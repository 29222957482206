import * as React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { LearnerCourse } from 'models/LearnerCourse';
import { breakpoints, sizes, theme } from '../../theme';
import { BodyRegular, Headline2 } from '../../components/Elements';
import strings from 'strings/strings.json';
import PrimaryButton from 'components/buttons/PrimaryButton';
import LearnerCourseComponent from 'components/learnerCourse/LearnerCourseComponent';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import NavBackButton from 'components/buttons/NavBackButton';

type State = {
    activeActivityId: string;
};

export default function LearnerCoursePage(): JSX.Element {
    const navigate = useNavigate();

    const { state } = useLocation();
    const { activeActivityId } = (state as State) ?? {};

    const [activeModuleIndex, setActiveModuleIndex] = React.useState<number>(0);

    const params = useParams();
    const { courseScheduleId } = params;
    const {
        data: course,
        loading: loadingLearnerCourse,
        errors,
    } = useGetRequest<LearnerCourse>(`learner/schedule/${courseScheduleId}`);

    React.useEffect(() => {
        const sortedModules = course?.modules.sort((a, b) => a.order - b.order) ?? [];

        if (activeActivityId) {
            const activeModuleIndex = sortedModules.findIndex((m) =>
                m.activities.some((a) => a.id === activeActivityId),
            );
            setActiveModuleIndex(activeModuleIndex);
        } else {
            const firstIncompleteModuleIndex =
                sortedModules?.findIndex((m) => m.activities.some((a) => !a.completed)) ?? 0;
            setActiveModuleIndex(firstIncompleteModuleIndex);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, activeActivityId]);

    if (!course || loadingLearnerCourse) {
        return <></>;
    }

    if (!courseScheduleId)
        return (
            <MainSectionWithGaps>
                <NavBackButton
                    backButtonTitle={strings.learnerCoursePage.errors.backButton.title}
                    backButtonAria={strings.learnerCoursePage.errors.backButton.aria}
                    backButtonHref={'/'}
                />
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.learnerCoursePage.errors.title}
                    description={strings.learnerCoursePage.errors.description}
                />
            </MainSectionWithGaps>
        );

    if (errors && errors.length > 0) {
        return (
            <MainSectionWithGaps>
                <NavBackButton
                    backButtonTitle={strings.learnerCoursePage.errors.backButton.title}
                    backButtonAria={strings.learnerCoursePage.errors.backButton.aria}
                    backButtonHref={'/'}
                />
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.learnerCoursePage.errors.title}
                    description={strings.learnerCoursePage.errors.description}
                />
            </MainSectionWithGaps>
        );
    }

    if (course.cancelledDate) {
        return (
            <CancelledCourseBackground>
                <CancelledCourseBody>
                    <Headline2>{strings.cancelledCourseComponent.headlineText}</Headline2>
                    <BodyRegular>{strings.cancelledCourseComponent.bodyText}</BodyRegular>
                    <PrimaryButton
                        type="submit"
                        title={strings.cancelledCourseComponent.backToCoursesButtonText}
                        aria={strings.cancelledCourseComponent.backToCoursesButtonAria}
                        onClick={() => navigate('/')}
                    />
                </CancelledCourseBody>
            </CancelledCourseBackground>
        );
    }

    return (
        <LearnerCourseComponent
            courseTitle={course.title}
            courseDescription={course.description}
            learningObjectives={course.learningObjectives}
            modules={course.modules}
            activeModuleIndex={activeModuleIndex}
            startDate={course.startDate}
            endDate={course.endDate}
            urlPrefix={`/learner/${courseScheduleId}`}
            trainerInfo={course.trainerInfo}
        />
    );
}

const CancelledCourseBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.contentBackgroundColourTrainer};
`;

const CancelledCourseBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    margin: 15rem ${sizes.edgePadding} 5rem ${sizes.edgePadding};
    @media (max-width: ${breakpoints.sm}) {
        margin-top: 10rem;
        margin-bottom: 5rem;
    }
`;
