import CreateCourseForm from 'pages/trainer/components/CreateCourseForm';
import React from 'react';
import CreateCourseWithFileForm from './CreateCourseWithFileForm';
import SelectCreateCourseMethodForm from './SelectCreateCourseMethodForm';

export type Component =
    | 'selectCreateCourseMethod'
    | 'createCourseManual'
    | 'createCourseSCORM'
    | 'createCoursePowerPoint';

type CreateCourseSwitchProps = {
    closeModal: () => void;
};

export default function CreateCourseSwitch({ closeModal }: CreateCourseSwitchProps) {
    const [component, setComponent] = React.useState<Component>('selectCreateCourseMethod');

    let body = <></>;

    switch (component) {
        case 'selectCreateCourseMethod':
        default:
            body = <SelectCreateCourseMethodForm setComponent={setComponent} />;
            break;
        case 'createCourseManual':
            body = <CreateCourseForm />;
            break;
        case 'createCourseSCORM':
            body = <CreateCourseWithFileForm createCourseUrl={'courses/create-with-scorm'} closeModal={closeModal} />;
            break;
        case 'createCoursePowerPoint':
            body = (
                <CreateCourseWithFileForm createCourseUrl={'courses/create-with-powerpoint'} closeModal={closeModal} />
            );
            break;
    }

    return <>{body}</>;
}
