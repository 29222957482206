import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import SuccessNotifier from 'components/notifiers/SuccessNotifier';
import styled from 'styled-components';
import { sizes } from 'theme';
import strings from '../../../strings/strings.json';

type CreateCourseSuccessProps = {
    error: string | null;
    closeModal: () => void;
};

export default function CreateCourseSuccess({ error, closeModal }: CreateCourseSuccessProps): JSX.Element {
    return (
        <>
            <SuccessNotifier
                title={strings.createCourseModal.createCourseForm.success.title}
                description={strings.createCourseModal.createCourseForm.success.description}
                maxWidth={'none'}
            />
            <ButtonRow>
                {error && <ErrorNotifierSlim description={error} />}
                <PrimaryButton
                    title={strings.createCourseModal.createCourseForm.success.button.title}
                    aria={strings.createCourseModal.createCourseForm.success.button.aria}
                    onClick={closeModal}
                />
            </ButtonRow>
        </>
    );
}

const ButtonRow = styled.div`
    display: flex;
    justify-content: end;
    gap: ${sizes.spacingMd};
`;
