import strings from '../../../strings/strings.json';
import { breakpoints, sizes, theme } from '../../../theme';
import Table, { RightAlignedButtonCell, TableCell, TableRow } from '../../../components/table/Table';
import { EmptyTableContent } from '../../../components/table/EmptyTableContent';
import { UserModel } from '../../../models/User';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/buttons/PrimaryButton';
import NavigateWithArrow from '../../../components/actions/NavigateWithArrow';
import HeaderWithCount from '../components/HeaderWithCount';
import { IconSize } from 'assets/icons/icon-sizes';
import MainSection from 'components/sections/MainSection';
import styled from 'styled-components';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import { useContext } from 'react';
import PlusIcon from 'assets/icons/controls/PlusIcon';
import { FlexAlignCenter, Headline3 } from 'components/Elements';
import OrganisationIcon from 'assets/icons/navigation/OrganisationIcon';
import Notification, { NotificationType } from 'components/notifiers/Notification';

export function OATrainersTable(): JSX.Element {
    const navigate = useNavigate();

    const { userData } = useContext(AuthContext);

    const {
        data: trainers,
        loading,
        errors,
    } = useGetRequest<UserModel[]>(`organisations/${userData.organisation?.id}/trainers`, !userData.organisation?.id);

    if (!trainers || loading) return <></>;

    if (errors.length) {
        return (
            <ManageTrainersContentSection>
                <Headline3>{strings.OATrainersTable.trainersPageHeader}</Headline3>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OATrainersTable.getTrainersError.title}
                    description={strings.OATrainersTable.getTrainersError.description}
                />
            </ManageTrainersContentSection>
        );
    }

    const trainerRows = (trainers ?? []).map((trainer: UserModel) => {
        const trainerName = `${trainer.firstName} ${trainer.lastName}`;

        const cells: TableCell[] = [
            {
                name: strings.OATrainersTable.tableHeaders.userName,
                isBold: true,
                value: trainerName,
            },
            {
                name: strings.OATrainersTable.tableHeaders.userEmail,
                value: trainer.email,
            },
            {
                name: '',
                value: (
                    <RightAlignedButtonCell>
                        <NavigateWithArrow
                            title={strings.OATrainersTable.viewTrainerButton.title}
                            aria={strings.OATrainersTable.viewTrainerButton.aria}
                            href={`/organisation/trainers/${trainer.id}`}
                            iconSize={IconSize.LARGE}
                        />
                    </RightAlignedButtonCell>
                ),
            },
        ];

        const tableRow: TableRow = { id: trainer.id, cells: cells };
        return tableRow;
    });

    const button = (
        <PrimaryButton
            title={strings.OATrainersTable.inviteTrainerButton.title}
            aria={strings.OATrainersTable.inviteTrainerButton.aria}
            onClick={() => {
                navigate('/organisation/trainers/invite');
            }}
            icon={<PlusIcon size={IconSize.LARGE} />}
        />
    );

    return (
        <ManageTrainersContentSection id="trainersContent">
            <TitleAndButtonContainer>
                <Headline3>{strings.OATrainersTable.trainersPageHeader}</Headline3>
                {trainerRows.length > 0 && (
                    <HeaderWithCount
                        countText={`${trainerRows.length} ${strings.OATrainersTable.numberOfTrainersText}`}
                        actionButton={button}
                    />
                )}
            </TitleAndButtonContainer>
            <div>
                <Table
                    headers={[
                        { name: strings.OATrainersTable.tableHeaders.userName, order: 1, rowPercentage: 20 },
                        { name: strings.OATrainersTable.tableHeaders.userEmail, order: 2, rowPercentage: 70 },
                        { name: '', order: 3, rowPercentage: 10, mobileRightColumn: true },
                    ]}
                    rows={trainerRows}
                    emptyTableContent={
                        <EmptyTableContent
                            icon={<OrganisationIcon size={IconSize.XXLARGE} colour={theme.textColour} />}
                            title={strings.OATrainersTable.emptyTable.title}
                            description={strings.OATrainersTable.emptyTable.description}
                            buttonTitle={strings.OATrainersTable.inviteTrainerButton.title}
                            buttonAria={strings.OATrainersTable.inviteTrainerButton.aria}
                            buttonOnClick={() => {
                                navigate('/organisation/trainers/invite');
                            }}
                            buttonIcon={<PlusIcon size={IconSize.LARGE} />}
                        />
                    }
                />
            </div>
        </ManageTrainersContentSection>
    );
}

const ManageTrainersContentSection = styled(MainSection)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    padding: ${sizes.spacingSm} 0;
    min-height: 120px;

    @media (max-width: ${breakpoints.sm}) {
        padding: unset;
    }
`;

const TitleAndButtonContainer = styled(FlexAlignCenter)`
    justify-content: space-between;

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        align-items: flex-start;
    }
`;
