import useGetRequest from 'hooks/useGetRequest';
import { Organisation } from 'models/Organisation';
import { AuthContext } from 'contextProviders/AuthContext';
import { useContext } from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import { IconSize } from 'assets/icons/icon-sizes';
import strings from '../../strings/strings.json';
import { Flex, FlexCol, Headline2 } from 'components/Elements';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import BinIcon from 'assets/icons/controls/BinIcon';
import useDeleteRequest from 'hooks/useDeleteRequest';
import React from 'react';
import Alert from 'components/alert/Alert';
import { logError } from 'lib/debug-helpers';
import { OrganisationDescriptionForm } from './components/OrganisationDescriptionForm';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import { useLocation } from 'react-router-dom';
import InformationIcon from 'assets/icons/other/InformationIcon';
import FileIcon from 'assets/icons/other/FileIcon';
import CharlieHRIntegrationContent from './components/CharlieHR/CharlieHRIntegrationContent';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import { TabDefinition } from 'components/tabs/Tabs';
import FileListPage from './files/FileListPage';
import { LogoImage, OrganisationHeaderBlue } from './shared/styles';

type State = {
    activeTabId: string;
};

export default function OrganisationOverviewPage(): JSX.Element {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { activeTabId } = (state as State) ?? {};

    const { userData, refreshUserData } = useContext(AuthContext);
    const organisationId = userData.organisation?.id;
    const [removeLogoWarning, setRemoveLogoWarning] = React.useState<boolean>(false);
    const [removeLogoError, setRemoveLogoError] = React.useState<boolean>(false);
    const [savedDescription, setSavedDescription] = React.useState<boolean>(false);

    const {
        data: organisation,
        loading,
        errors,
        refetchData: refetchOrganisation,
    } = useGetRequest<Organisation>(`organisations/${organisationId}`, !organisationId);

    const { deleteData } = useDeleteRequest(`organisations/${userData?.organisation?.id}/logo`);

    if (!organisation || loading) return <></>;

    if (errors && errors.length) {
        return (
            <>
                <OrganisationHeaderBlue>
                    <LimitedContainer>
                        <Headline2>{strings.organisationOverviewPage.errors.pageHeader}</Headline2>
                    </LimitedContainer>
                </OrganisationHeaderBlue>
                <MainSectionWithGaps>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.organisationOverviewPage.errors.errorTitle}
                        description={strings.organisationOverviewPage.errors.errorDescription}
                    />
                </MainSectionWithGaps>
            </>
        );
    }

    async function RemoveLogo() {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            setRemoveLogoError(true);
            return;
        }

        await refetchOrganisation();
        await refreshUserData();
        setRemoveLogoWarning(false);
    }

    const tabDefinitions: TabDefinition[] = [
        {
            id: 'organisationDetails',
            name: strings.organisationOverviewPage.tabs.organisationDetails,
            icon: InformationIcon,
            content: (
                <OrganisationDescriptionForm
                    organisation={organisation}
                    refetchOrganisation={refetchOrganisation}
                    savedDescription={savedDescription}
                    setSavedDescription={setSavedDescription}
                />
            ),
        },
        {
            id: 'manageFiles',
            name: strings.organisationOverviewPage.tabs.manageFiles,
            icon: FileIcon,
            content: <FileListPage />,
        },
        {
            id: 'integration',
            name: strings.organisationOverviewPage.tabs.integration,
            icon: FileIcon,
            content: <CharlieHRIntegrationContent />,
        },
    ];

    if (errors && errors.length > 0)
        return (
            <>
                <OrganisationHeaderBlue>
                    <LimitedContainer>
                        <OrgOverviewPageHeadlineError>
                            {strings.organisationOverviewPage.loadingError.pageTitle}
                        </OrgOverviewPageHeadlineError>
                    </LimitedContainer>
                </OrganisationHeaderBlue>
                <ErrorNotificationContainer>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.organisationOverviewPage.loadingError.title}
                        description={strings.organisationOverviewPage.loadingError.description}
                        maxWidth="800px"
                    />
                </ErrorNotificationContainer>
            </>
        );

    return (
        <>
            <OrganisationHeaderBlue>
                <LimitedContainer>
                    <HeaderLogoAndButtonsContainer>
                        <Headline2>{organisation?.name}</Headline2>
                        <HeaderButtonContainer>
                            <LogoAndButtonsRow>
                                {organisation?.logoS3Filepath && (
                                    <LogoContainer>
                                        <LogoImage
                                            src={`${DOCUMENTS_URL}/${organisation?.logoS3Filepath}`}
                                            alt={strings.organisationOverviewPage.logoAltText}
                                        />
                                    </LogoContainer>
                                )}
                                <ButtonColumn>
                                    <SecondaryButton
                                        title={
                                            organisation?.logoS3Filepath
                                                ? strings.organisationOverviewPage.updateButton.title
                                                : strings.organisationOverviewPage.uploadButton.title
                                        }
                                        aria={
                                            organisation?.logoS3Filepath
                                                ? strings.organisationOverviewPage.updateButton.aria
                                                : strings.organisationOverviewPage.uploadButton.aria
                                        }
                                        icon={<UploadIcon size={IconSize.SMALL} />}
                                        onClick={() => {
                                            navigate(`/organisation/logo`);
                                        }}
                                    />
                                    {organisation?.logoS3Filepath && (
                                        <SecondaryButton
                                            title={strings.organisationOverviewPage.removeLogoButton.title}
                                            aria={strings.organisationOverviewPage.removeLogoButton.aria}
                                            onClick={() => {
                                                setRemoveLogoWarning(true);
                                            }}
                                            alternateColour={SecondaryButtonColour.ALTERNATE}
                                            icon={<BinIcon />}
                                        />
                                    )}
                                </ButtonColumn>
                            </LogoAndButtonsRow>
                        </HeaderButtonContainer>
                    </HeaderLogoAndButtonsContainer>
                </LimitedContainer>
            </OrganisationHeaderBlue>
            <MainSectionWithGaps>
                <TabStructureComponent initialActiveTabId={activeTabId} tabs={tabDefinitions} />
            </MainSectionWithGaps>
            {removeLogoWarning && (
                <Alert
                    buttonText={strings.organisationOverviewPage.removeLogoAlert.button.text}
                    ariaButtonText={strings.organisationOverviewPage.removeLogoAlert.button.aria}
                    alertText={strings.organisationOverviewPage.removeLogoAlert.alertText}
                    closeAlert={() => setRemoveLogoWarning(false)}
                    onSubmit={RemoveLogo}
                    error={
                        removeLogoError
                            ? {
                                  title: strings.organisationOverviewPage.removeLogoAlert.removeLogoError.title,
                                  description:
                                      strings.organisationOverviewPage.removeLogoAlert.removeLogoError.description,
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}

const LimitedContainer = styled.header`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;

    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        justify-content: unset;
        align-items: flex-start;
    }
`;

const HeaderLogoAndButtonsContainer = styled.div`
    gap: ${sizes.spacingLg};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${sizes.spacingLg} 0;

    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        align-items: unset;
        gap: ${sizes.spacingMd};
    }
`;

const HeaderButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
    }
`;

const ButtonColumn = styled(FlexCol)`
    gap: ${sizes.spacingMd};
`;

const LogoAndButtonsRow = styled(Flex)`
    gap: ${sizes.spacingMd};
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: unset;
    }
`;

const OrgOverviewPageHeadlineError = styled(Headline2)`
    margin: 140px 0;
    @media (max-width: ${breakpoints.sm}) {
        margin: 64px 0;
    }
`;

const ErrorNotificationContainer = styled.div`
    display: flex;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    width: 100%;
    padding: 0 ${sizes.edgePadding};
    margin-top: ${sizes.spacingLg};
`;

const LogoContainer = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    display: flex;
    align-items: center;
    height: min-content;
    width: min-content;
    padding: ${sizes.spacingSm};
`;
