import * as React from 'react';
import styled from 'styled-components';
import strings from 'strings/strings.json';
import useGetRequest from 'hooks/useGetRequest';
import useWindowWidth from 'hooks/useWindowWidth';
import Skeleton from 'react-loading-skeleton';
import { breakpoints, sizes, theme } from 'theme';
import LearnerCourseCard from './components/LearnerCourseCard';
import MainSection from '../../components/sections/MainSection';
import LearnerHeader from '../../components/sections/LearnerHeader';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import { LearnerCourseOverview } from '../../models/LearnerCourseOverview';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import ExpandableCourseList from './components/ExpandableCourseList';
import { CourseCardsContainer, CourseList } from './sharedStyles/LearnerStyles';

export default function LearnerCoursesPage(): JSX.Element {
    const {
        data: learnerCourseOverviews,
        loading,
        errors,
    } = useGetRequest<LearnerCourseOverview[]>('learner/schedules');
    const { isMobileWidth } = useWindowWidth();

    if (!learnerCourseOverviews || loading) {
        return (
            <>
                <LearnerHeader
                    title={strings.learnerCoursesPage.title}
                    icon={
                        <CoursesIcon
                            size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                            colour={theme.textColour}
                        />
                    }
                />
                <MainSection>
                    <ListContainer>
                        <CourseList>
                            <CourseCardsContainer>
                                {isMobileWidth ? (
                                    <Skeleton height={400} width={345} count={1} borderRadius={30} />
                                ) : (
                                    <>
                                        <Skeleton height={385} width={604} count={1} borderRadius={30} />
                                        <Skeleton height={385} width={604} count={1} borderRadius={30} />
                                    </>
                                )}
                            </CourseCardsContainer>
                        </CourseList>
                    </ListContainer>
                </MainSection>
            </>
        );
    }

    if (errors && errors.length > 0) {
        return (
            <>
                <LearnerHeader
                    title={strings.learnerCoursesPage.title}
                    icon={
                        <CoursesIcon
                            size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                            colour={theme.textColour}
                        />
                    }
                />
                <MainSection>
                    <ListContainer>
                        <Notification
                            notificationType={NotificationType.DANGER}
                            title={strings.learnerCoursesPage.getUserDataError}
                        />
                    </ListContainer>
                </MainSection>
            </>
        );
    }

    const activeCourses: LearnerCourseOverview[] = [];
    const oldCourses: LearnerCourseOverview[] = [];
    const cancelledCourses: LearnerCourseOverview[] = [];

    const endOfYesterday = new Date();
    endOfYesterday.setDate(endOfYesterday.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59, 59);

    for (const course of learnerCourseOverviews) {
        if (course.cancelledDate) cancelledCourses.push(course);
        else if (new Date(course.endDate) <= endOfYesterday) {
            oldCourses.push(course);
        } else activeCourses.push(course);
    }

    return (
        <>
            <LearnerHeader
                title={strings.learnerCoursesPage.title}
                icon={
                    <CoursesIcon size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE} colour={theme.textColour} />
                }
            />
            <MainSection>
                {learnerCourseOverviews.length ? (
                    <ListContainer>
                        {activeCourses.length > 0 && (
                            <CourseList>
                                <CourseCardsContainer>
                                    {activeCourses
                                        .sort(
                                            (a, b) =>
                                                new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
                                        )
                                        .map((courseOverview: LearnerCourseOverview) => {
                                            return (
                                                <LearnerCourseCard
                                                    key={courseOverview.id}
                                                    courseOverview={courseOverview}
                                                />
                                            );
                                        })}
                                </CourseCardsContainer>
                            </CourseList>
                        )}

                        {oldCourses.length > 0 && (
                            <ExpandableCourseList
                                headline={strings.learnerCoursesPage.endedCourses}
                                courses={oldCourses}
                            />
                        )}

                        {cancelledCourses.length > 0 && (
                            <ExpandableCourseList
                                headline={strings.learnerCoursesPage.cancelledCourses}
                                courses={cancelledCourses}
                            />
                        )}
                    </ListContainer>
                ) : (
                    <ListContainer>
                        <Notification
                            notificationType={NotificationType.INFO}
                            title={strings.learnerCoursesPage.noCoursesMessage.title}
                            description={strings.learnerCoursesPage.noCoursesMessage.description}
                            maxWidth={'100%'}
                        />
                    </ListContainer>
                )}
            </MainSection>
        </>
    );
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingXl} 0;
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
`;
