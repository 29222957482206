import * as React from 'react';
import { useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import { BodyRegular, FlexAlignCenter, FlexCol, Headline1, Headline2, LinkWrapper } from 'components/Elements';
import { IconSize } from 'assets/icons/icon-sizes';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import strings from '../../strings/strings.json';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import { OrganisationPublic } from '../../models/OrganisationPublic';
import NeveLearningLogoMonochrome from 'assets/icons/footer/NeveLearningLogoMonochrome';
import YourTrainerCard from 'pages/learner/components/YourTrainerCard';
import { CourseCardsContainer, LearnerCardBase } from 'pages/learner/sharedStyles/LearnerStyles';
import ExpandableContentComponent from 'components/ExpandableContentComponent';
import OrganisationIcon from 'assets/icons/navigation/OrganisationIcon';
import CourseCardWithColouredHeader from 'components/courseCards/CourseCardWithColouredHeader';
import { LogoImage, OrganisationHeaderBlue } from 'pages/OA/shared/styles';
import useWindowWidth from 'hooks/useWindowWidth';

export default function PublicOrganisationPage(): JSX.Element {
    const params = useParams();
    const { isMobileWidth } = useWindowWidth();
    const { name } = params;
    const {
        data: organisation,
        loading,
        errors,
    } = useGetRequest<OrganisationPublic>(`public/organisations/${name}`, undefined, true);

    if (loading || !organisation) {
        return <></>;
    }

    if (errors.length > 0) {
        return (
            <MainContent>
                <NotificationContainer>
                    <ErrorNotifier
                        title={strings.publicOrganisationPage.orgNotFoundError.title}
                        description={strings.publicOrganisationPage.orgNotFoundError.description}
                    />
                </NotificationContainer>
            </MainContent>
        );
    }

    return (
        <>
            <Page>
                <OrganisationHeaderBlue id="org-header">
                    <OrganisationHeaderContent id="org-header-content">
                        <OrganisationHeaderLogoAndTitle id="org-logo-title">
                            <Headline1>{organisation?.name}</Headline1>
                            {organisation?.logoUrl && (
                                <LogoContainer>
                                    <LogoImage
                                        src={`${DOCUMENTS_URL}/${organisation?.logoUrl}`}
                                        alt={strings.organisationOverviewPage.logoAltText}
                                    />
                                </LogoContainer>
                            )}
                        </OrganisationHeaderLogoAndTitle>
                        {organisation?.description && (
                            <OrganisationDescription id="org-description">
                                <BodyRegular>{organisation?.description}</BodyRegular>
                            </OrganisationDescription>
                        )}
                    </OrganisationHeaderContent>
                </OrganisationHeaderBlue>

                <MainContent>
                    <ContentSection id="courses-content-section">
                        <HeaderAndIconContainer>
                            <CoursesIcon
                                size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                                colour={theme.textColour}
                            />
                            <Headline2>{strings.publicOrganisationPage.courses}</Headline2>
                        </HeaderAndIconContainer>
                        {organisation.courses.length > 0 ? (
                            <CourseCardsContainer id="courses-grid">
                                {organisation.courses.map((courseOverview, index) => (
                                    <CourseCardWithColouredHeader
                                        key={index}
                                        courseOverview={courseOverview}
                                        cardHeaderColour={theme.cardThirdBackgroundColour}
                                    />
                                ))}
                            </CourseCardsContainer>
                        ) : (
                            <NotificationContainer>
                                <Notification
                                    notificationType={NotificationType.INFO}
                                    title={strings.publicOrganisationPage.organisationHasNoCourses}
                                />
                            </NotificationContainer>
                        )}
                    </ContentSection>

                    {organisation.trainers.length > 0 && (
                        <ContentSection id="trainers-content-section">
                            <HeaderAndIconContainer>
                                <OrganisationIcon
                                    size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                                    colour={theme.textColour}
                                />
                                <Headline2>{strings.publicOrganisationPage.trainers}</Headline2>
                            </HeaderAndIconContainer>
                            {organisation.trainers.map((trainer, index) => (
                                <YourTrainerCard
                                    key={index}
                                    trainerName={`${trainer.firstName} ${trainer.lastName}`}
                                    trainerBio={trainer.bio}
                                    profileImageFilepath={
                                        trainer.profileImage ? `${DOCUMENTS_URL}/${trainer.profileImage}` : ''
                                    }
                                />
                            ))}
                        </ContentSection>
                    )}

                    {organisation.pastCourses.length > 0 && (
                        <ExpandableContentComponent
                            headline={strings.publicOrganisationPage.pastCourses}
                            closedHeightInPx={0}
                            content={
                                <ContentSection id="past-courses-section-content">
                                    <CourseCardsContainer id="past-courses-grid">
                                        {organisation.pastCourses.map((courseOverview, index) => (
                                            <CourseCardWithColouredHeader
                                                key={index}
                                                courseOverview={courseOverview}
                                                cardHeaderColour={theme.cardThirdBackgroundColour}
                                            />
                                        ))}
                                    </CourseCardsContainer>
                                </ContentSection>
                            }
                        />
                    )}
                </MainContent>
            </Page>

            <FooterBackground>
                <LogoLink href="https://www.nevelearning.co.uk/" target="_blank">
                    <NeveLearningLogoMonochrome />
                </LogoLink>
            </FooterBackground>
        </>
    );
}

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 184px);
    @media (max-width: ${breakpoints.sm}) {
        min-height: calc(100vh - 189px);
    }
`;

const MainContent = styled.div`
    padding: ${sizes.spacingLg} ${sizes.edgePadding};
    width: 100%;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: ${sizes.spacingXl};
`;

const OrganisationHeaderLogoAndTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingLg};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
    }
`;

const LogoContainer = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    display: flex;
    align-items: center;
    height: min-content;
    width: min-content;
    padding: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.md}) {
        margin-top: ${sizes.spacingMd};
        align-self: flex-start;
    }
    @media (max-width: ${breakpoints.sm}) {
        margin-top: unset;
    }
`;

const OrganisationDescription = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
    width: 100%;
`;

const OrganisationHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    align-items: center;
    justify-content: center;
    width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: ${sizes.spacingLg} ${sizes.edgePadding};
`;

const NotificationContainer = styled.div`
    display: flex;
    align-items: start;
`;

const HeaderAndIconContainer = styled(FlexAlignCenter)`
    gap: ${sizes.spacingRg};
`;

const ContentSection = styled(FlexCol)`
    gap: ${sizes.spacingMd};
`;

const FooterBackground = styled.div`
    background-color: ${theme.footerBackground};
    padding: ${sizes.spacingLg} 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LogoLink = styled(LinkWrapper)`
    height: 40px;
`;
