import PrimaryButton from 'components/buttons/PrimaryButton';
import { ButtonRowRight, Headline3 } from 'components/Elements';
import { ErrorMessage } from 'components/forms/FormComponents';
import useNeveForm from 'components/forms/NeveForm';
import TextField from 'components/forms/TextField';
import useUpsertRequest from 'hooks/useUpsertRequest';
import { APIError } from 'lib/_api-helpers';
import { logError } from 'lib/debug-helpers';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    createErrorMessage,
    createGlobalErrorFromAPIError,
    fieldErrorCodes,
    requiredFieldErrorMessage,
} from 'shared/error-messages';
import styled from 'styled-components';
import { AuthContext } from 'contextProviders/AuthContext';
import { sizes } from 'theme';
import strings from '../../../strings/strings.json';

type OrganisationInput = {
    organisationName: string;
};

type UserCreateOrganisationFormProps = {
    showHeadlineText: boolean;
    maxWidth?: string;
};

export default function UserCreateOrganisationForm({
    showHeadlineText,
    maxWidth,
}: UserCreateOrganisationFormProps): JSX.Element {
    const { refreshUserData } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const { modifyData: upsertOrganisation } = useUpsertRequest('users/me/create-organisation');

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useNeveForm<OrganisationInput>({
        organisationName: '',
    });

    async function onSubmit(formData: OrganisationInput) {
        const newOrganisation = {
            organisationName: formData.organisationName,
        };
        const response = await upsertOrganisation(newOrganisation);

        const { errors } = response;
        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        await refreshUserData();
        navigate('/organisation/dashboard/');
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
        });
    }

    return (
        <CreateOrganisationForm onSubmit={handleSubmit(onSubmit)} $maxWidth={maxWidth}>
            {showHeadlineText && <Headline3>{strings.userCreateOrganisationPage.createOrgPageHeader}</Headline3>}
            <TextField
                fieldName="organisationName"
                labelText={strings.organisationForm.createOrganisationInputLabel}
                inputAria={strings.organisationForm.createOrganisationInputAriaLabel}
                inputProps={register('organisationName', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.organisationForm.createOrganisationInputLabel),
                    },
                    maxLength: {
                        value: 50,
                        message: createErrorMessage(
                            `${strings.notifyAllUsersPage.messageBodyInputField.inputLabel}`,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                errorMessage={errors.organisationName?.message}
                required
                maxLength={50}
            />
            {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
            <ButtonRowRight>
                <PrimaryButton
                    type="submit"
                    title={strings.userCreateOrganisationPage.button.title}
                    aria={strings.userCreateOrganisationPage.button.aria}
                />
            </ButtonRowRight>
        </CreateOrganisationForm>
    );
}

const CreateOrganisationForm = styled.form<{ $maxWidth?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
    max-width: ${({ $maxWidth }) => {
        return $maxWidth ? $maxWidth : '640px';
    }};
    margin: 0 auto;
    padding: ${sizes.spacingLg} 0;
`;
