import { CourseSchedule } from './CourseSchedule';
import { Organisation } from './Organisation';
import strings from '../strings/strings.json';
import LearnerIcon from 'assets/icons/role-icons/LearnerIcon';
import TrainerIcon from 'assets/icons/role-icons/TrainerIcon';
import OrganisationAdminIcon from 'assets/icons/role-icons/OrganisationAdminIcon';
import AdminIcon from 'assets/icons/role-icons/AdminIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { theme } from 'theme';

export enum RoleType {
    ADMIN = 'ADMIN',
    ORGANISATION_ADMIN = 'ORGANISATION_ADMIN',
    TRAINER = 'TRAINER',
    LEARNER = 'LEARNER',
}

type RoleDetail = {
    name: string;
    friendlyName: string;
    indefiniteArticle: string;
    description: string;
    icon: JSX.Element;
};

/*
the ROLE_DETAILS object is designed to collect up useful information around each role,
further to what the enum provides above.
*/
export const ROLE_DETAILS: RoleDetail[] = [
    {
        name: 'LEARNER',
        friendlyName: strings.roles.learnerFriendlyName,
        indefiniteArticle: strings.roles.learnerIndefiniteArticle,
        description: strings.roles.learnerDescription,
        icon: <LearnerIcon size={IconSize.SMALL} colour={theme.textColour} />,
    },
    {
        name: 'TRAINER',
        friendlyName: strings.roles.trainerFriendlyName,
        indefiniteArticle: strings.roles.trainerIndefiniteArticle,
        description: strings.roles.trainerDescription,
        icon: <TrainerIcon size={IconSize.SMALL} colour={theme.textColour} />,
    },
    {
        name: 'ORGANISATION_ADMIN',
        friendlyName: strings.roles.OAFriendlyName,
        indefiniteArticle: strings.roles.OAIndefiniteArticle,
        description: strings.roles.OADescription,
        icon: <OrganisationAdminIcon size={IconSize.SMALL} colour={theme.textColour} />,
    },
    {
        name: 'ADMIN',
        friendlyName: strings.roles.adminFriendlyName,
        indefiniteArticle: strings.roles.adminIndefiniteArticle,
        description: strings.roles.adminDescription,
        icon: <AdminIcon size={IconSize.SMALL} colour={theme.textColour} />,
    },
];

export function findHighestRole(roles: RoleType[]): RoleType {
    if (roles.some((x) => x === RoleType.ADMIN)) {
        return RoleType.ADMIN;
    } else if (roles.some((x) => x === RoleType.ORGANISATION_ADMIN)) {
        return RoleType.ORGANISATION_ADMIN;
    } else if (roles.some((x) => x === RoleType.TRAINER)) {
        return RoleType.TRAINER;
    } else {
        return RoleType.LEARNER;
    }
}

export function getFriendlyRoleName(role: RoleType, includeIndefiniteArticle?: boolean): string {
    let friendlyRoleName: string = '';

    if (includeIndefiniteArticle) {
        friendlyRoleName = ROLE_DETAILS.find((roleDetail) => roleDetail.name === role)?.indefiniteArticle + ' ';
    }

    return friendlyRoleName + ROLE_DETAILS.find((roleDetail) => roleDetail.name === role)?.friendlyName;
}

export type RoleModel = {
    name: RoleType;
};

export type UserModel = {
    id: string;
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: RoleModel[];
    token: string;
    courseSchedules: CourseSchedule[];
    organisation: Organisation;
    trainerCourseSchedules: CourseSchedule[];
    profileImageS3Filepath: string;
    bio: string;
};


