import { LearnerActivity, LearnerCourse, LearnerModule } from '../models/LearnerCourse';
import React from 'react';
import useGetRequest from '../hooks/useGetRequest';
import { useParams } from 'react-router-dom';
import { APIError } from '../lib/_api-helpers';
import { PreviewContext } from './PreviewContext';
import { AuthContext } from './AuthContext';
import { LearnerCourseContentError } from '../pages/learner/components/shared';
import strings from '../strings/strings.json';

type LearnerCourseSummaryValues = {
    courseSummary: LearnerCourse | null;
    loading: boolean;
    errors: APIError[];
    navigationUrlPrefix: string;
};

export const LearnerCourseSummaryContext = React.createContext<LearnerCourseSummaryValues>({
    courseSummary: null,
    loading: false,
    errors: [],
    navigationUrlPrefix: '/learner',
});

export default function LearnerCourseSummaryProvider(props: {
    children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
    const params = useParams();
    const { courseScheduleId } = params;

    const {
        data: scheduledCourse,
        loading,
        errors,
    } = useGetRequest<LearnerCourse>(`learner/schedule/${courseScheduleId}`);

    return (
        <LearnerCourseSummaryContext.Provider
            value={{ courseSummary: scheduledCourse, loading, errors, navigationUrlPrefix: '/learner' }}
        >
            {props.children}
        </LearnerCourseSummaryContext.Provider>
    );
}

export function LearnerCourseSummaryPreviewProvider(props: {
    children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
    const params = useParams();
    const { courseId, activityId } = params;

    const previewContext = React.useContext(PreviewContext);
    const authContext = React.useContext(AuthContext);

    if (!courseId || !activityId || previewContext.scheduledCourse?.course === undefined) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    const learnerCourse: LearnerCourse = {
        id: previewContext.scheduledCourse.course.id,
        title: previewContext.scheduledCourse.course.title,
        description: previewContext.scheduledCourse.course.description,
        startDate: previewContext.scheduledCourse.startDate,
        endDate: previewContext.scheduledCourse.endDate,
        modules: previewContext.scheduledCourse.course.modules.map((module) => {
            const learnerModule: LearnerModule = {
                id: module.id,
                title: module.title,
                description: module.description,
                order: module.order,
                dateCreated: new Date(module.dateCreated),
                activities: module.activities.map((activity) => {
                    const locationType = previewContext.scheduledCourse?.meetings.find(
                        (m) => m.activityId === activity.id,
                    )?.locationType;

                    const learnerActivity: LearnerActivity = {
                        id: activity.id,
                        title: activity.title,
                        order: activity.order,
                        type: activity.type,
                        locationType: locationType,
                        startDate: activity.meetings ? activity.meetings[0].meetingDatetime : undefined,
                        completed: false,
                        questionsTotal: activity.questions.length,
                        questionsAnswered: 0,
                        durationInMins: activity.durationInMins,
                        moduleId: activity.moduleId,
                    };
                    return learnerActivity;
                }),
            };
            return learnerModule;
        }),
        learningObjectives: previewContext.scheduledCourse.course.learningObjectives,
        cancelledDate: null,
        trainerInfo: {
            firstName: authContext.userTokenDetails.given_name,
            lastName: authContext.userTokenDetails.family_name,
            email: authContext.userTokenDetails.email,
            bio: strings.learnerCoursePage.previewBioText,
            profileImagePath: null,
            organisationName: authContext.userData.organisation?.name ?? '',
            organisationPublicName: authContext.userData.organisation?.publicName ?? '',
            organisationLogo: authContext.userData.organisation?.logoS3Filepath ?? null,
        },
    };

    return (
        <LearnerCourseSummaryContext.Provider
            value={{ courseSummary: learnerCourse, loading: false, errors: [], navigationUrlPrefix: '/preview' }}
        >
            {props.children}
        </LearnerCourseSummaryContext.Provider>
    );
}
