import React from 'react';
import { Headline4, ModalBackground, TertiaryButton } from 'components/Elements';
import { breakpoints, sizes, theme } from 'theme';
import useKeyboardTrap from '../../hooks/useKeyboardTrap';
import styled from 'styled-components';
import CloseIcon from 'assets/icons/controls/CloseIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import strings from 'strings/strings.json';

type ModalProps = {
    onClickCloseButton: () => void;
    headlineText?: string;
    modalBody: JSX.Element;
};

export default function Modal({ onClickCloseButton, headlineText, modalBody }: ModalProps): JSX.Element {
    const { ref } = useKeyboardTrap(onClickCloseButton);

    return (
        <ModalBackground id="modal-background">
            <ModalBody ref={ref} id="modal-body">
                <ModalCard id="modal-card">
                    <ModalContent id="modal-content">
                        <HeadlineAndCloseRow>
                            {headlineText && <Headline4>{headlineText}</Headline4>}
                            <TertiaryButton
                                aria-label={strings.createCoursePage.closeButtonAria}
                                onClick={onClickCloseButton}
                            >
                                <IconContainer>
                                    <CloseIcon size={IconSize.SMALL} />
                                </IconContainer>
                            </TertiaryButton>
                        </HeadlineAndCloseRow>
                        {modalBody}
                    </ModalContent>
                </ModalCard>
            </ModalBody>
        </ModalBackground>
    );
}

const ModalBody = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    top: 20%;
    width: 750px;

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
    @media (max-width: ${breakpoints.sm}) {
        top: 17%;
    }
`;

const ModalCard = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: ${sizes.spacingMd};
    border: solid 1px ${theme.cardBorder};
    background-color: ${theme.cardBackground};
    border-radius: 8px;

    @media (max-width: ${breakpoints.sm}) {
        border: none;
        border-radius: initial;
        height: 80vh;
        overflow-y: auto;
        padding-bottom: ${sizes.spacingLg};
        justify-content: space-between;
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const HeadlineAndCloseRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const IconContainer = styled.div`
    padding: ${sizes.spacingSm};
`;
