import { StepperFormProps } from '../../../components/stepper/stepperTypes';
import { NewSchedule } from '../ScheduleCoursePage';
import useNeveForm from '../../../components/forms/NeveForm';
import strings from '../../../strings/strings.json';
import { BodyRegular, Headline4 } from '../../../components/Elements';
import * as React from 'react';
import RadioFields from '../../../components/forms/RadioFields';
import { ErrorNotifierSlim } from '../../../components/notifiers/ErrorNotifierSlim';
import useGetRequest from '../../../hooks/useGetRequest';
import { UserModel } from '../../../models/User';
import { AuthContext } from '../../../contextProviders/AuthContext';
import StepperButtonRow from '../../../components/stepper/StepperButtonRow';
import { FormWithGaps } from './commonElements';

type SelectTrainerFormData = {
    trainerId: string | undefined;
};
export default function SelectTrainerForm(props: StepperFormProps<NewSchedule>): JSX.Element {
    const { next, previous, newObject, setNewObject } = props;
    const { userData } = React.useContext(AuthContext);

    const { data: trainers, loading } = useGetRequest<UserModel[]>(
        `organisations/${userData.organisation?.id}/trainers`,
        !userData.organisation?.id,
    );

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        watch,
        setError,
    } = useNeveForm<SelectTrainerFormData>({
        trainerId: newObject.trainerId,
    });

    if (!trainers || loading) return <></>;

    const currentTrainerId = watch('trainerId');

    async function onSubmit(formData: SelectTrainerFormData) {
        if (isTrainerIdInvalid(formData.trainerId ?? '')) return;

        const trainer = trainers?.find((x) => x.id === formData.trainerId);

        setNewObject({
            ...newObject,
            trainerId: formData.trainerId,
            trainerName: trainer ? trainer.firstName + ' ' + trainer.lastName : undefined,
        });

        reset(formData);

        next();
    }

    async function onPrevious() {
        const trainer = trainers?.find((x) => x.id === currentTrainerId);

        setNewObject({
            ...newObject,
            trainerId: currentTrainerId,
            trainerName: trainer ? trainer.firstName + ' ' + trainer.lastName : undefined,
        });

        reset();

        previous();
    }

    function onBlurSelectTrainer() {
        isTrainerIdInvalid(currentTrainerId ?? '');
    }

    function isTrainerIdInvalid(trainerId: string): boolean {
        if (!trainerId) {
            setError('trainerId', {
                type: 'manual',
                message: strings.scheduleCourseStepper.selectTrainerForm.selectTrainerError,
            });
            return true;
        }
        return false;
    }

    return (
        <FormWithGaps onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.scheduleCourseStepper.selectTrainerForm.title}</Headline4>
            <BodyRegular>{strings.scheduleCourseStepper.selectTrainerForm.subtitle}</BodyRegular>
            <RadioFields
                maxWidth="300px"
                onBlur={onBlurSelectTrainer}
                options={
                    trainers?.map((trainer) => {
                        return {
                            value: trainer.id,
                            title: `${trainer.firstName} ${trainer.lastName}`,
                            aria: trainer.email,
                            inputProps: register('trainerId'),
                            name: 'trainerId',
                        };
                    }) ?? []
                }
            />
            {errors.trainerId && errors.trainerId.message && (
                <ErrorNotifierSlim description={errors.trainerId.message} />
            )}
            <StepperButtonRow
                submitButtonTitle={strings.scheduleCourseStepper.selectTrainerForm.submitButton}
                submitButtonAria={strings.scheduleCourseStepper.selectTrainerForm.submitButtonAria}
                previousButtonFunction={onPrevious}
            />
        </FormWithGaps>
    );
}
