import { StepperFormProps } from '../../../components/stepper/stepperTypes';
import { NewMeeting, NewSchedule } from '../ScheduleCoursePage';
import * as React from 'react';
import useNeveForm from '../../../components/forms/NeveForm';
import strings from '../../../strings/strings.json';
import { Headline4 } from '../../../components/Elements';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import StepperButtonRow from '../../../components/stepper/StepperButtonRow';
import { FormWithGaps } from './commonElements';
import CollapsibleMeetingCard from './CollapsibleMeetingCard';
import { LocationType } from '../../../models/ActivityMeeting';

type ConfigureMeetingsFormData = {
    meetings: NewMeeting[];
};
export function ConfigureMeetingsForm(props: StepperFormProps<NewSchedule>): JSX.Element {
    const { next, previous, newObject, setNewObject } = props;

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        watch,
        setError,
        setValue,
        clearErrors,
    } = useNeveForm<ConfigureMeetingsFormData>({
        meetings: newObject.meetings,
    });

    function validateLocation(
        locationType: LocationType | undefined,
        joinLink: string | undefined,
        location: string | undefined,
        meetingIndex: number,
    ): boolean {
        clearErrors(`meetings.${meetingIndex}.joinLink`);
        clearErrors(`meetings.${meetingIndex}.location`);
        clearErrors(`meetings.${meetingIndex}.locationType`);

        if (!locationType) {
            setError(`meetings.${meetingIndex}.locationType`, {
                type: 'manual',
                message: strings.scheduleCourseStepper.configureMeetingsForm.errors.locationType,
            });

            return false;
        }

        // eslint-disable-next-line eqeqeq
        if (locationType == LocationType.IN_PERSON) {
            if (!location) {
                setError(`meetings.${meetingIndex}.location`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.location,
                });

                return false;
            }
        } else {
            if (!joinLink) {
                // @ts-ignore
                setError(`meetings.${meetingIndex}.joinLink`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.joinLink,
                });

                return false;
            } else if (!joinLink.match(/^(https?:\/\/)/)) {
                // @ts-ignore
                setError(`meetings.${meetingIndex}.joinLink`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.joinLinkHttpsError,
                });

                return false;
            }
        }

        return true;
    }

    const currentMeetings = watch('meetings');

    async function onSubmit(formData: ConfigureMeetingsFormData) {
        let hasErrors = false;

        formData.meetings.forEach((meeting, meetingIndex) => {
            if (!meeting.startDate) {
                hasErrors = true;
                setError(`meetings.${meetingIndex}.startDate`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.meetingDate,
                });
            } else if (!!newObject.courseStartDate && newObject.courseStartDate > meeting.startDate) {
                hasErrors = true;
                setError(`meetings.${meetingIndex}.startDate`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.meetingBeforeCourseStartDate,
                });
            } else if (!!newObject.courseEndDate && newObject.courseEndDate < meeting.startDate) {
                hasErrors = true;
                setError(`meetings.${meetingIndex}.startDate`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.meetingAfterCourseEndDate,
                });
            }
            const hasLocationErrors = !validateLocation(
                meeting.locationType,
                meeting.joinLink,
                meeting.location,
                meetingIndex,
            );

            if (hasLocationErrors) hasErrors = true;

            if (!meeting.startTime) {
                hasErrors = true;
                setError(`meetings.${meetingIndex}.startTime`, {
                    type: 'manual',
                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.meetingTime,
                });
            }
        });

        if (hasErrors) return;

        setNewObject({
            ...newObject,
            meetings: formData.meetings,
        });

        reset(formData);

        next();
    }

    async function onPrevious() {
        setNewObject({
            ...newObject,
            meetings: currentMeetings,
        });

        reset();

        previous();
    }

    return (
        <FormWithGaps onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.scheduleCourseStepper.configureMeetingsForm.title}</Headline4>
            <Activities>
                {currentMeetings
                    .sort((x, y) => {
                        return x.order < y.order ? -1 : 1;
                    })
                    .map((meeting, meetingIndex) => {
                        function setMeetingStartDate(date: Date) {
                            const updatedMeetings = [...currentMeetings];
                            updatedMeetings[meetingIndex].startDate = date;

                            if (!date) {
                                setError(`meetings.${meetingIndex}.startDate`, {
                                    type: 'manual',
                                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.meetingDate,
                                });
                            }

                            setValue('meetings', updatedMeetings);
                            clearErrors(`meetings.${meetingIndex}.startDate`);
                        }

                        function setMeetingStartTime(newStartTime: Date) {
                            const updatedMeetings = [...currentMeetings];
                            updatedMeetings[meetingIndex].startTime = newStartTime;

                            if (!newStartTime) {
                                setError(`meetings.${meetingIndex}.startTime`, {
                                    type: 'manual',
                                    message: strings.scheduleCourseStepper.configureMeetingsForm.errors.meetingTime,
                                });
                            }

                            setValue('meetings', updatedMeetings);
                            clearErrors(`meetings.${meetingIndex}.startTime`);
                        }

                        const meetingErrors = errors?.meetings !== undefined ? errors?.meetings[meetingIndex] : null;
                        const meetingStartDateError = meetingErrors?.startDate?.message;
                        const meetingStartTimeError = meetingErrors?.startTime?.message;
                        const meetingJoinLinkError = meetingErrors?.joinLink?.message;
                        const meetingLocationError = meetingErrors?.location?.message;
                        const meetingLocationTypeError = meetingErrors?.locationType?.message;

                        return (
                            <CollapsibleMeetingCard
                                key={meetingIndex}
                                meetingDate={meeting.startDate}
                                setMeetingDate={setMeetingStartDate}
                                meetingTime={meeting.startTime}
                                setMeetingTime={setMeetingStartTime}
                                title={meeting.title}
                                moduleName={meeting.moduleName}
                                durationInMins={meeting.durationInMins}
                                courseStartDate={newObject.courseStartDate ?? new Date()}
                                courseEndDate={newObject.courseEndDate ?? new Date()}
                                validateLocation={validateLocation}
                                meetingIndex={meetingIndex}
                                meetingLocationType={meeting.locationType}
                                register={register}
                                meetingJoinLink={meeting.joinLink}
                                meetingLocation={meeting.location}
                                meetingStartDateError={meetingStartDateError}
                                meetingStartTimeError={meetingStartTimeError}
                                meetingJoinLinkError={meetingJoinLinkError}
                                meetingLocationTypeError={meetingLocationTypeError}
                                meetingLocationError={meetingLocationError}
                                setValue={setValue}
                            />
                        );
                    })}
            </Activities>
            <StepperButtonRow
                submitButtonTitle={strings.scheduleCourseStepper.configureMeetingsForm.submitButton}
                submitButtonAria={strings.scheduleCourseStepper.configureMeetingsForm.submitButtonAria}
                previousButtonFunction={onPrevious}
            />
        </FormWithGaps>
    );
}

const Activities = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    background: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.borderRadiusMd};
    padding: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        padding: ${sizes.spacingMd} 0;
    }
`;
