import strings from '../../../strings/strings.json';
import SuccessCard from '../../../components/notifiers/SuccessCard';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contextProviders/AuthContext';
import React from 'react';
import { RoleType } from 'models/User';

type CoursePublishSuccessCardProps = {
    courseTitle: string;
    courseId: string;
};

export default function CoursePublishSuccessCard({
    courseTitle,
    courseId,
}: CoursePublishSuccessCardProps): JSX.Element {
    const navigate = useNavigate();
    const { userData } = React.useContext(AuthContext);

    return (
        <SuccessCard
            notificationTitle={'Course published!'}
            notificationDescription={`Your "${courseTitle}" course has been successfully published.`}
            primaryButtonClick={() =>
                userData.activeRole === RoleType.TRAINER
                    ? navigate(`/schedule/${courseId}`, {
                          state: {
                              returnUrl: `/trainer/scheduled-courses`,
                              backUrl: `/trainer/course/${courseId}/view`,
                          },
                      })
                    : navigate(`/schedule/${courseId}`, {
                          state: { returnUrl: `/organisation/dashboard`, backUrl: `/trainer/course/${courseId}/view` },
                      })
            }
            primaryButtonTitle={strings.courseEditorPage.scheduleButtonTitle}
            primaryButtonAria={strings.courseEditorPage.scheduleButtonTitleAria}
            secondaryButtonClick={() =>
                navigate(userData.activeRole === RoleType.TRAINER ? `/trainer/dashboard` : `/organisation/dashboard`)
            }
            secondaryButtonTitle={strings.publishCourseWarningPage.returnToDashboardButtonTitle}
            secondaryButtonAria={strings.publishCourseWarningPage.returnToDashboardButtonAria}
            big
        />
    );
}
