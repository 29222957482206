import strings from '../../../strings/strings.json';
import ActivityWizardButtonRow from './ActivityWizardButtonRow';
import useNeveForm from 'components/forms/NeveForm';
import styled from 'styled-components';
import { Headline4 } from 'components/Elements';
import TextField from 'components/forms/TextField';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { validateThreeCharacterLimit } from 'lib/custom-form-validation';
import { BuildTaskMethod, TaskActivity } from 'models/inputModels/TaskActivity';
import { requiredFieldErrorMessage, createErrorMessage, fieldErrorCodes } from 'shared/error-messages';
import { sizes } from 'theme';
import DropdownField from './DropdownField';
import { TextAreaField } from 'components/forms/TextAreaField';
import Hint from 'components/Hint';

type TaskDetailsInput = {
    title: string;
    description: string;
    durationInMins: number | null;
    buildMethod: BuildTaskMethod;
};

export default function TaskDetailsForm(props: StepperFormProps<TaskActivity>): JSX.Element {
    const { next, newObject, setNewObject } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useNeveForm<TaskDetailsInput>({
        title: newObject.title ?? '',
        description: newObject.description ?? '',
        durationInMins: newObject.durationInMins ?? null,
        buildMethod: newObject.buildMethod,
    });

    const description = watch('description');

    async function submit(formData: TaskDetailsInput) {
        setNewObject({
            ...newObject,
            title: formData.title,
            description: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
            buildMethod: formData.buildMethod,
        });

        reset(formData);
        next();
    }

    const buildMethods: BuildTaskMethod[] = ['Text & image', 'Video', 'Audio'];

    const titleMaxLength = 100;
    const descriptionMaxLength = 500;

    return (
        <PaddedForm onSubmit={handleSubmit(submit)}>
            <Headline4>{strings.taskDetailsForm.instruction}</Headline4>
            <DropdownField
                id={'build-task-dropdown'}
                aria={strings.taskDetailsForm.buildMethodAria}
                options={buildMethods}
                labelText={strings.taskDetailsForm.buildMethodLabel}
                required
                inputProps={register('buildMethod', {
                    required: strings.taskDetailsForm.buildMethodLabelRequired,
                })}
            />
            <TextField
                fieldName="activityTitle"
                maxLength={titleMaxLength}
                labelText={strings.taskDetailsForm.titleInputLabel}
                inputAria={strings.taskDetailsForm.titleInputAria}
                inputProps={register('title', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.taskDetailsForm.titleInputLabel),
                    },
                    maxLength: {
                        value: titleMaxLength,
                        message: createErrorMessage(
                            strings.taskDetailsForm.titleInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                errorMessage={errors.title?.message}
                required
                autoFocus
            />
            <Hint hint={strings.taskDetailsForm.descriptionInputHint} />
            <TextAreaField
                fieldName={strings.taskDetailsForm.descriptionInputLabel}
                maxLength={descriptionMaxLength}
                charactersLeft={descriptionMaxLength - description.length}
                aria={strings.taskDetailsForm.descriptionInputAria}
                inputProps={register('description', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.taskDetailsForm.descriptionInputLabel),
                    },
                    maxLength: {
                        value: descriptionMaxLength,
                        message: createErrorMessage(
                            strings.taskDetailsForm.descriptionInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                errorMessage={errors.description?.message}
                required
            />
            <TextField
                fieldName="activityTaskDuration"
                maxLength={3}
                labelText={strings.taskDetailsForm.durationInputLabel}
                inputAria={strings.taskDetailsForm.durationInputAria}
                inputProps={register('durationInMins', {
                    validate: {
                        validateThreeCharacterLimit,
                    },
                    maxLength: {
                        value: 3,
                        message: createErrorMessage(
                            strings.taskDetailsForm.durationInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                errorMessage={errors.durationInMins?.message}
                inputType="number"
                width="8rem"
                flavourText={strings.taskDetailsForm.durationInputMins}
            />
            <ActivityWizardButtonRow
                submitButtonTitle={strings.taskDetailsForm.submitButton}
                submitButtonAria={strings.taskDetailsForm.submitButtonAria}
                showPreviousButton={false}
            />
        </PaddedForm>
    );
}

const PaddedForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;
