import * as React from 'react';
import strings from 'strings/strings.json';
import useGetRequest from '../../../hooks/useGetRequest';
import { CopyLinkModal, CopyLinkModalProps } from '../../../components/modals/CopyLinkModal';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { breakpoints, sizes, theme } from '../../../theme';
import MainSection from '../../../components/sections/MainSection';
import SubpageHeader from '../../../components/sections/SubpageHeader';
import styled from 'styled-components';
import Table, { RightAlignedButtonCell, TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import { WrappingText } from 'components/Elements';
import Skeleton from 'react-loading-skeleton';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { CourseScheduleOverview } from 'models/CourseScheduleOverview';
import CancelledIndicator from '../../OA/components/CancelledIndicator';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import NavigateWithArrow from '../../../components/actions/NavigateWithArrow';
import { useLocation, useNavigate } from 'react-router-dom';
import ValidateIcon from 'assets/icons/indicators/ValidateIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import ScheduleIcon from 'assets/icons/pathway/ScheduleIcon';
import WarningIcon from '../../../assets/icons/indicators/WarningIcon';
import { ErrorMessage } from '../../../components/forms/FormComponents';

type CopyLinkModalState = {
    modalProps: CopyLinkModalProps;
    modalOpen: boolean;
};

type State = {
    newScheduledCourseId: string;
};

export default function ScheduledCoursesPage(): JSX.Element {
    const { isMobileWidth, currentWindowWidth } = useWindowWidth();
    const navigate = useNavigate();

    const { state } = useLocation();
    const { newScheduledCourseId } = (state as State) ?? {};

    const scrollRef = React.createRef<any>();

    React.useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    });

    const { data, loading, errors } = useGetRequest<CourseScheduleOverview[]>('courses/schedule');

    const [copyLinkState, setCopyLinkState] = React.useState<CopyLinkModalState>({
        modalProps: {} as CopyLinkModalProps,
        modalOpen: false,
    });

    function openCopyModal(url: string) {
        setCopyLinkState({
            modalOpen: true,
            modalProps: {
                title: strings.scheduledCourseDetails.schedule.copyLinkModalTitle,
                description: strings.scheduledCourseDetails.schedule.copyLinkModalDescription,
                inputValue: url,
                primaryButtonTitle: strings.scheduledCourseDetails.schedule.copyButtonTitle,
                primaryButtonAria: strings.scheduledCourseDetails.schedule.copyButtonAria,
                closeButtonAria: strings.scheduledCourseDetails.schedule.closeButtonAria,
                onClickCloseButton: () => {},
            },
        });
    }

    function closeCopyModal() {
        setCopyLinkState({
            modalOpen: false,
            modalProps: {} as CopyLinkModalProps,
        });
    }

    if (loading) {
        return (
            <>
                <SubpageHeader
                    title={strings.scheduledCoursesPage.title}
                    subtitle={strings.scheduledCoursesPage.subtitle}
                    shadedBackground
                />
                <MainSectionWithGaps>
                    <Skeleton height={isMobileWidth ? 224 : 100} count={3} />
                </MainSectionWithGaps>
            </>
        );
    }

    if (errors && errors.length > 0) {
        return (
            <>
                <SubpageHeader
                    title={strings.scheduledCoursesPage.title}
                    subtitle={strings.scheduledCoursesPage.subtitle}
                    shadedBackground
                />
                <MainSectionWithGaps>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.scheduledCoursesPage.getScheduledCoursesErrorMessage}
                    />
                </MainSectionWithGaps>
            </>
        );
    }

    const scheduledCourseRows: TableRow[] = (data ?? [])
        ?.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
        .sort((a, b) => {
            if (a.cancelled && !b.cancelled) {
                return 1;
            } else if (!a.cancelled && b.cancelled) {
                return -1;
            } else {
                return 0;
            }
        })
        .flatMap((courseScheduleOverview) => {
            const shortDate = new Date(courseScheduleOverview.startDate).toLocaleDateString();
            const linkAria = `${strings.scheduledCoursesPage.viewCourseDetailsButtonAria} ${courseScheduleOverview.courseTitle}`;

            return {
                id: courseScheduleOverview.id,
                cells: [
                    {
                        name: strings.scheduledCoursesPage.tableHeaders.courseTitle,
                        isBold: true,
                        value: (
                            <ScheduledCourseContainer
                                ref={newScheduledCourseId === courseScheduleOverview.id ? scrollRef : null}
                            >
                                {currentWindowWidth > parseInt(breakpoints.xl) &&
                                    courseScheduleOverview.id === newScheduledCourseId && <ValidateIcon />}
                                <WrappingText>{courseScheduleOverview.courseTitle}</WrappingText>
                            </ScheduledCourseContainer>
                        ),
                    },
                    {
                        name: strings.scheduledCoursesPage.tableHeaders.startDate,
                        value: shortDate,
                    },
                    {
                        name: strings.scheduledCoursesPage.tableHeaders.learners,
                        value:
                            currentWindowWidth < parseInt(breakpoints.md)
                                ? ''
                                : courseScheduleOverview.enrolledLearnersCount,
                    },
                    {
                        name: strings.OAScheduledCoursesTable.tableHeaders.status,
                        value: courseScheduleOverview.cancelled ? (
                            <div>
                                <StatusContainer>
                                    <CancelledIndicator />
                                </StatusContainer>
                            </div>
                        ) : (
                            <div>
                                {courseScheduleOverview.enrolledLearnersCount >=
                                courseScheduleOverview.maximumLearners ? (
                                    <EnrolmentFullContainer>
                                        <WarningIcon />
                                        <ErrorMessage $bold>
                                            {strings.scheduledCoursesPage.courseFullWarning}
                                        </ErrorMessage>
                                    </EnrolmentFullContainer>
                                ) : (
                                    ''
                                )}
                            </div>
                        ),
                    },
                    {
                        name: strings.scheduledCoursesPage.tableHeaders.shareLink,
                        value: (
                            <SecondaryButton
                                title={strings.scheduledCoursesPage.shareLinkButtonTitle}
                                aria={`${strings.scheduledCoursesPage.shareLinkButtonAria} ${courseScheduleOverview.courseTitle}`}
                                onClick={() => {
                                    const copyLinkUrl = `${origin}/public/course/${courseScheduleOverview.id}`;
                                    openCopyModal(copyLinkUrl);
                                }}
                                compact
                                disabled={
                                    courseScheduleOverview.enrolledLearnersCount >=
                                    courseScheduleOverview.maximumLearners
                                }
                            />
                        ),
                    },
                    {
                        name: '',
                        value: courseScheduleOverview.cancelled ? (
                            ''
                        ) : (
                            <RightAlignedButtonCell>
                                <NavigateWithArrow
                                    title={''}
                                    href={`/schedule/courses/${courseScheduleOverview.id}`}
                                    aria={linkAria}
                                    iconSize={IconSize.LARGE}
                                />
                            </RightAlignedButtonCell>
                        ),
                    },
                ],
            };
        });

    return (
        <>
            <SubpageHeader
                title={strings.scheduledCoursesPage.title}
                subtitle={strings.scheduledCoursesPage.subtitle}
                shadedBackground
            />
            <MainSectionWithGaps>
                <div>
                    <Table
                        headers={[
                            {
                                name: strings.scheduledCoursesPage.tableHeaders.courseTitle,
                                order: 1,
                                rowPercentage: 30,
                            },
                            {
                                name: strings.scheduledCoursesPage.tableHeaders.startDate,
                                order: 2,
                                rowPercentage: 15,
                            },
                            {
                                name: strings.scheduledCoursesPage.tableHeaders.learners,
                                order: 3,
                                rowPercentage: 15,
                            },
                            {
                                name: strings.scheduledCoursesPage.tableHeaders.status,
                                order: 4,
                                rowPercentage: 20,
                            },
                            {
                                name: strings.scheduledCoursesPage.tableHeaders.shareLink,
                                order: 5,
                                rowPercentage: 15,
                            },
                            {
                                name: '',
                                order: 6,
                                rowPercentage: 5,
                                mobileRightColumn: true,
                            },
                        ]}
                        rows={scheduledCourseRows}
                        activeRow={newScheduledCourseId}
                        emptyTableContent={
                            <EmptyTableContent
                                icon={<ScheduleIcon size={IconSize.XLARGE} colour={theme.textColour} />}
                                title={strings.scheduledCoursesPage.emptyTableContent.title}
                                description={strings.scheduledCoursesPage.emptyTableContent.description}
                                buttonTitle={strings.scheduledCoursesPage.emptyTableContent.button.title}
                                buttonAria={strings.scheduledCoursesPage.emptyTableContent.button.aria}
                                buttonIcon={
                                    <ScheduleIcon
                                        colour={theme.primaryButtonTextColour}
                                        backgroundColour={theme.primaryButtonBackgroundColour}
                                    />
                                }
                                buttonOnClick={() => navigate('/trainer/dashboard')}
                            />
                        }
                    />
                </div>
                {copyLinkState.modalOpen && (
                    <CopyLinkModal {...copyLinkState.modalProps} onClickCloseButton={closeCopyModal} />
                )}
            </MainSectionWithGaps>
        </>
    );
}

const EnrolmentFullContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const StatusContainer = styled.div`
    display: inline-block;
`;

const MainSectionWithGaps = styled(MainSection)`
    margin-top: ${sizes.spacingLg};
    gap: ${sizes.spacingLg};
`;

const ScheduledCourseContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;
