import CloseIcon from 'assets/icons/controls/CloseIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import LightBulbIcon from 'assets/icons/LightBulbIcon';
import { BodySmall, FlexCol, TertiaryButton } from 'components/Elements';
import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from 'theme';
import strings from '../strings/strings.json';

type HintProps = {
    hint: string;
    button?: JSX.Element;
};

export default function Hint({ hint, button }: HintProps): JSX.Element {
    const [showHint, setShowHint] = React.useState<boolean>(true);

    if (!showHint) return <></>;

    return (
        <HintContainer>
            <Column>
                <TopRowContainer>
                    <IconAndText>
                        <LightBulbIconContainer>
                            <LightBulbIcon size={IconSize.MEDIUM} colour={theme.textColour} />
                        </LightBulbIconContainer>
                        <BodySmall>{hint}</BodySmall>
                    </IconAndText>
                    <ButtonContainer>
                        <TertiaryButton onClick={() => setShowHint(false)} aria-label={strings.hint.closeButtonAria}>
                            <CloseIcon size={IconSize.SMALL} colour={theme.textColour} />
                        </TertiaryButton>
                    </ButtonContainer>
                </TopRowContainer>
                {button}
            </Column>
        </HintContainer>
    );
}

const ButtonContainer = styled.div`
    margin-left: ${sizes.spacingMd};
`;

const IconAndText = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const LightBulbIconContainer = styled.div`
    width: ${sizes.spacingRg};
`;

const Column = styled(FlexCol)`
    gap: ${sizes.spacingMd};
`;

const TopRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HintContainer = styled.div`
    background-color: ${theme.courseEditHintBackgroundColour};
    padding: ${sizes.spacingRg};
    width: 100%;
`;
