import styled from 'styled-components';
import { BodyRegular, Card, Headline4 } from 'components/Elements';
import { breakpoints, sizes } from 'theme';
import strings from 'strings/strings.json';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import CourseCardDateSection from 'pages/learner/components/CourseCardDateSection';
import ArrowRightIcon from 'assets/icons/controls/ArrowRightIcon';
import ProgressBar from 'components/progressBar/ProgressBar';

export type CourseOverview = {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    description: string;
    cancelledDate: string | null;
    currentLearners: number;
    maximumLearners: number;
};

type CourseCardProps = {
    courseOverview: CourseOverview;
    cardHeaderColour: string;
};

export default function CourseCardWithColouredHeader({
    courseOverview,
    cardHeaderColour,
}: CourseCardProps): JSX.Element {
    const navigate = useNavigate();
    const linkTitle = strings.learnerCourseCard.linkTitle;
    const linkTitleAria = `${strings.learnerCourseCard.linkTitleAria}: ${courseOverview.title}`;
    const linkHref = `/public/course/${courseOverview.id}`;

    return (
        <CourseCard $cardHeaderColour={cardHeaderColour}>
            <CourseTitleHeadine>{courseOverview.title}</CourseTitleHeadine>

            <CourseCardDateSection
                startDate={courseOverview.startDate}
                endDate={courseOverview.endDate}
                cancelled={!!courseOverview.cancelledDate}
            />

            <BarContainer>
                <ProgressBar
                    label={strings.publicCoursePage.progressBarLabel}
                    max={courseOverview.maximumLearners}
                    current={courseOverview.currentLearners}
                    reverseFractionAmount
                />
            </BarContainer>

            <DescriptionText>{courseOverview.description}</DescriptionText>

            <PrimaryButton
                title={linkTitle}
                aria={linkTitleAria}
                icon={<ArrowRightIcon />}
                iconPositionRight
                fullwidth
                onClick={() => {
                    navigate(linkHref);
                }}
            />
        </CourseCard>
    );
}

const CourseCard = styled(Card)<{ $cardHeaderColour: string }>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: ${sizes.spacingRg};
    border-top: 40px solid ${({ $cardHeaderColour }) => $cardHeaderColour};
    border-radius: 30px;
    gap: ${sizes.spacingMd};
`;

const CourseTitleHeadine = styled(Headline4)`
    height: 6rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    @media (max-width: ${breakpoints.sm}) {
        height: 5rem;
    }
`;

const BarContainer = styled.div`
    width: 100%;
`;

const DescriptionText = styled(BodyRegular)`
    height: 5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
`;
