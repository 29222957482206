import strings from '../../../strings/strings.json';
import React from 'react';
import { TextAreaField } from 'components/forms/TextAreaField';
import useModifyRequest from 'hooks/useModifyRequest';
import { createErrorMessage, fieldErrorCodes } from 'shared/error-messages';
import useNeveForm from 'components/forms/NeveForm';
import { FormCard } from 'components/forms/FormComponents';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import { Organisation } from 'models/Organisation';
import styled from 'styled-components';
import { sizes } from 'theme';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { FlexAlignCenter, FlexCol } from 'components/Elements';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { useNavigate } from 'react-router-dom';

type OrganisationDescription = {
    description: string;
};

type OrganisationDescriptionFormProps = {
    organisation: Organisation;
    refetchOrganisation: () => Promise<void>;
    savedDescription: boolean;
    setSavedDescription: (value: React.SetStateAction<boolean>) => void;
};

export function OrganisationDescriptionForm({
    organisation,
    refetchOrganisation,
    savedDescription,
    setSavedDescription,
}: OrganisationDescriptionFormProps): JSX.Element {
    const navigate = useNavigate();
    const [showError, setShowError] = React.useState<boolean>(false);

    const { modifyData: updateDescription, loading: updatingDescription } = useModifyRequest(
        `organisations/${organisation.id}/description`,
        'PATCH',
    );

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isDirty },
        reset,
    } = useNeveForm<OrganisationDescription>({
        description: organisation?.description ?? '',
    });

    async function onUpdateOrganisationDescription({ description }: OrganisationDescription) {
        setShowError(false);

        const response = await updateDescription({ description: description });

        if (response.errors && response.errors.length > 0) {
            setShowError(true);
        } else {
            setSavedDescription(true);
            reset({ description });
            await refetchOrganisation();
        }
    }

    return (
        <OrganisationDescriptionFormCard onSubmit={handleSubmit(onUpdateOrganisationDescription)}>
            <TextAreaField
                maxLength={500}
                charactersLeft={500 - watch('description').length}
                fieldName={strings.updateOrganisationForm.descriptionInput.title}
                aria={strings.updateOrganisationForm.descriptionInput.aria}
                errorMessage={errors.description?.message}
                inputProps={register('description', {
                    maxLength: {
                        value: 500,
                        message: createErrorMessage(
                            strings.updateOrganisationForm.descriptionInput.title,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
            />
            <ButtonContainer>
                <SaveButtonContainer>
                    {showError && <ErrorNotifierSlim description={strings.updateOrganisationForm.error} />}
                    {savedDescription && !isDirty && (
                        <Notification
                            notificationType={NotificationType.SUCCESS}
                            title={strings.general.saveSuccess}
                        />
                    )}
                    <PrimaryButton
                        type="submit"
                        title={strings.updateOrganisationForm.submitButton.title}
                        aria={strings.updateOrganisationForm.submitButton.aria}
                        disabled={updatingDescription}
                    />
                </SaveButtonContainer>
                <SecondaryButton
                    title={strings.updateOrganisationForm.viewOrgButton.title}
                    aria={strings.updateOrganisationForm.viewOrgButton.aria}
                    fullwidth
                    onClick={() => {
                        navigate(`/public/organisation/${organisation.publicName}`);
                    }}
                />
            </ButtonContainer>
        </OrganisationDescriptionFormCard>
    );
}

const OrganisationDescriptionFormCard = styled(FormCard)`
    border: unset;
    box-shadow: unset;
`;

const ButtonContainer = styled(FlexCol)`
    gap: ${sizes.spacingMd};
`;

const SaveButtonContainer = styled(FlexAlignCenter)`
    justify-content: end;
    min-height: 72px;
    gap: ${sizes.spacingMd};
`;
