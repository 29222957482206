import { LearnerLiveTraining } from '../../../../../models/LearnerLiveTraining';
import { APIError, DOCUMENTS_URL } from '../../../../../lib/_api-helpers';
import { ContentColumn, LearnerCourseContentError, Page } from '../../../components/shared';
import strings from '../../../../../strings/strings.json';
import { BodyLarge, BodyRegular, FlexCol, Headline2, Headline4 } from '../../../../../components/Elements';
import TimeIcon from '../../../../../assets/icons/pathway/TimeIcon';
import { breakpoints, sizes, theme } from '../../../../../theme';
import ScheduleIcon from '../../../../../assets/icons/pathway/ScheduleIcon';
import LocationIcon from '../../../../../assets/icons/controls/LocationIcon';
import { IconSize } from '../../../../../assets/icons/icon-sizes';
import styled from 'styled-components';
import { LocationType } from '../../../../../models/ActivityMeeting';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';
import { Content, GreenBanner } from '../../../components/MarkAsCompleteCard';
import { CourseNavigationActivity } from '../../../components/CourseNavigation';
import { HeadlineAndLabelContainer } from '../task/TaskContentLayout';
import { ActivityType } from 'models/Activity';
import ActivityTypeLabel from '../../../../edit-course/components/ActivityTypeLabel';
import { LearnerCardBase } from 'pages/learner/sharedStyles/LearnerStyles';

type LiveTrainingContentLayoutProps = {
    liveTraining: LearnerLiveTraining | null;
    loading: boolean;
    errors: APIError[];
};

export default function LiveTrainingContentLayout({
    liveTraining,
    loading,
    errors,
}: LiveTrainingContentLayoutProps): JSX.Element {
    if (loading || !liveTraining) return <></>;

    if (errors.length > 0) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    const meetingDate = liveTraining.startDateTime ? new Date(liveTraining.startDateTime) : new Date();

    const meetingDateString = meetingDate.toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });

    const meetingStartTimeString = meetingDate
        .toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' })
        .replaceAll(' ', '');

    const meetingEndTime = new Date(meetingDate.getTime() + 60000 * (liveTraining.durationInMins ?? 1));

    const meetingEndTimeString = meetingEndTime
        .toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' })
        .replaceAll(' ', '');

    // eslint-disable-next-line eqeqeq
    const locationFriendlyText = liveTraining.locationType == LocationType.ONLINE ? 'Online' : 'In person';

    return (
        <Page>
            <ContentColumn>
                <HeadlineAndLabelContainer>
                    <Headline2>{liveTraining.title}</Headline2>
                    <ActivityTypeLabel activityType={ActivityType.LIVE_TRAINING} />
                </HeadlineAndLabelContainer>

                <InfoCard>
                    <InfoContainer>
                        <InfoHeader>
                            <ScheduleIcon colour={theme.fiord} size={16} />
                            <BodyLarge $bold>{strings.liveTrainingPage.dateLabel}</BodyLarge>
                        </InfoHeader>
                        <BodyLarge>{meetingDateString}</BodyLarge>
                    </InfoContainer>

                    <InfoContainer>
                        <InfoHeader>
                            <TimeIcon colour={theme.fiord} size={16} />
                            <BodyLarge $bold>{strings.liveTrainingPage.timeLabel}</BodyLarge>
                        </InfoHeader>
                        <BodyLarge>
                            {meetingStartTimeString} - {meetingEndTimeString}
                        </BodyLarge>
                    </InfoContainer>

                    <InfoContainer>
                        <InfoHeader>
                            <LocationIcon colour={theme.fiord} size={IconSize.SMALL} />
                            <BodyLarge $bold>{strings.liveTrainingPage.locationLabel}</BodyLarge>
                        </InfoHeader>
                        <BodyLarge>{locationFriendlyText}</BodyLarge>
                        <ActionContainer>
                            {/* eslint-disable-next-line eqeqeq */}
                            {liveTraining.locationType == LocationType.IN_PERSON ? (
                                <BodyRegular>{liveTraining.location}</BodyRegular>
                            ) : (
                                <>
                                    {meetingEndTime > new Date() && (
                                        <PrimaryButton
                                            onClick={() => window.open(liveTraining.joinLink)}
                                            aria={strings.liveTrainingPage.joinMeetingButtonAria}
                                            title={strings.liveTrainingPage.joinMeetingButton}
                                        />
                                    )}
                                </>
                            )}
                        </ActionContainer>
                    </InfoContainer>
                </InfoCard>

                {liveTraining.videoDocument ? (
                    <VideoContainer>
                        <Border />
                        <Headline4>{strings.liveTrainingPage.videoRecordingHeader}</Headline4>
                        <video
                            controls
                            controlsList="nodownload"
                            src={`${DOCUMENTS_URL}/${liveTraining.videoDocument.s3Filepath}`}
                            width="100%"
                        />
                    </VideoContainer>
                ) : (
                    <></>
                )}
            </ContentColumn>
            <Footer>
                {meetingEndTime < new Date() && (
                    <GreenBanner>
                        <Content $completed={true}>
                            <BodyLarge $inverted $bold>
                                {strings.liveTrainingPage.meetingFinishedNotice}
                            </BodyLarge>
                        </Content>
                    </GreenBanner>
                )}
                <CourseNavigationActivity activityId={liveTraining.id} />
            </Footer>
        </Page>
    );
}

const Footer = styled.div`
    width: 100%;
`;

const InfoCard = styled(LearnerCardBase)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingMd};
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXs};
    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const InfoHeader = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const ActionContainer = styled.div`
    padding: ${sizes.spacingRg} 0;
    @media (max-width: ${breakpoints.sm}) {
        display: flex;
        justify-content: center;
    }
`;
const VideoContainer = styled(FlexCol)`
    gap: ${sizes.spacingMd};
`;

const Border = styled.div`
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
`;
