import UploadIcon from 'assets/icons/controls/UploadIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import PrimaryButton from 'components/buttons/PrimaryButton';
import FileUpload from 'components/fileUpload/FileUpload';
import { FileMetaData, FileType } from 'components/fileUpload/strategies/strategies';
import Hint from 'components/Hint';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import useModifyRequest from 'hooks/useModifyRequest';
import useUploadRequest from 'hooks/useUploadRequest';
import { logError } from 'lib/debug-helpers';
import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from 'theme';
import strings from '../../../strings/strings.json';
import CreateCourseSuccess from './CreateCourseSuccess';
import { FlexCol } from 'components/Elements';

type CreateCourseUrl = 'courses/create-with-scorm' | 'courses/create-with-powerpoint';

type CreateCourseWithFileFormProps = {
    createCourseUrl: CreateCourseUrl;
    closeModal: () => void;
};

export default function CreateCourseWithFileForm({
    createCourseUrl,
    closeModal,
}: CreateCourseWithFileFormProps): JSX.Element {
    let hint: string;
    let fileType: FileType;

    switch (createCourseUrl) {
        case 'courses/create-with-scorm':
            hint = strings.createCourseModal.createCourseForm.hint.SCORM;
            fileType = FileType.SCORM;
            break;
        case 'courses/create-with-powerpoint':
            hint = strings.createCourseModal.createCourseForm.hint.PowerPoint;
            fileType = FileType.POWERPOINT;
            break;
    }

    const { upload, uploading } = useUploadRequest();
    const { modifyData: createCourseWithPowerPoint, loading: creating } = useModifyRequest(createCourseUrl, 'POST');

    const [tempFileFromLocal, setTempFileFromLocal] = React.useState<File | undefined>();
    const [fileValid, setFileValid] = React.useState<boolean>(false);
    const [fileMetaDataValid, setFileMetaDataValid] = React.useState<boolean>(false);
    const [fileMetaData, setFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [error, setError] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (tempFileFromLocal) {
            setError('');
        }
    }, [tempFileFromLocal]);

    async function uploadFile() {
        setError(null);
        if (!tempFileFromLocal) {
            setError(strings.fileUploadPage.noFileText);
            return;
        }
        if (!fileValid) return;
        if (!fileMetaDataValid) return;

        const uploadResponse = await upload(tempFileFromLocal);

        if (uploadResponse.errors?.length) {
            logError(uploadResponse.errors);
            setError(strings.createCourseModal.createCourseForm.error);
            return;
        }
        if (!uploadResponse.value) {
            setError(strings.createCourseModal.createCourseForm.error);
            return;
        }

        type CreateCourseWithPowerPointRequest = {
            fileName: string;
            s3Key: string;
        };

        const { errors } = await createCourseWithPowerPoint<CreateCourseWithPowerPointRequest, boolean>({
            fileName: uploadResponse.value.filename,
            s3Key: uploadResponse.value.s3Key,
        });
        if (errors) {
            logError(errors);
            setError(strings.createCourseModal.createCourseForm.error);
            return;
        }

        setSuccess(true);
    }

    if (success) {
        return <CreateCourseSuccess error={error} closeModal={closeModal} />;
    }

    return (
        <Container>
            <Hint hint={hint} />
            <FileUpload
                localFile={tempFileFromLocal}
                setLocalFile={(newFile: File | undefined) => {
                    setTempFileFromLocal(newFile);
                }}
                fileMetaData={fileMetaData}
                setFileMetaData={(newFileMetaData: FileMetaData | undefined) => {
                    setFileMetaData(newFileMetaData);
                }}
                fileValid={fileValid}
                setFileValid={(fileValid: boolean) => {
                    setFileValid(fileValid);
                }}
                fileMetaDataValid={fileMetaDataValid}
                setFileMetaDataValid={(fileMetaDataValid: boolean) => {
                    setFileMetaDataValid(fileMetaDataValid);
                }}
                chosenFileType={fileType}
            />
            <ButtonRow>
                {error && <ErrorNotifierSlim description={error} />}
                <PrimaryButton
                    title={strings.liveTrainingDetailPage.uploadVideoTab.uploadButtonTitle}
                    aria={strings.liveTrainingDetailPage.uploadVideoTab.uploadButtonAria}
                    onClick={uploadFile}
                    submitting={uploading || creating}
                    icon={<UploadIcon colour={theme.cardBackground} size={IconSize.SMALL} />}
                />
            </ButtonRow>
        </Container>
    );
}
const Container = styled(FlexCol)`
    gap: ${sizes.spacingMd};
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: end;
    gap: ${sizes.spacingMd};
`;
