import { RoleType } from 'models/User';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../contextProviders/AuthContext';

export default function LoginSuccessPage(): JSX.Element {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { userData, exchangeToken, isAuthenticated, setNewActiveRole, loginFailed, setLoginFailed } =
        React.useContext(AuthContext);
    const code = params.get('code');

    React.useEffect(() => {
        if (!code) return;

        exchangeToken(code).then();
        // eslint-disable-next-line
    }, [code]);

    if (loginFailed) {
        setLoginFailed(false);
        navigate('/auth/login', {
            state: { loginFailed: true },
        });
    }

    if (isAuthenticated) {
        if (userData.roles.some((x) => x === RoleType.ADMIN)) {
            setNewActiveRole(RoleType.ADMIN);
        } else if (userData.roles.some((x) => x === RoleType.ORGANISATION_ADMIN)) {
            setNewActiveRole(RoleType.ORGANISATION_ADMIN);
        } else if (userData.roles.some((x) => x === RoleType.TRAINER)) {
            setNewActiveRole(RoleType.TRAINER);
        } else if (userData.roles.some((x) => x === RoleType.LEARNER)) {
            setNewActiveRole(RoleType.LEARNER);
        } else {
            setNewActiveRole('');
        }

        const returnPath = params.get('state');

        if (returnPath) {
            navigate(returnPath);
        } else {
            navigate('/');
        }
    }

    return <p>Logging in...</p>;
}
