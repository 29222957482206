import * as React from 'react';
import strings from 'strings/strings.json';
// @ts-ignore
import { ReactComponent as CalendarIcon } from 'assets/icons/schedule-icons/schedule-white.svg';
import { useNavigate, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { Course } from 'models/Course';
import SubpageHeader from '../../../components/sections/SubpageHeader';
import CourseOverview from 'components/courseSchedule/CourseOverview';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';

export default function OACoursePage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const navigate = useNavigate();

    const { data: course, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const goToPreview = () => {
        navigate(`/preview/${courseId}`, {
            state: { previewContextEntryPoint: `/organisation/dashboard/${courseId}/view` },
        });
    };

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.OACoursePage.backToCoursesButtonTitle,
            aria: strings.OACoursePage.backToCoursesButtonTitleAria,
            href: '/organisation/dashboard',
        };
    }, []);

    if (loading || !course) return <></>;

    return (
        <>
            <SubpageHeader
                title={course.title}
                subtitle={course.description}
                backButtonDetails={backButtonDetails}
                shadedBackground
                buttons={
                    <>
                        <SecondaryButton
                            title={strings.courseEditorPage.previewCourseButtonTitle}
                            aria={strings.courseEditorPage.previewCourseButtonAria}
                            onClick={goToPreview}
                        />
                        <PrimaryButton
                            title={strings.coursePage.scheduleButtonTitle}
                            aria={strings.coursePage.scheduleButtonTitleAria}
                            onClick={() => {
                                navigate(`/schedule/${course.id}/`, {
                                    state: {
                                        returnUrl: `/organisation/dashboard`,
                                        backUrl: `/organisation/dashboard`,
                                    },
                                });
                            }}
                            icon={<CalendarIcon />}
                        />
                    </>
                }
            />
            <CourseOverview course={course} />
        </>
    );
}
