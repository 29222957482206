import * as React from 'react';
import { Link } from 'react-router-dom';
import strings from '../../strings/strings.json';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import { IconSize } from '../../assets/icons/icon-sizes';
import CloseIcon from '../../assets/icons/controls/CloseIcon';
import {
    NavigationSlimProps,
    StyledNeveLogo,
    NavigationPadding,
    LimitedContainer,
    NavigationContainer,
    NavigationLogoSection,
} from './Navigation';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import { AuthContext } from 'contextProviders/AuthContext';
import { useContext } from 'react';
import { DOCUMENTS_URL } from 'lib/_api-helpers';

export function NavigationSlim({ buttonTitle, buttonAria, buttonOnClick }: NavigationSlimProps): JSX.Element {
    const { userData } = useContext(AuthContext);

    return (
        <>
            <NavigationContainerSlim id={'nav'}>
                <LimitedContainerSlim>
                    <NavigationLogoSectionSlim>
                        {userData.organisation?.logoS3Filepath ? (
                            <Link to={'/'}>
                                <Image
                                    src={`${DOCUMENTS_URL}/${userData.organisation.logoS3Filepath}`}
                                    alt={`${userData.organisation.name} logo`}
                                    aria-label={strings.appHeader.neveLearningLogoAria}
                                />
                            </Link>
                        ) : (
                            <Link to={'/'}>
                                <StyledNeveLogo
                                    alt={strings.appHeader.neveLearningLogoAltText}
                                    aria-label={strings.appHeader.neveLearningLogoAria}
                                />
                            </Link>
                        )}
                    </NavigationLogoSectionSlim>
                    <NavigationSlimButtonContainer>
                        <SecondaryButton
                            title={buttonTitle}
                            aria={buttonAria}
                            icon={<CloseIcon size={IconSize.SMALL} />}
                            onClick={buttonOnClick}
                        />
                    </NavigationSlimButtonContainer>
                </LimitedContainerSlim>
            </NavigationContainerSlim>
            <NavigationSlimPadding />
        </>
    );
}

const NavigationContainerSlim = styled(NavigationContainer)`
    padding: 0 ${sizes.edgePadding};
    @media (max-width: ${breakpoints.sm}) {
        height: 85px;
        padding: ${sizes.edgePadding} 10px;
    }
`;

const LimitedContainerSlim = styled(LimitedContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NavigationLogoSectionSlim = styled(NavigationLogoSection)`
    @media (max-width: ${breakpoints.sm}) {
        border-bottom: unset;
        padding: unset;
    }
`;

const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    width: auto;
    max-height: 45px;
`;

const NavigationSlimButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const NavigationSlimPadding = styled(NavigationPadding)`
    @media (max-width: ${breakpoints.sm}) {
        height: ${sizes.headerMobileHeightSlim};
    }
`;
