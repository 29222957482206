import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: calc(100vh - 80px);
    @media (max-width: ${breakpoints.sm}) {
        min-height: calc(100vh - 85px);
    }
`;

export const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${sizes.spacingLg};
    width: 100%;
    max-width: 1000px;
    margin: ${sizes.spacingLg} 0;
    padding: 0 ${sizes.edgePadding};
`;

type LearnerCourseContentErrorProps = {
    title: string;
    description: string;
};

export function LearnerCourseContentError({ title, description }: LearnerCourseContentErrorProps): JSX.Element {
    return (
        <Page>
            <ContentColumn>
                <ErrorNotifier title={title} description={description} />
            </ContentColumn>
        </Page>
    );
}
