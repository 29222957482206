import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes } from '../../theme';
import { Headline1 } from '../Elements';

export default function LearnerHeader({ title, icon }: { title: string; icon?: JSX.Element }): JSX.Element {
    return (
        <BannerBackground>
            <LimitedContainer>
                <TitleAndLogoContainer>
                    {icon}
                    <Headline1>{title}</Headline1>
                </TitleAndLogoContainer>
            </LimitedContainer>
        </BannerBackground>
    );
}

const LimitedContainer = styled.header`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: 0 auto;
    @media (max-width: ${breakpoints.sm}) {
        margin: unset;
    }
`;

const BannerBackground = styled.div`
    height: 160px;
    background: white;
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        display: flex;
        align-items: center;
    }
`;

const TitleAndLogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;
