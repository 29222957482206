import strings from '../../strings/strings.json';
import useGetRequest from '../../hooks/useGetRequest';
import { NotificationModel } from '../../models/Notification';
import MainSection from '../../components/sections/MainSection';
import { sizes, theme } from '../../theme';
import { BodyRegular } from '../../components/Elements';
import useModifyRequest from '../../hooks/useModifyRequest';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import styled from 'styled-components';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import { Notifications } from 'components/notifications/Notifications';
import { useNavigate } from 'react-router-dom';
import LearnerHeader from 'components/sections/LearnerHeader';
import NotificationIcon from 'assets/icons/navigation/NotificationIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import useWindowWidth from 'hooks/useWindowWidth';

export default function NotificationsPage(): JSX.Element {
    const { isMobileWidth } = useWindowWidth();

    const {
        data: notifications,
        loading: loadingNotifications,
        errors,
        refetchData: refetchNotifications,
    } = useGetRequest<NotificationModel[]>('users/me/notifications');

    const { modifyData: acknowledgeAllNotifications, loading: acknowledgeAllNotificationsLoading } = useModifyRequest(
        `users/me/notifications/acknowledge/all`,
        'POST',
    );

    const navigate = useNavigate();

    const [showMarkAllAsReadError, setShowMarkAllAsReadError] = React.useState<boolean>(false);

    const showMarkAllNotificationsAsRead = notifications && notifications.some((n) => !n.acknowledged);

    async function markAllNotificationsAsRead() {
        setShowMarkAllAsReadError(false);

        const response = await acknowledgeAllNotifications({});
        if (!!response && !!response.value && response.value === true) {
            await refetchNotifications();
        } else {
            setShowMarkAllAsReadError(true);
        }
    }

    if (loadingNotifications) {
        return (
            <>
                <LearnerHeader
                    title={strings.notificationsPage.title}
                    icon={
                        <NotificationIcon
                            size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                            colour={theme.textColour}
                        />
                    }
                />
                <MainSectionWithPadding>
                    <Skeleton height={100} count={3} />
                </MainSectionWithPadding>
            </>
        );
    }

    if (!!errors && errors.length > 0) {
        return (
            <>
                <LearnerHeader
                    title={strings.notificationsPage.title}
                    icon={
                        <NotificationIcon
                            size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                            colour={theme.textColour}
                        />
                    }
                />
                <MainSectionWithPadding>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.notificationsPage.fetchError}
                    />
                </MainSectionWithPadding>
            </>
        );
    }

    if ((notifications ?? []).length === 0) {
        return (
            <>
                <LearnerHeader
                    title={strings.notificationsPage.title}
                    icon={
                        <NotificationIcon
                            size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                            colour={theme.textColour}
                        />
                    }
                />
                <MainSectionWithPadding>
                    <BodyRegular>{strings.notificationsPage.noNotificationsYet}</BodyRegular>
                </MainSectionWithPadding>
            </>
        );
    }

    return (
        <>
            <LearnerHeader
                title={strings.notificationsPage.title}
                icon={
                    <NotificationIcon
                        size={isMobileWidth ? IconSize.XLARGE : IconSize.XXLARGE}
                        colour={theme.textColour}
                    />
                }
            />
            <MainSectionWithPadding>
                {showMarkAllAsReadError && (
                    <ErrorNotifierContainer>
                        <ErrorNotifier
                            title={strings.notificationsPage.markAsReadError.title}
                            description={strings.notificationsPage.markAsReadError.description}
                        />
                    </ErrorNotifierContainer>
                )}

                <MarkAllAsReadRow>
                    {showMarkAllNotificationsAsRead && (
                        <SecondaryButton
                            title={strings.notificationsPage.markAsReadButton.title}
                            aria={strings.notificationsPage.markAsReadButton.aria}
                            onClick={async () => await markAllNotificationsAsRead()}
                            disabled={loadingNotifications || acknowledgeAllNotificationsLoading}
                        />
                    )}
                </MarkAllAsReadRow>

                <Notifications
                    notifications={notifications ?? []}
                    disableButtons={loadingNotifications}
                    navigate={navigate}
                />
            </MainSectionWithPadding>
        </>
    );
}

const MainSectionWithPadding = styled(MainSection)`
    padding-top: ${sizes.spacingMd};
`;

const MarkAllAsReadRow = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: ${sizes.spacingMd};
    min-height: 48px;
`;

const ErrorNotifierContainer = styled.div`
    display: flex;
    margin-top: ${sizes.spacingMd};
`;
