import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RoleType } from '../models/User';
import { AuthContext } from '../contextProviders/AuthContext';

export default function Redirect(): JSX.Element {
    const location = useLocation();
    const { isAuthenticated, userData, login } = React.useContext(AuthContext);

    if (!isAuthenticated) {
        login(location.pathname);
    }

    if (!userData.roles.length) return <Navigate to={'/initial-login'} replace />;

    if (userData.activeRole === RoleType.ADMIN) {
        return <Navigate to="/admin/dashboard" replace />;
    } else if (userData.activeRole === RoleType.TRAINER) {
        return <Navigate to="/trainer/dashboard" replace />;
    } else if (userData.activeRole === RoleType.ORGANISATION_ADMIN) {
        return <Navigate to="/organisation/dashboard" replace />;
    } else {
        return <Navigate to={`/learner/courses`} replace />;
    }
}
