import * as React from 'react';
import strings from '../../../../strings/strings.json';
import useNeveForm from 'components/forms/NeveForm';
import useUploadRequest from 'hooks/useUploadRequest';
import styled from 'styled-components';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline4, Headline5 } from 'components/Elements';
import { ErrorMessage, FormCard } from 'components/forms/FormComponents';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { Activity } from 'models/Activity';
import { FileDocument } from 'models/FileDocument';
import { createGlobalErrorFromAPIError, createFieldErrorFromAPIError } from 'shared/error-messages';
import { sizes } from 'theme';
import useFormBlocker from 'components/forms/useFormBlocker';
import { ResourcesInput } from '../ResourcesForm';
import { AudioCard } from '../FileCard';
import { AudioSelectionComponent } from '../FileSelectionComponent';
import { LearningResourcesFormModal } from '../LearningResourcesFormModal';
import ResourcesList from '../ResourcesList';
import { TaskInput, TaskUpdateDetailsSection } from './TaskUpdateDetailsSection';
import { arraysEqual } from 'lib/arraysEqual';
import GenerateContentModal, { GenerateModalState } from '../taskContentForms/GenerateContentModal';
import { CheckboxOption } from 'components/forms/Checkboxes';
import GeneratedContentSection from './GeneratedContentSection';
import SavedSuccessfullyNotification from './sharedUpdateComponents/SavedSuccessfullyNotification';
import { ButtonAndNotificationContainer } from './sharedUpdateComponents/ButtonAndNotificationContainer';
import DeleteActivityAlert from './sharedUpdateComponents/DeleteActivityAlert';
import DeleteActivityButton from './sharedUpdateComponents/DeleteActivityButton';

type TaskUpdateFormProps = {
    task: Activity;
    refetchActivity: () => Promise<void>;
    returnToCourseStructure: () => void;
};

export function TaskUpdateFormForAudio({
    task: taskProp,
    refetchActivity,
    returnToCourseStructure,
}: TaskUpdateFormProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const [task, setTask] = React.useState<Activity>(taskProp);
    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [audioRequired, setAudioRequired] = React.useState<boolean>(false);
    const [resources, setResources] = React.useState<ResourcesInput[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [saved, setSaved] = React.useState<boolean>(false);
    const [alertOpen, setAlertOpen] = React.useState(false);

    const [localAudioToBeUploaded, setLocalAudioToBeUploaded] = React.useState<File | null | undefined>(null);
    const [providerAudioToBeUploaded, setProviderAudioToBeUploaded] = React.useState<FileDocument>();
    const [audioCardComponentData, setAudioCardComponentData] = React.useState<FileCardComponentData | null>(
        taskProp.audioDocument
            ? { fileName: taskProp.audioDocument.filename, fileDate: taskProp.audioDocument.dateUpdated }
            : null,
    );

    const [generateModalState, setGenerateModalState] = React.useState<GenerateModalState>('hide');
    const [addText, setAddText] = React.useState<boolean>(false);

    const selectAudioButtonRef = React.useRef<HTMLButtonElement>(null);
    const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        if (taskProp) {
            setTask(taskProp);
            setResources(taskProp.resources);
            setGlobalErrorMessage('');
            setAudioRequired(false);
            setLocalAudioToBeUploaded(undefined);
            setProviderAudioToBeUploaded(undefined);
        }
    }, [taskProp]);

    React.useEffect(() => {
        let data: FileCardComponentData | null;

        if (localAudioToBeUploaded) {
            data = {
                fileName: localAudioToBeUploaded.name,
                fileDate: new Date(),
            };
        } else if (providerAudioToBeUploaded) {
            data = {
                fileName: providerAudioToBeUploaded.filename,
                fileDate: providerAudioToBeUploaded.dateUpdated,
            };
        } else if (task.audioDocument) {
            data = {
                fileName: task.audioDocument.filename,
                fileDate: task.audioDocument.dateUpdated,
            };
        } else {
            data = null;
        }

        if (localAudioToBeUploaded || providerAudioToBeUploaded) {
            setSaved(false);
        }

        setAudioCardComponentData(data);
    }, [task, localAudioToBeUploaded, providerAudioToBeUploaded]);

    React.useEffect(() => {
        setAudioRequired(!audioCardComponentData);
    }, [audioCardComponentData]);

    const { modifyData: updateTask, loading: updatingTask } = useModifyRequest(
        `activities/task/audio/${task.id}`,
        'PUT',
    );

    const { modifyData: generateText, loading: generatingText } = useModifyRequest(
        `activities/task/${task.id}/generate/text`,
        'POST',
    );

    React.useEffect(() => {
        if (generatingText) {
            setGenerateModalState('submitting');
        }
    }, [generatingText]);

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset,
    } = useNeveForm<TaskInput>({
        title: task?.title ?? ' ',
        description: task?.description ?? '',
        durationInMins: task?.durationInMins ?? null,
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(false);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const title = watch('title');
    const description = watch('description');
    const durationInMins = watch('durationInMins');

    React.useEffect(() => {
        const titleChanged = title !== taskProp.title;
        const descriptionChanged = description !== taskProp.description;
        const durationInMinsChanged = durationInMins != taskProp.durationInMins; // eslint-disable-line eqeqeq
        const audioChanged = audioCardComponentData?.fileName !== taskProp.audioDocument?.filename;
        const resourcesChanged = resources != undefined && !arraysEqual(resources, taskProp.resources); // eslint-disable-line eqeqeq

        const changed =
            titleChanged || descriptionChanged || durationInMinsChanged || resourcesChanged || audioChanged;

        setFormDirty(changed);
    }, [title, description, durationInMins, audioCardComponentData, resources]); // eslint-disable-line react-hooks/exhaustive-deps

    const { upload, uploading } = useUploadRequest();

    async function addResource(newResource: ResourcesInput) {
        setSaved(false);
        setResources([...(resources ?? []), newResource]);
        addLinkButtonRef.current?.focus();
    }

    function deleteResource(id: string | null, title: string, url: string, deleted: boolean): void {
        setSaved(false);

        if (id) {
            setResources(
                (resources ?? []).map((resource) => {
                    if (resource.id === id && deleted) {
                        return { title: resource.title, id: resource.id, url: resource.url, deleted };
                    } else {
                        return resource;
                    }
                }),
            );
        } else {
            setResources((resources ?? []).filter((resource) => !(resource.title === title && resource.url === url)));
        }

        if (saved) {
            setSaved(false);
        }

        addLinkButtonRef.current?.focus();
    }

    type SubmitFile = {
        filePath: string | null;
        fileName: string | null;
        id: string | null;
    };

    async function uploadAudio(): Promise<SubmitFile | null> {
        if (localAudioToBeUploaded) {
            const uploadResponse = await upload(localAudioToBeUploaded);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                throw new Error('Audio upload failed');
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                throw new Error('Audio upload failed');
            }

            return {
                filePath: uploadResponse.value.s3Key,
                fileName: uploadResponse.value.filename,
                id: null,
            };
        } else if (providerAudioToBeUploaded) {
            return {
                filePath: null,
                fileName: null,
                id: providerAudioToBeUploaded.id,
            };
        } else if (task.audioDocument) {
            return {
                filePath: null,
                fileName: null,
                id: task.audioDocument.id,
            };
        } else {
            return null;
        }
    }

    async function saveTask(formData: TaskInput) {
        setSaved(false);

        if (!audioCardComponentData) {
            selectAudioButtonRef.current?.focus();
            return;
        }

        let audioData: SubmitFile | null = null;
        try {
            audioData = await uploadAudio();
        } catch (error: any) {
            logError(error);
            return;
        }

        const audioHasChanged =
            task.audioDocument?.filename !== audioCardComponentData.fileName ||
            task.audioDocument?.dateUpdated !== audioCardComponentData.fileDate;

        const response = await updateTask<any, Activity>({
            title: formData.title,
            description: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
            resources,
            audio: audioData,
            content: audioHasChanged ? '' : task.content, //audio has changed so remove old transcript
        });
        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        reset(formData);
        await refetchActivity();
        await context.refetchCourse();
        setSaved(true);

        if (audioHasChanged) {
            setGenerateModalState('show');
        }
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof TaskInput, createFieldErrorFromAPIError(field, code));
        });
    }

    const removeAudio = () => {
        setSaved(false);
        setLocalAudioToBeUploaded(undefined);
        setProviderAudioToBeUploaded(undefined);
        setAudioCardComponentData(null);
        setTask({ ...task, audioDocument: null });
        selectAudioButtonRef.current?.focus();
    };

    type FileCardComponentData = {
        fileName: string;
        fileDate: Date;
    };

    const generateContentOptions: CheckboxOption[] = [
        {
            name: strings.generateContentModal.options.textToAudio,
            value: addText.toString(),
            inputProps: { onChange: () => setAddText(!addText) },
        },
    ];

    const generateContent = async () => {
        if (!addText) {
            setGenerateModalState('hide');
        } else {
            const response = await generateText({});
            setGenerateModalState(response.value === true ? 'success' : 'error');
        }
    };

    const closeGenerateContentModal = async () => {
        if (generateModalState === 'success') {
            await context.refetchCourse();
            await refetchActivity();
        }
        setGenerateModalState('hide');
    };

    return (
        <>
            {generateModalState !== 'hide' && (
                <GenerateContentModal
                    state={generateModalState}
                    close={closeGenerateContentModal}
                    generateContent={generateContent}
                    contentOptions={generateContentOptions}
                />
            )}
            <FormCardWithGaps onSubmit={handleSubmit(saveTask)} id="activityForm">
                <Headline4>{strings.activityForm.headlineTextEditAudio}</Headline4>
                <TaskUpdateDetailsSection
                    register={register}
                    watch={watch}
                    errors={errors}
                    onChange={() => saved && setSaved(false)}
                />
                <ResourceGroup>
                    <Headline5>{strings.learningMaterialsForm.addAudioTitle}</Headline5>
                    {audioCardComponentData && (
                        <AudioCard
                            fileName={audioCardComponentData.fileName}
                            uploadDate={audioCardComponentData.fileDate}
                            removeFile={removeAudio}
                            disabled={uploading || updatingTask}
                        />
                    )}
                    {!audioCardComponentData && (
                        <AudioSelectionComponent
                            setFileFromLocal={(newFile) => setLocalAudioToBeUploaded(newFile)}
                            setFileFromProvider={setProviderAudioToBeUploaded}
                            setFileFromLocalMetaData={() => {}}
                            disableButton={uploading || updatingTask}
                            selectFileButtonRef={selectAudioButtonRef}
                        />
                    )}
                    {audioRequired && <ErrorMessage>{strings.activityUpdatePage.audioRequired}</ErrorMessage>}
                </ResourceGroup>
                <GeneratedContentSection task={task} setTask={setTask} />
                <ResourceGroup>
                    <Headline5>{strings.resourcesList.title}</Headline5>
                    <ResourcesList
                        resources={resources ?? []}
                        toggleModalOpen={() => {
                            setModalOpen(!modalOpen);
                            return saved && setSaved(false);
                        }}
                        deleteResource={deleteResource}
                        disabled={uploading || updatingTask}
                        addLinkButtonRef={addLinkButtonRef}
                    />
                </ResourceGroup>
                <ButtonAndNotificationContainer>
                    {saved && !formDirty && <SavedSuccessfullyNotification />}
                    <DeleteActivityButton setAlertOpen={setAlertOpen} />
                    <PrimaryButton
                        type="submit"
                        title={!uploading ? strings.activityForm.submitButton : strings.activityForm.uploadingFile}
                        aria={strings.activityForm.submitButtonAria}
                        disabled={uploading || updatingTask}
                        submitting={uploading || updatingTask}
                    />
                </ButtonAndNotificationContainer>
                {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
            </FormCardWithGaps>
            {alertOpen && (
                <DeleteActivityAlert
                    taskId={task.id}
                    returnToCourseStructure={returnToCourseStructure}
                    setAlertOpen={setAlertOpen}
                />
            )}
            {modalOpen && (
                <LearningResourcesFormModal closeModal={() => setModalOpen(false)} addResource={addResource} />
            )}
        </>
    );
}

const ResourceGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const FormCardWithGaps = styled(FormCard)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
`;
