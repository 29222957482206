import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import { breakpoints, theme } from '../../theme';
import strings from '../../strings/strings.json';
import { RoleType } from '../../models/User';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useSpring } from '@react-spring/web';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import ContactSupportCard from 'components/contact/ContactSupportCard';
import SupportIcon from 'assets/icons/navigation/SupportIcon';
import LogoutIcon from 'assets/icons/navigation/LogoutIcon';
import SecondaryButton from 'components/buttons/SecondaryButton';
import {
    ContactSupportOverlay,
    ContactSupportPanel,
    LimitedContainer,
    MenuItemButton,
    MenuItemIcon,
    MenuItemText,
    NavbarLink,
    NavigationButtonsSection,
    NavigationContainer,
    NavigationLogoSection,
    NavigationPadding,
    StyledNeveLogo,
} from './Navigation';

type MenuItem = {
    id: string;
    label: string | JSX.Element;
    buttonAria: string;
    address: string;
    roles: RoleType[];
    onClick: () => void;
    components: { active: React.ComponentType; inactive: React.ComponentType };
};

export default function SupportOnlyNavigation(): JSX.Element {
    const navigate = useNavigate();
    const { currentWindowWidth } = useWindowWidth();

    const [panelIsOpen, setPanelIsOpen] = React.useState<boolean>(false);
    const panelsRef = React.useRef<HTMLDivElement>(null);

    const { translateX } = useSpring({
        translateX: panelIsOpen ? `0` : '500px',
    });

    const keyboardTrapIsDisabled = !panelIsOpen;

    const { ref } = useKeyboardTrap(() => {
        setPanelIsOpen(false);
    }, keyboardTrapIsDisabled);

    const menuItems: MenuItem[] = [
        {
            id: 'SupportMenuItem',
            label: strings.appHeader.support.label,
            buttonAria: strings.appHeader.support.aria,
            components: {
                active: () => <SupportIcon />,
                inactive: () => <SupportIcon colour={theme.navigationBar.buttonText} />,
            },
            roles: [],
            address: '/support',
            onClick: () => {
                setPanelIsOpen(!panelIsOpen);
            },
        },
    ];

    return (
        <>
            <NavigationContainer>
                <LimitedContainer>
                    <NavigationLogoSection>
                        <StyledNeveLogo
                            alt={strings.appHeader.neveLearningLogoAltText}
                            aria-label={strings.appHeader.neveLearningLogoAria}
                        />
                    </NavigationLogoSection>

                    <NavigationButtonsSection>
                        {menuItems.map((currentMenuItem, index) => {
                            const isActive = panelIsOpen;
                            const MenuItemComponent = isActive
                                ? currentMenuItem.components.active
                                : currentMenuItem.components.inactive;
                            return (
                                <NavbarLink onClick={currentMenuItem.onClick} key={index}>
                                    <MenuItemButton
                                        id={currentMenuItem.id}
                                        role="img"
                                        aria-label={currentMenuItem.buttonAria}
                                        key={index}
                                    >
                                        <MenuItemIcon>
                                            <MenuItemComponent />
                                        </MenuItemIcon>
                                        <MenuItemText $active={isActive}>{currentMenuItem.label}</MenuItemText>
                                    </MenuItemButton>
                                </NavbarLink>
                            );
                        })}

                        {panelIsOpen && <ContactSupportOverlay></ContactSupportOverlay>}
                        <ContactSupportPanel ref={panelsRef && ref} style={{ translateX, overflow: 'hidden' }}>
                            <ContactSupportCard closePanel={() => setPanelIsOpen(false)} panelIsOpen={panelIsOpen} />
                        </ContactSupportPanel>

                        <SecondaryButton
                            title="Logout"
                            aria="logout"
                            onClick={() => {
                                navigate('/logout');
                            }}
                            icon={currentWindowWidth > parseInt(breakpoints.sm) ? <LogoutIcon /> : undefined}
                        />
                    </NavigationButtonsSection>
                </LimitedContainer>
            </NavigationContainer>
            <NavigationPadding />
        </>
    );
}
