import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FONTSIZE, sizes, theme, breakpoints, MOBILEFONTSIZE } from '../theme';

// ------------ Button Elements ------------

export const TertiaryButton = styled.button`
    display: block;
    cursor: pointer;
    font-size: ${FONTSIZE.BodyRegular};
    font-family: ${theme.fontFamilyMain};
    text-decoration: underline;
    letter-spacing: 0;
    border: none;
    background-color: transparent;
    color: ${theme.textColour};
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
        border-radius: 4px;
    }
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
        border: 1px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
    }
`;

export const ExternalSecondaryLinkButton = styled.a`
    font-size: ${FONTSIZE.BodyRegular};
    text-decoration: underline;
    letter-spacing: 0;
    font-weight: bold;
    color: ${theme.linkColour};
    &:hover {
        background-color: ${theme.linkHoverBackgroundColour};
        border-radius: 4px;
    }
    &:visited {
        color: ${theme.linkVisitedColour};
    }
    &:focus {
        background-color: ${theme.linkFocusBackgroundColour};
        border: 1px solid ${theme.linkFocusBorderColour};
        outline: ${theme.linkFocusBorderColour};
        border-radius: 4px;
    }
`;

export const NavigationLink = styled(Link)<{ inverted?: boolean }>`
    color: ${({ inverted }) => {
        return inverted ? theme.secondaryButtonTextColour : theme.textColour;
    }};
    font-size: ${FONTSIZE.BodyRegular};
    text-decoration: underline;
    font-weight: bold;
    &:focus {
        background-color: ${theme.linkFocusBackgroundColour};
        border: 1px solid ${theme.linkFocusBorderColour};
        outline: none;
        border-radius: ${sizes.borderRadiusMd};
    }
    &:hover {
        background-color: ${theme.linkHoverBackgroundColour};
        border-radius: ${sizes.borderRadiusMd};
    }
`;

export const IconLinkButtonText = styled.div<{ inverted?: boolean }>`
    font-size: ${FONTSIZE.BodyRegular};
    text-decoration: underline;
    font-weight: bold;
    white-space: nowrap;
    color: ${({ inverted }) => {
        return inverted ? theme.textColourInverted : theme.linkColour;
    }};
`;

export const ButtonRowLeft = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

export const ButtonRowRight = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${sizes.spacingMd};
    width: 100%;
`;

// ------------ Text Elements ------------

const HeadlineBase = styled.h1<{ $inverted?: boolean }>`
    font-weight: 600;
    font-family: ${theme.fontFamilyHeader};
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
    overflow: hidden;

    color: ${({ $inverted }) => {
        return $inverted ? theme.textColourInverted : theme.textColour;
    }};
`;

export const Headline1 = styled(HeadlineBase).attrs({ as: 'h1' })<{ $inverted?: boolean }>`
    font-size: ${FONTSIZE.Headline1};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${MOBILEFONTSIZE.Headline1};
    }
`;

export const Headline2 = styled(HeadlineBase).attrs({ as: 'h2' })<{ $inverted?: boolean }>`
    font-size: ${FONTSIZE.Headline2};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${MOBILEFONTSIZE.Headline2};
    }
`;

export const Headline3 = styled(HeadlineBase).attrs({ as: 'h3' })<{ $inverted?: boolean }>`
    font-size: ${FONTSIZE.Headline3};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${MOBILEFONTSIZE.Headline3};
    }
`;

export const Headline4 = styled(HeadlineBase).attrs({ as: 'h4' })<{ $inverted?: boolean }>`
    font-size: ${FONTSIZE.Headline4};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${MOBILEFONTSIZE.Headline4};
    }
`;

export const Headline5 = styled(HeadlineBase).attrs({ as: 'h5' })<{ $inverted?: boolean }>`
    font-size: ${FONTSIZE.Headline5};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${MOBILEFONTSIZE.Headline5};
    }
`;

export const Headline6 = styled(HeadlineBase).attrs({ as: 'h6' })<{ $inverted?: boolean }>`
    font-size: ${FONTSIZE.Headline6};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${MOBILEFONTSIZE.Headline6};
    }
`;

const BodyBase = styled.p<{ $inverted?: boolean; $bold?: boolean }>`
    font-family: ${theme.fontFamilyMain};
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    font-weight: ${({ $bold }) => ($bold ? 600 : 'normal')};
    color: ${({ $inverted }) => {
        return $inverted ? theme.textColourInverted : theme.textColour;
    }};
`;

export const BodyLarge = styled(BodyBase)<{ $inverted?: boolean; $bold?: boolean }>`
    font-size: ${FONTSIZE.BodyLarge};
`;

export const BodyRegular = styled(BodyBase)<{ $inverted?: boolean; $bold?: boolean }>`
    font-size: ${FONTSIZE.BodyRegular};
`;

export const BodySmall = styled(BodyBase)<{ $inverted?: boolean; $bold?: boolean }>`
    font-size: ${FONTSIZE.BodySmall};
`;

export const WrappingText = styled(BodyRegular)`
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: anywhere;
`;

export const BulletList = styled.ul`
    list-style-position: inside;
`;

// ------------ ------------

export const Card = styled.div`
    box-sizing: border-box;
    border: 1px solid ${theme.cardBorder};
    border-radius: ${sizes.spacingSm};
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: ${theme.cardBackground};
`;

// --- MODALS ---
export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
`;

export const ModalBody = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ModalCard = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: ${sizes.spacingMd};
    padding-bottom: ${sizes.spacingLg};
    border: solid 1px ${theme.cardBorder};
    background-color: ${theme.cardBackground};
    border-radius: 8px;

    @media (max-width: ${breakpoints.sm}) {
        padding-top: 130px;
        padding-bottom: 4.5rem;
        height: 100%;
        border: none;
        border-radius: initial;
        justify-content: space-between;
    }
`;

// ------------ CSS Utilities ------------

export const Flex = styled.div`
    display: flex;
`;

export const FlexAlignCenter = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

// ---- Link to wrap logos

export const LinkWrapper = styled.a`
    margin: 0;
    padding: 0;
    height: auto;
    width: auto;
`;
