import Notification, { NotificationType } from 'components/notifiers/Notification';

type SuccessNotifierProps = {
    title: string;
    description: string;
    maxWidth?: string;
};

export default function SuccessNotifier({ title, description, maxWidth }: SuccessNotifierProps): JSX.Element {
    return (
        <Notification
            notificationType={NotificationType.SUCCESS}
            title={title}
            description={description}
            maxWidth={maxWidth}
        />
    );
}
