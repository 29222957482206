import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import { breakpoints, sizes, theme } from '../../../theme';
import { FlexAlignCenter, Headline3, WrappingText } from '../../../components/Elements';
import useGetRequest from '../../../hooks/useGetRequest';
import { AuthContext } from '../../../contextProviders/AuthContext';
import { FileDocument, FileDocumentType } from '../../../models/FileDocument';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import Table, { RightAlignedButtonCell, TableRow } from '../../../components/table/Table';
import { EmptyTableContent } from '../../../components/table/EmptyTableContent';
import useWindowWidth from '../../../hooks/useWindowWidth';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import Alert from 'components/alert/Alert';
import useDeleteRequest from 'hooks/useDeleteRequest';
import { logError } from 'lib/debug-helpers';
import MainSection from 'components/sections/MainSection';
import BinIcon from 'assets/icons/controls/BinIcon';
import HeaderWithCount from '../components/HeaderWithCount';
import UploadIcon from '../../../assets/icons/controls/UploadIcon';
import { IconSize } from '../../../assets/icons/icon-sizes';
import { useLocation, useNavigate } from 'react-router-dom';
import ValidateIcon from '../../../assets/icons/indicators/ValidateIcon';
import { DOCUMENTS_URL } from '../../../lib/_api-helpers';
import ImageIcon from 'assets/icons/pathway/ImageIcon';
import VideoIcon from 'assets/icons/pathway/VideoIcon';
import FileIcon from 'assets/icons/other/FileIcon';
import ExternalLinkIcon from '../../../assets/icons/navigation/ExternalLinkIcon';
import AudioIcon from 'assets/icons/pathway/AudioIcon';

type State = {
    newFileId: string;
};

export default function FileListPage(): JSX.Element {
    const navigate = useNavigate();
    const { userData } = useContext(AuthContext);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [selectedFileId, setSelectedFileId] = React.useState<string>('');
    const [removeFileError, setRemoveFileError] = React.useState<boolean>(false);
    const { currentWindowWidth } = useWindowWidth();

    const { state } = useLocation();
    const { newFileId } = (state as State) || null || {};

    const scrollRef = React.createRef<any>();

    React.useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    });

    const {
        data: files,
        loading,
        errors,
        refetchData,
    } = useGetRequest<FileDocument[]>(
        `organisations/${userData?.organisation?.id}/documents`,
        !userData?.organisation?.id,
    );

    const organisationId = userData?.organisation?.id;
    const { deleteData, deleteInProgress } = useDeleteRequest(
        `organisations/${organisationId}/documents/${selectedFileId}`,
    );

    if (!files || loading) return <></>;

    if (errors && errors.length) {
        return (
            <ManageFilesContentSection>
                <Headline3>{strings.OAFileListPage.heading}</Headline3>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OAFileListPage.getFilesError.title}
                    description={strings.OAFileListPage.getFilesError.description}
                />
            </ManageFilesContentSection>
        );
    }

    const toggleAlert = () => {
        setAlertOpen(!alertOpen);
    };

    const onDelete = async () => {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            setRemoveFileError(true);
            return;
        }

        await refetchData();
        setSelectedFileId('');
        toggleAlert();
    };

    const fileCount = files.length + strings.OAFileListPage.fileCountText;

    const fileRows: TableRow[] = (files ?? [])
        .sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime())
        .map((file) => {
            return {
                id: file.id,
                cells: [
                    {
                        name: strings.OAFileListPage.fileNameHeading,
                        value: (
                            <FileContainer ref={newFileId === file.id ? scrollRef : null}>
                                <IconContainer>
                                    {file.id === newFileId && <ValidateIcon />}
                                    {file.id !== newFileId && file.documentType === FileDocumentType.VIDEO && (
                                        <VideoIcon />
                                    )}
                                    {file.id !== newFileId && file.documentType === FileDocumentType.IMAGE && (
                                        <ImageIcon />
                                    )}
                                    {file.id !== newFileId && file.documentType === FileDocumentType.AUDIO && (
                                        <AudioIcon />
                                    )}
                                </IconContainer>
                                <WrappingText $bold>{file.filename}</WrappingText>
                            </FileContainer>
                        ),
                    },
                    {
                        name: strings.OAFileListPage.fileUploadedDateHeader,
                        value:
                            currentWindowWidth < parseInt(breakpoints.lg)
                                ? null
                                : new Date(file.dateUpdated).toLocaleDateString(),
                    },
                    {
                        name: strings.OAFileListPage.fileUploadedByHeader,
                        value:
                            currentWindowWidth < parseInt(breakpoints.lg) ? null : (
                                <div>
                                    <WrappingText $bold>
                                        {file.user?.firstName} {file.user?.lastName}
                                    </WrappingText>
                                    <WrappingText>{file.user?.email ?? ''}</WrappingText>
                                </div>
                            ),
                    },
                    {
                        name: '',
                        value: (
                            <ButtonsContainer>
                                <RightAlignedButtonCell>
                                    <SecondaryButton
                                        title={
                                            currentWindowWidth < parseInt(breakpoints.lg)
                                                ? ''
                                                : strings.OAFileListPage.previewFileButton
                                        }
                                        aria={strings.OAFileListPage.previewFileButtonAria}
                                        onClick={() => {
                                            if (file.documentType === FileDocumentType.IMAGE) {
                                                let imageWindow = window.open('', '_blank');
                                                imageWindow?.document.write(`
                                                  <html lang='en'>
                                                    <head>
                                                    <title>Image from Neve Learning</title></head>
                                                    <body>
                                                      <img src='${DOCUMENTS_URL}/${file.s3Filepath}' alt='image from neve learning' height='100%' >
                                                    </body>
                                                  </html>
                                            `);

                                                return;
                                            }

                                            if (file.documentType === FileDocumentType.VIDEO) {
                                                let videoWindow = window.open('', '_blank');
                                                videoWindow?.document.write(`
                                                  <html lang='en'>
                                                    <head>
                                                    <title>Image from Neve Learning</title></head>
                                                    <body>
                                                      <video src='${DOCUMENTS_URL}/${file.s3Filepath}' controls width='100%' />
                                                    </body>
                                                  </html>
                                            `);

                                                return;
                                            }

                                            if (file.documentType === FileDocumentType.AUDIO) {
                                                let videoWindow = window.open('', '_blank');
                                                videoWindow?.document.write(`
                                                  <html lang='en'>
                                                    <head>
                                                    <title>Image from Neve Learning</title></head>
                                                    <body>
                                                      <audio src='${DOCUMENTS_URL}/${file.s3Filepath}' controls width='100%' />
                                                    </body>
                                                  </html>
                                            `);

                                                return;
                                            }

                                            window.open(`${DOCUMENTS_URL}/${file.s3Filepath}`, '_blank');
                                        }}
                                        icon={<ExternalLinkIcon size={IconSize.MEDIUM} />}
                                        compact
                                    />
                                </RightAlignedButtonCell>
                                <RightAlignedButtonCell>
                                    <SecondaryButton
                                        title={
                                            currentWindowWidth < parseInt(breakpoints.lg)
                                                ? ''
                                                : strings.OAFileListPage.removeFileButton
                                        }
                                        aria={strings.OAFileListPage.removeFileButtonAria}
                                        onClick={() => {
                                            setSelectedFileId(file.id);
                                            toggleAlert();
                                        }}
                                        icon={<BinIcon />}
                                        alternateColour={SecondaryButtonColour.ALTERNATE}
                                        disabled={deleteInProgress}
                                        compact
                                    />
                                </RightAlignedButtonCell>
                            </ButtonsContainer>
                        ),
                    },
                ],
            };
        });

    return (
        <>
            <ManageFilesContentSection>
                <TitleAndButtonContainer>
                    <Headline3>{strings.OAFileListPage.heading}</Headline3>
                    <HeaderWithCount
                        countText={fileCount}
                        actionButton={
                            <SecondaryButton
                                title={strings.OAFileListPage.uploadButton}
                                aria={strings.OAFileListPage.uploadButtonAria}
                                icon={<UploadIcon size={IconSize.SMALL} />}
                                onClick={() => {
                                    navigate(`/organisation/files/upload`);
                                }}
                            />
                        }
                    />
                </TitleAndButtonContainer>
                <div>
                    <Table
                        headers={[
                            { name: strings.OAFileListPage.fileNameHeading, order: 1, rowPercentage: 30 },
                            {
                                name: strings.OAFileListPage.fileUploadedDateHeader,
                                order: 2,
                                rowPercentage: 15,
                            },
                            { name: strings.OAFileListPage.fileUploadedByHeader, order: 3, rowPercentage: 20 },
                            { name: '', order: 4, rowPercentage: 35, mobileRightColumn: true },
                        ]}
                        rows={fileRows}
                        activeRow={newFileId}
                        mobileViewBelow={breakpoints.lg}
                        emptyTableContent={
                            <EmptyTableContent
                                icon={<FileIcon size={IconSize.XLARGE} colour={theme.textColour} />}
                                title={strings.OAFileListPage.emptyFileTable.title}
                                description={strings.OAFileListPage.emptyFileTable.description}
                                buttonTitle={strings.OAFileListPage.emptyFileTable.buttonTitle}
                                buttonAria={strings.OAFileListPage.emptyFileTable.buttonAria}
                                buttonOnClick={() => {
                                    navigate(`/organisation/files/upload`);
                                }}
                                buttonIcon={
                                    <UploadIcon size={IconSize.SMALL} colour={theme.primaryButtonTextColour} />
                                }
                            />
                        }
                    />
                </div>
            </ManageFilesContentSection>
            {alertOpen && (
                <Alert
                    alertText={strings.OAFileListPage.deleteConfirmationAlert.fileNotPartOfCourse}
                    buttonText={strings.OAFileListPage.deleteConfirmationAlert.deleteButtonText}
                    ariaButtonText={strings.OAFileListPage.deleteConfirmationAlert.deleteButtonTextAria}
                    closeAlert={() => {
                        setAlertOpen(false);
                        setSelectedFileId('');
                    }}
                    onSubmit={onDelete}
                    error={
                        removeFileError
                            ? {
                                  title: strings.OAFileListPage.removeError.title,
                                  description: strings.OAFileListPage.removeError.description,
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}

const ManageFilesContentSection = styled(MainSection)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    padding: ${sizes.spacingSm} 0;
    min-height: 120px;

    @media (max-width: ${breakpoints.sm}) {
        padding: unset;
    }
`;

const TitleAndButtonContainer = styled(FlexAlignCenter)`
    justify-content: space-between;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${sizes.spacingMd};
`;
