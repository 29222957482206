import { BodyRegular, Headline5, WrappingText } from 'components/Elements';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import React, { ReactNode, useContext } from 'react';
import strings from '../../../strings/strings.json';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import { CustomBackgroundColourContext } from 'contextProviders/CustomBackgroundColourContext';
import SubpageHeader, { BackButtonDetails } from 'components/sections/SubpageHeader';
import Table, { TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import SecondaryButton, { SecondaryButtonColour } from '../../../components/buttons/SecondaryButton';
import BinIcon from 'assets/icons/controls/BinIcon';
import Alert from '../../../components/alert/Alert';
import useDeleteRequest from '../../../hooks/useDeleteRequest';
import { logError } from '../../../lib/debug-helpers';
import { IconSize } from 'assets/icons/icon-sizes';
import ScheduleIcon from 'assets/icons/pathway/ScheduleIcon';
import { RoleType, UserModel } from 'models/User';

export function TrainerDetailsPage(): JSX.Element {
    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;
    const { userData } = useContext(AuthContext);
    const { setBackgroundColour } = useContext(CustomBackgroundColourContext);

    setBackgroundColour(theme.organisationTrainerBackgroundColour);

    const [removeTrainerWarningVisible, setRemoveTrainerWarningVisible] = React.useState<boolean>(false);
    const [removeTrainerError, setRemoveTrainerError] = React.useState<boolean>(false);

    const {
        data: trainer,
        loading,
        errors,
    } = useGetRequest<UserModel>(
        `organisations/${userData.organisation?.id}/trainers/${id}`,
        !userData.organisation?.id,
    );

    const { deleteData } = useDeleteRequest(`organisations/${userData.organisation?.id}/trainers/${id}`);

    async function RemoveTrainer() {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            setRemoveTrainerError(true);
            return;
        }

        navigate('/organisation/dashboard', { state: { activeTabId: 'trainers' } });
        return;
    }

    if (!trainer || loading) return <></>;

    if (errors && errors.length > 0) {
        return (
            <TrainerDetailsSection>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OATrainerDetailsPage.getTrainerError}
                />
            </TrainerDetailsSection>
        );
    }

    const trainerName = `${trainer?.firstName} ${trainer?.lastName}`;

    const courseScheduleRows: TableRow[] = (trainer?.trainerCourseSchedules ?? []).map((scheduledCourse) => {
        const shortDate = new Date(scheduledCourse.startDate).toLocaleDateString();

        return {
            id: scheduledCourse.id,
            cells: [
                {
                    name: strings.OATrainerDetailsPage.scheduledCourses.course.title,
                    value: scheduledCourse.course.title,
                    isBold: true,
                },
                {
                    name: strings.OATrainerDetailsPage.scheduledCourses.course.startDate,
                    value: shortDate,
                },
            ],
        };
    });

    return (
        <>
            <TrainerDetailsSection>
                <InformationCard>
                    <HeadingRow>
                        <RowTitle>
                            <Headline5>{strings.OATrainerDetailsPage.informationCard.information}</Headline5>
                        </RowTitle>
                    </HeadingRow>
                    <Row>
                        <RowTitle>
                            <BodyRegular $bold>{strings.OATrainerDetailsPage.informationCard.name}</BodyRegular>
                        </RowTitle>

                        <WrappingText>{trainerName}</WrappingText>
                    </Row>
                    <Row>
                        <RowTitle>
                            <BodyRegular $bold>
                                {strings.OATrainerDetailsPage.informationCard.emailAddress}
                            </BodyRegular>
                        </RowTitle>

                        <WrappingText>{trainer?.email}</WrappingText>
                    </Row>
                </InformationCard>
                <div>
                    <HeadingRow>
                        <RowTitle>
                            <Headline5>{strings.OATrainerDetailsPage.scheduledCourses.heading}</Headline5>
                        </RowTitle>
                    </HeadingRow>
                    <Table
                        headers={[
                            {
                                name: strings.OATrainerDetailsPage.scheduledCourses.course.title,
                                order: 1,
                                rowPercentage: 50,
                            },
                            {
                                name: strings.OATrainerDetailsPage.scheduledCourses.course.startDate,
                                order: 2,
                                rowPercentage: 50,
                            },
                        ]}
                        rows={courseScheduleRows}
                        emptyTableContent={
                            <EmptyTableContent
                                icon={<ScheduleIcon size={IconSize.XLARGE} colour={theme.textColour} />}
                                title={strings.OATrainerDetailsPage.emptyTable.title}
                                description={strings.OATrainerDetailsPage.emptyTable.description}
                            />
                        }
                    />
                </div>
                {courseScheduleRows.length > 0 &&
                    !trainer.roles.some((role) => role.name === RoleType.ORGANISATION_ADMIN) && (
                        <>
                            <Notification
                                notificationType={NotificationType.WARNING}
                                title={strings.OATrainerDetailsPage.removeTrainerWarningTitle}
                                description={strings.OATrainerDetailsPage.removeTrainerWarningDetail}
                            />
                        </>
                    )}
                {!trainer.roles.some((role) => role.name === RoleType.ORGANISATION_ADMIN) && (
                    <div>
                        <SecondaryButton
                            title={strings.OATrainerDetailsPage.removeButton.title}
                            aria={strings.OATrainerDetailsPage.removeButton.aria}
                            icon={<BinIcon />}
                            onClick={() => setRemoveTrainerWarningVisible(true)}
                            alternateColour={SecondaryButtonColour.ALTERNATE}
                        />
                    </div>
                )}
            </TrainerDetailsSection>
            {removeTrainerWarningVisible && (
                <Alert
                    buttonText={strings.OATrainerDetailsPage.removeAlert.buttonText}
                    alertText={strings.OATrainerDetailsPage.removeAlert.alertText}
                    closeAlert={() => setRemoveTrainerWarningVisible(false)}
                    ariaButtonText={strings.OATrainerDetailsPage.removeAlert.buttonAria}
                    onSubmit={RemoveTrainer}
                    error={
                        removeTrainerError
                            ? {
                                  title: strings.OATrainerDetailsPage.removeError.title,
                                  description: strings.OATrainerDetailsPage.removeError.description,
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}

type TrainerDetailsSectionProps = {
    children: ReactNode;
};

const TrainerDetailsSection = ({ children }: TrainerDetailsSectionProps): JSX.Element => {
    const backButtonDetails: BackButtonDetails = {
        title: strings.OATrainerDetailsPage.backButton.title,
        aria: strings.OATrainerDetailsPage.backButton.aria,
        href: '/organisation/dashboard',
        navigateState: { state: { activeTabId: 'trainers' } },
    };

    return (
        <>
            <SubpageHeader
                title={strings.OATrainerDetailsPage.heading}
                shadedBackground
                backButtonDetails={backButtonDetails}
            />
            <MainSectionWithGaps>{children}</MainSectionWithGaps>
        </>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px;
    min-height: 65px;
    border-bottom: 1px solid ${theme.cardBorder};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        justify-content: center;
        min-height: 80px;
        align-items: unset;
        gap: 8px;
    }
`;

const HeadingRow = styled(Row)`
    padding-bottom: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.sm}) {
        min-height: 40px;
    }
`;

const RowTitle = styled.div`
    min-width: 120px;

    @media (max-width: ${breakpoints.sm}) {
        min-width: unset;
    }
`;

const InformationCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    background: ${theme.cardBackground};

    @media (max-width: ${breakpoints.sm}) {
        width: unset;
    }
`;
