import React, { useContext } from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import { breakpoints, sizes, theme } from 'theme';
import Table, { RightAlignedButtonCell, TableHeader, TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import { FlexAlignCenter, Headline3, WrappingText } from 'components/Elements';
import useWindowWidth from 'hooks/useWindowWidth';
import { CourseScheduleOverview } from 'models/CourseScheduleOverview';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import ValidateIcon from 'assets/icons/indicators/ValidateIcon';
import NavigateWithArrow from 'components/actions/NavigateWithArrow';
import { IconSize } from 'assets/icons/icon-sizes';
import WarningIcon from '../../../assets/icons/indicators/WarningIcon';
import { ErrorMessage } from '../../../components/forms/FormComponents';
import CancelledIndicator from '../components/CancelledIndicator';
import MainSection from 'components/sections/MainSection';
import ScheduleIcon from 'assets/icons/navigation/ScheduleIcon';
import PrimaryButton from 'components/buttons/PrimaryButton';

type State = {
    newScheduledCourseId: string;
};

export default function OALiveCoursesTable(): JSX.Element {
    const { userData } = useContext(AuthContext);
    const { currentWindowWidth } = useWindowWidth();
    const navigate = useNavigate();

    const { state } = useLocation();
    const { newScheduledCourseId } = (state as State) ?? {};

    const scrollRef = React.createRef<any>();

    React.useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    });

    const {
        data: scheduledCourses,
        loading,
        errors,
    } = useGetRequest<CourseScheduleOverview[]>(
        `organisations/${userData?.organisation?.id}/scheduled-courses`,
        !userData?.organisation?.id,
    );

    if (!scheduledCourses || loading) return <></>;

    if (errors && errors.length) {
        return (
            <CourseTableContentSection>
                <Headline3>{strings.OAScheduledCoursesTable.liveCoursesHeading}</Headline3>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OAScheduledCoursesTable.getScheduledCoursesError.title}
                    description={strings.OAScheduledCoursesTable.getScheduledCoursesError.description}
                />
            </CourseTableContentSection>
        );
    }

    const tableHeaders: TableHeader[] = [
        {
            name: strings.OAScheduledCoursesTable.tableHeaders.courseTitle,
            order: 1,
            rowPercentage: 36,
        },
        { name: strings.OAScheduledCoursesTable.tableHeaders.startDate, order: 2, rowPercentage: 12 },
        { name: strings.OAScheduledCoursesTable.tableHeaders.endDate, order: 3, rowPercentage: 12 },
        { name: strings.OAScheduledCoursesTable.tableHeaders.trainer, order: 4, rowPercentage: 15 },
        {
            name: strings.OAScheduledCoursesTable.tableHeaders.status,
            order: 5,
            rowPercentage: 15,
        },
        {
            name: '',
            order: 6,
            rowPercentage: 10,
        },
    ];

    const scheduledCourseRows: TableRow[] = (scheduledCourses ?? [])
        .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
        .sort((a, b) => {
            if (a.cancelled && !b.cancelled) {
                return 1;
            } else if (!a.cancelled && b.cancelled) {
                return -1;
            } else {
                return 0;
            }
        })
        .map((scheduledCourse) => {
            const shortStartDate = new Date(scheduledCourse.startDate).toLocaleDateString();
            const shortEndDate = new Date(scheduledCourse.startDate).toLocaleDateString();
            const linkAria = `${strings.OAScheduledCoursesTable.viewCourseButton.aria} ${scheduledCourse.courseTitle}`;

            return {
                id: scheduledCourse.id,
                cells: [
                    {
                        name: strings.OAScheduledCoursesTable.tableHeaders.courseTitle,
                        value: (
                            <ScheduledCourseContainer
                                ref={newScheduledCourseId === scheduledCourse.id ? scrollRef : null}
                            >
                                {currentWindowWidth > parseInt(breakpoints.xl) &&
                                    scheduledCourse.id === newScheduledCourseId && <ValidateIcon />}
                                <WrappingText>{scheduledCourse.courseTitle}</WrappingText>
                            </ScheduledCourseContainer>
                        ),
                        isBold: true,
                    },
                    {
                        name: strings.OAScheduledCoursesTable.tableHeaders.startDate,
                        value: shortStartDate,
                    },
                    {
                        name: strings.OAScheduledCoursesTable.tableHeaders.endDate,
                        value: shortEndDate,
                    },
                    {
                        name: strings.OAScheduledCoursesTable.tableHeaders.trainer,
                        isBold: true,
                        value:
                            currentWindowWidth < parseInt(breakpoints.xl)
                                ? scheduledCourse.trainer?.email ?? ''
                                : `${scheduledCourse.trainer?.firstName} ${scheduledCourse.trainer?.lastName}`,
                    },
                    {
                        name: strings.OAScheduledCoursesTable.tableHeaders.status,
                        value: scheduledCourse.cancelled ? (
                            <div>
                                <StatusContainer>
                                    <CancelledIndicator />
                                </StatusContainer>
                            </div>
                        ) : (
                            <div>
                                {scheduledCourse.enrolledLearnersCount >= scheduledCourse.maximumLearners ? (
                                    <EnrolmentFullContainer>
                                        <WarningIcon />
                                        <ErrorMessage $bold>
                                            {strings.scheduledCoursesPage.courseFullWarning}
                                        </ErrorMessage>
                                    </EnrolmentFullContainer>
                                ) : (
                                    ''
                                )}
                            </div>
                        ),
                    },
                    {
                        name: '',
                        value: scheduledCourse.cancelled ? (
                            ''
                        ) : (
                            <ButtonsSection>
                                <RightAlignedButtonCell>
                                    <NavigateWithArrow
                                        title={strings.OAScheduledCoursesTable.viewCourseButton.title}
                                        aria={linkAria}
                                        href={`/schedule/courses/${scheduledCourse.id}`}
                                        iconSize={IconSize.LARGE}
                                    />
                                </RightAlignedButtonCell>
                            </ButtonsSection>
                        ),
                    },
                ],
            };
        });

    return (
        <CourseTableContentSection>
            <TitleAndButtonContainer>
                <Headline3>{strings.OAScheduledCoursesTable.liveCoursesHeading}</Headline3>
                <PrimaryButton
                    title="View live courses"
                    aria="yes"
                    onClick={() => {
                        navigate(`/public/organisation/${userData.organisation!.publicName}`);
                    }}
                />
            </TitleAndButtonContainer>
            <div>
                <Table
                    headers={tableHeaders}
                    rows={scheduledCourseRows}
                    activeRow={newScheduledCourseId}
                    mobileViewBelow={breakpoints.xl}
                    emptyTableContent={
                        <EmptyTableContent
                            icon={<ScheduleIcon size={IconSize.XXLARGE} colour={theme.textColour} />}
                            title={strings.OAScheduledCoursesTable.emptyTableContent.title}
                            description={strings.OAScheduledCoursesTable.emptyTableContent.description}
                        />
                    }
                />
            </div>
        </CourseTableContentSection>
    );
}

const CourseTableContentSection = styled(MainSection)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    padding: ${sizes.spacingSm} 0;
    min-height: 120px;

    @media (max-width: ${breakpoints.sm}) {
        padding: unset;
    }
`;

const TitleAndButtonContainer = styled(FlexAlignCenter)`
    justify-content: space-between;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        align-items: flex-start;
    }
`;

const EnrolmentFullContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${sizes.spacingSm};

    @media (max-width: ${breakpoints.xl}) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
`;

const StatusContainer = styled.div`
    display: inline-block;
`;

const ScheduledCourseContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
`;
