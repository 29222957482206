import { useLocation, useParams } from 'react-router-dom';
import { LearnerActivity, LearnerLearningObjective, LearnerModule } from 'models/LearnerCourse';
import React, { useContext } from 'react';
import { PreviewContext } from 'contextProviders/PreviewContext';
import LearnerCourseComponent from 'components/learnerCourse/LearnerCourseComponent';
import { AuthContext } from '../../contextProviders/AuthContext';
import strings from '../../strings/strings.json';

type State = {
    activeModuleId: string;
};

export default function CoursePreviewPage(): JSX.Element {
    const { userTokenDetails } = useContext(AuthContext);
    const params = useParams();
    const { courseId } = params;

    const { state } = useLocation();
    const { activeModuleId } = (state as State) ?? {};

    const previewContext = React.useContext(PreviewContext);
    if (previewContext.scheduledCourse === undefined) return <></>;

    const learningObjectives: LearnerLearningObjective[] =
        previewContext.scheduledCourse.course.learningObjectives.map((o) => {
            const learningObjective: LearnerLearningObjective = {
                id: o.id,
                order: o.order,
                description: o.description,
            };
            return learningObjective;
        });

    const modules = previewContext.scheduledCourse.course.modules
        .sort((a, b) => a.order - b.order)
        .map((m) => {
            const module: LearnerModule = {
                id: m.id,
                title: m.title,
                description: m.description,
                order: m.order,
                dateCreated: new Date(m.dateCreated),
                activities: m.activities
                    .sort((a, b) => a.order - b.order)
                    .map((a) => {
                        const locationType = previewContext.scheduledCourse?.meetings.find(
                            (m) => m.activityId === a.id,
                        )?.locationType;

                        const activity: LearnerActivity = {
                            id: a.id,
                            title: a.title,
                            order: a.order,
                            type: a.type,
                            locationType: locationType,
                            startDate: a.meetings ? a.meetings[0].meetingDatetime : undefined,
                            completed: a.statuses[0].completed !== null,
                            questionsTotal: a.questions.length,
                            questionsAnswered: 0,
                            durationInMins: a.durationInMins,
                            moduleId: m.id,
                        };
                        return activity;
                    }),
            };
            return module;
        });

    const activeModuleIndex = modules.findIndex((module) => module.id === activeModuleId);

    return (
        <LearnerCourseComponent
            courseTitle={previewContext.scheduledCourse.course.title}
            courseDescription={previewContext.scheduledCourse.course.description}
            learningObjectives={learningObjectives}
            modules={modules}
            startDate={previewContext.scheduledCourse.startDate}
            endDate={previewContext.scheduledCourse.endDate}
            urlPrefix={`/preview/${courseId}`}
            activeModuleIndex={activeModuleIndex}
            trainerInfo={{
                firstName: userTokenDetails.given_name,
                lastName: userTokenDetails.family_name,
                email: userTokenDetails.email,
                bio: strings.learnerCoursePage.previewBioText,
                profileImagePath: null,
                organisationName: previewContext.scheduledCourse.course.organisation.name,
                organisationPublicName: previewContext.scheduledCourse.course.organisation.publicName,
                organisationLogo: previewContext.scheduledCourse.course.organisation.logoS3Filepath,
            }}
        />
    );
}
