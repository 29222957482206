import { BodyLarge, FlexCol, Headline2, Headline4 } from 'components/Elements';
import useGetRequest from 'hooks/useGetRequest';
import { LearnerCourse } from 'models/LearnerCourse';
import { useParams } from 'react-router-dom';
import strings from 'strings/strings.json';
import { Page, LearnerCourseContentError, ContentColumn } from '../components/shared';
import { CourseNavigationModule } from '../components/CourseNavigation';
import ActivityCardGrid from '../components/ActivityCardGrid';
import styled from 'styled-components';
import { sizes } from 'theme';

export default function ModulePage(): JSX.Element {
    const params = useParams();
    const { courseScheduleId, moduleId } = params;
    const {
        data: learnerCourse,
        loading,
        errors,
    } = useGetRequest<LearnerCourse>(`learner/schedule/${courseScheduleId}`);

    if (!courseScheduleId || !moduleId || errors.length > 0) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    if (loading) {
        return <></>;
    }

    if (!learnerCourse) {
        return <></>;
    }

    return (
        <ModulePageContent
            learnerCourse={learnerCourse}
            moduleId={moduleId}
            courseId={learnerCourse.id}
            courseTitle={learnerCourse.title}
            urlPrefix={'/learner'}
        />
    );
}

type ModulePageContentProps = {
    learnerCourse: LearnerCourse;
    moduleId: string;
    courseId: string;
    courseTitle: string;
    urlPrefix: string;
};

export function ModulePageContent({ learnerCourse, moduleId, urlPrefix }: ModulePageContentProps): JSX.Element {
    const module = learnerCourse.modules.find((module) => module.id === moduleId);

    return (
        <Page>
            <ContentColumn>
                <Headline2>{module && `${strings.modulePage.module} ${module.order}: ${module.title}`}</Headline2>
                <div>
                    <BodyLarge>{module && module.description}</BodyLarge>
                </div>
                <Container>
                    <Headline4>{strings.modulePage.activities}</Headline4>
                    {module && (
                        <ActivityCardGrid
                            scheduledCourseId={learnerCourse.id}
                            activities={module.activities}
                            urlPrefix={urlPrefix}
                        />
                    )}
                </Container>
            </ContentColumn>
            <CourseNavigationModule learnerCourse={learnerCourse} moduleId={moduleId} urlPrefix={urlPrefix} />
        </Page>
    );
}

const Container = styled(FlexCol)`
    gap: ${sizes.spacingLg};
`;
