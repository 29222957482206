import strings from '../../../../strings/strings.json';
import { EnrolledUser } from 'models/EnrolledUser';
import { BodySmall } from '../../../../components/Elements';
import Table from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import HeaderWithCount from 'pages/OA/components/HeaderWithCount';
import LearnerIcon from 'assets/icons/role-icons/LearnerIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { AccessNeedsOptions } from '../../../../models/UserPreferences';
import React from 'react';
import { ACCESSIBILITY_OPTIONS } from 'models/AccessibilityOptions';
import styled from 'styled-components';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import DownloadIcon from '../../../../assets/icons/controls/DownloadIcon';
import { breakpoints, sizes, theme } from '../../../../theme';
import { API_URL } from '../../../../lib/_api-helpers';
import { downloadCsvFile } from '../../../../lib/downloadCsvFile';
import Spinner from '../../../../components/Spinner';
import { ErrorMessage } from '../../../../components/forms/FormComponents';
import { AuthContext } from '../../../../contextProviders/AuthContext';
import WarningIcon from '../../../../assets/icons/indicators/WarningIcon';
import ProgressBar from 'components/progressBar/ProgressBar';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import RemoveLearnerModal from './RemoveLearnerModal';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import useGetRequest from 'hooks/useGetRequest';
import useWindowWidth from 'hooks/useWindowWidth';
import { logError } from 'lib/debug-helpers';
import { CompletionIndicator } from 'components/progressBar/CompletionIndicator';

type EnrolledUsersProps = {
    courseScheduleId: string;
    maximumLearners: number;
};

export default function EnrolledUserTable({ courseScheduleId, maximumLearners }: EnrolledUsersProps): JSX.Element {
    const {
        data: enrolledUsers,
        loading: loadingLearners,
        errors: learnersErrors,
        refetchData: refetchLearners,
    } = useGetRequest<EnrolledUser[]>(`courses/schedule/${courseScheduleId}/learners`);

    const authContext = React.useContext(AuthContext);
    const { isMobileWidth, isDesktopWidth } = useWindowWidth();
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [downloadError, setDownloadError] = React.useState<string | null>(null);
    const [idOfLearnerToDelete, setIdOfLearnerToDelete] = React.useState<string>('');
    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState<boolean>(false);
    const [learnerRemovedNotifier, setLearnerRemovedNotifier] = React.useState<boolean>(false);

    if (!enrolledUsers || loadingLearners) return <></>;

    if (learnersErrors.length > 0) {
        logError(learnersErrors);
    }

    function closeModal() {
        setIdOfLearnerToDelete('');
        setAreYouSureModalOpen(false);
        setLearnerRemovedNotifier(false);
    }

    async function onCompleted(): Promise<void> {
        await refetchLearners();
        setLearnerRemovedNotifier(true);
    }

    async function downloadFile(): Promise<void> {
        setDownloadError(null);
        setDownloading(true);

        const url = `${API_URL}/courses/schedule/${courseScheduleId}/learners/file`;
        let token = authContext.userData.tokens.accessToken ?? '';

        const downloadResponse = await downloadCsvFile(url, token, 'learner_completion_report_' + courseScheduleId);

        if (downloadResponse.status === 204) {
            setDownloadError(strings.enrolledUsers.downloadReportNoUsersError);
            setDownloading(false);
            return;
        }

        if (!downloadResponse.ok) {
            setDownloadError(strings.enrolledUsers.downloadReportGeneralError);
            setDownloading(false);
            return;
        }

        setDownloading(false);
    }

    if (!enrolledUsers) return <></>;

    return (
        <>
            <ReportButtonRow>
                {downloadError && <WarningIcon />}
                {downloadError && <ErrorMessage>{downloadError}</ErrorMessage>}
            </ReportButtonRow>
            <HeaderAndTableContainer>
                <HeaderWithCount
                    spacesLeftCounter={
                        <ProgressBar
                            label={strings.enrolledUsers.progressBarLabel}
                            current={enrolledUsers.length}
                            max={maximumLearners}
                        />
                    }
                    actionButton={
                        enrolledUsers.length > 0 ? (
                            <PrimaryButton
                                title={strings.enrolledUsers.downloadReportButtonTitle}
                                aria={strings.enrolledUsers.downloadReportButtonAria}
                                icon={downloading ? <Spinner /> : <DownloadIcon colour={theme.textColourInverted} />}
                                onClick={() => downloadFile()}
                                fullwidth
                            />
                        ) : undefined
                    }
                />
                {learnerRemovedNotifier && (
                    <Notification
                        notificationType={NotificationType.INFO}
                        title={strings.enrolledUsers.learnerRemovedNotificationText}
                        maxWidth={isMobileWidth ? '100%' : '50%'}
                    />
                )}
                <Table
                    headers={[
                        {
                            name: strings.enrolledUsers.nameText,
                            order: 1,
                            rowPercentage: 20,
                        },
                        { name: strings.enrolledUsers.emailText, order: 2, rowPercentage: 20 },
                        { name: strings.enrolledUsers.accessibilityRequirementsText, order: 3, rowPercentage: 30 },
                        {
                            name: isDesktopWidth ? (
                                <CenteredFlexBox>{strings.enrolledUsers.tasksCompletedText}</CenteredFlexBox>
                            ) : (
                                strings.enrolledUsers.tasksCompletedText
                            ),
                            order: 4,
                            rowPercentage: 15,
                            mobileRightColumn: true,
                        },
                        { name: '', order: 5, rowPercentage: 15 },
                    ]}
                    rows={enrolledUsers.map((enrolledUser) => {
                        const learnerName = `${enrolledUser.firstName} ${enrolledUser.lastName}`;
                        const accessibilityStrings = getAccessibilityStrings(enrolledUser);

                        return {
                            id: enrolledUser.id,
                            cells: [
                                {
                                    name: strings.enrolledUsers.nameText,
                                    value: <BodySmall $bold>{learnerName}</BodySmall>,
                                },
                                {
                                    name: strings.enrolledUsers.emailText,
                                    value: <BodySmall>{enrolledUser.email}</BodySmall>,
                                },
                                {
                                    name: strings.enrolledUsers.accessibilityRequirementsText,
                                    value: (
                                        <div>
                                            {accessibilityStrings.map((string, index) => (
                                                <React.Fragment key={index}>
                                                    <BodySmall>{string}.</BodySmall>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    ),
                                },
                                {
                                    name: strings.enrolledUsers.tasksCompletedText,
                                    value: (
                                        <CenteredFlexBox>
                                            <CompletionIndicator
                                                completed={enrolledUser.completedActivities}
                                                total={enrolledUser.totalActivities}
                                            />
                                        </CenteredFlexBox>
                                    ),
                                },
                                {
                                    name: '',
                                    value: (
                                        <RemoveButtonContainer>
                                            <SecondaryButton
                                                title={strings.enrolledUsers.removeLearnerButton.title}
                                                aria={`${strings.enrolledUsers.removeLearnerButton.ariaPart1} ${enrolledUser.firstName} ${enrolledUser.lastName} ${strings.enrolledUsers.removeLearnerButton.ariaPart2}`}
                                                onClick={() => {
                                                    setIdOfLearnerToDelete(enrolledUser.id);
                                                    setAreYouSureModalOpen(true);
                                                    setLearnerRemovedNotifier(false);
                                                }}
                                                alternateColour={SecondaryButtonColour.ALTERNATE}
                                            />
                                        </RemoveButtonContainer>
                                    ),
                                },
                            ],
                        };
                    })}
                    emptyTableContent={
                        <EmptyTableContent
                            icon={<LearnerIcon size={IconSize.XLARGE} />}
                            title={strings.enrolledUsers.emptyTable.title}
                            description={strings.enrolledUsers.emptyTable.description}
                        />
                    }
                />
                {areYouSureModalOpen && (
                    <RemoveLearnerModal
                        courseScheduleId={courseScheduleId}
                        idOfLearnerToDelete={idOfLearnerToDelete}
                        closeModal={closeModal}
                        onCompleted={onCompleted}
                    />
                )}
            </HeaderAndTableContainer>
        </>
    );
}

const getAccessibilityStrings = (user: EnrolledUser): string[] => {
    let accessibilityStrings: string[] = [];

    if (!user.userPreferences || !user.userPreferences.hasAccessibilityRequirements) {
        accessibilityStrings.push(strings.enrolledUsers.noSpecifiedAccessibilityRequirements);
        return accessibilityStrings;
    }

    if (user.userPreferences.hasAccessibilityRequirements) {
        for (const preference in user.userPreferences) {
            if (user.userPreferences[preference as keyof AccessNeedsOptions]) {
                const option = ACCESSIBILITY_OPTIONS.find((option) => option.value === preference);

                if (option) {
                    accessibilityStrings.push(option.name);
                }
            }
        }

        accessibilityStrings = accessibilityStrings.sort((a, b) => a.localeCompare(b));
        user.userPreferences.otherNeeds && accessibilityStrings.push(user.userPreferences.otherNeeds);
    }

    if (accessibilityStrings.length === 0) {
        accessibilityStrings.push(strings.enrolledUsers.noSpecifiedAccessibilityRequirements);
    }

    return accessibilityStrings;
};

const CenteredFlexBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: ${breakpoints.lg}) {
        justify-content: unset;
    }
`;

const HeaderAndTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const ReportButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    @media (max-width: ${breakpoints.md}) {
        justify-content: unset;
    }
`;
