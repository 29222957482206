import { IconSize } from 'assets/icons/icon-sizes';
import DurationLabel from 'components/DurationLabel';
import { BodyRegular, FlexCol, Headline6 } from 'components/Elements';
import { getActivityTypeIcon } from 'models/Activity';
import { LearnerActivity } from 'models/LearnerCourse';
import { Resource } from 'models/Resources';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme, sizes, breakpoints } from 'theme';
import strings from '../../../strings/strings.json';
import ExternalLinkIcon from '../../../assets/icons/navigation/ExternalLinkIcon';

type ActivityCardGridProps = {
    scheduledCourseId: string;
    activities: LearnerActivity[];
    urlPrefix: string;
};

export default function ActivityCardGrid({
    scheduledCourseId,
    activities,
    urlPrefix,
}: ActivityCardGridProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <CardGrid>
            {activities.map((activity, index) => (
                <CardButton
                    key={index}
                    onClick={() => {
                        navigate(`${urlPrefix}/${scheduledCourseId}/activity/${activity.id}`);
                    }}
                    aria-label={`${strings.immersiveCourseViewButtons.moduleOverviewClickableActivityAria} ${activity.title}`}
                >
                    <IconContainer>{getActivityTypeIcon(activity, IconSize.XLARGE)}</IconContainer>
                    <CardInfo>
                        <BodyRegular $bold>{activity.title}</BodyRegular>
                        {activity.durationInMins && (
                            <DurationLabel durationInMins={activity.durationInMins} hideTitle />
                        )}
                    </CardInfo>
                </CardButton>
            ))}
        </CardGrid>
    );
}

type ResourceCardGridProps = {
    resources: Resource[];
};

export function ResourceCardGrid({ resources }: ResourceCardGridProps): JSX.Element {
    return (
        <LinkContainer>
            <Headline6>{strings.additonalResources.title}</Headline6>
            <CardGrid>
                {resources.map((resource, index) => (
                    <CardButtonSmall
                        key={index}
                        onClick={() => {
                            window.open(resource.url, '_blank');
                        }}
                        aria-label={strings.general.openInNewTab}
                    >
                        <IconContainerSmall>
                            <ExternalLinkIcon colour={theme.textColour} size={IconSize.XLARGE} />
                        </IconContainerSmall>
                        <ResourceInfo>
                            <BodyRegular $bold>{resource.title}</BodyRegular>
                        </ResourceInfo>
                    </CardButtonSmall>
                ))}
            </CardGrid>
        </LinkContainer>
    );
}

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    max-width: 60px;
    min-height: 80px;
    max-height: 80px;
    background-color: ${theme.iconBackgroundColour};
    border-radius: ${sizes.borderRadiusLg};
`;

const ResourceInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXs};
`;

const LinkContainer = styled(FlexCol)`
    gap: 16px;
`;

const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
`;

const CardButton = styled.button`
    all: unset;
    display: flex;
    gap: ${sizes.spacingMd};
    padding: ${sizes.spacingMd};
    cursor: pointer;

    box-sizing: border-box;
    border: 1px solid ${theme.cardBorder};
    border-radius: 8px;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    background: ${theme.cardBackground};

    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
        border: 1px solid ${theme.secondaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
`;

const CardButtonSmall = styled(CardButton)`
    align-items: center;
`;

const IconContainerSmall = styled(IconContainer)`
    min-width: 30px;
    max-width: 30px;
    min-height: 40px;
    max-height: 40px;
`;

const CardGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${sizes.spacingMd};
    row-gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: auto;
    }
`;
