import Stepper from 'components/stepper/Stepper';
import { LiveTrainingActivity, createEmptyLiveTrainingActivity } from 'models/inputModels/LiveTrainingActivity';
import EmptyForm from './EmptyForm';
import LiveTrainingDetailsForm from './LiveTrainingDetailsForm';

type LiveTrainingCreateComponentProps = {
    saveActivityClick: () => void;
    moduleId: string;
};

export default function LiveTrainingCreateComponent({
    saveActivityClick,
    moduleId,
}: LiveTrainingCreateComponentProps): JSX.Element {
    return (
        <Stepper<LiveTrainingActivity>
            defaultObject={createEmptyLiveTrainingActivity(moduleId)}
            steps={[
                { name: 'Live training', Form: EmptyForm },
                { name: 'Details', Form: LiveTrainingDetailsForm },
            ]}
            defaultStep={1}
            complete={saveActivityClick}
        />
    );
}
