import * as React from 'react';
import strings from '../../strings/strings.json';
// @ts-ignore
import { ReactComponent as Dashboard } from '../../assets/backgrounds/dashboard.svg';
import CourseCard from './components/CourseCard';
import { Course } from '../../models/Course';
import { breakpoints, sizes, theme } from '../../theme';
import useGetRequest from '../../hooks/useGetRequest';
import { Headline1, Headline2, Headline3 } from 'components/Elements';
import { TrainerHeader } from '../../components/sections/TrainerHeader';
import { MainSectionWithGaps } from '../../components/sections/MainSection';
import PlusIcon from 'assets/icons/controls/PlusIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import LargeCreateButton from '../../components/buttons/LargeCreateButton';
import styled from 'styled-components';
import useWindowWidth from 'hooks/useWindowWidth';
import Modal from 'components/modals/Modal';
import CreateCourseSwitch from 'components/modals/createCourseModalComponents/CreateCourseSwitch';

export default function TrainerDashboardPage(): JSX.Element {
    const { isDesktopWidth } = useWindowWidth();

    const { data: courses, called } = useGetRequest<Course[]>('courses/user');

    const [openCreateCourseModal, setOpenCreateCourseModal] = React.useState<boolean>(false);

    const createCourseButtonRef = React.useRef<HTMLButtonElement>(null);
    const largeCreateCourseButtonRef = React.useRef<HTMLButtonElement>(null);

    if (!called) return <></>;

    const publishedCourses = courses?.filter((c) => c.published);
    const unpublishedCourses = courses?.filter((c) => !c.published);

    function closeCourseModal() {
        setOpenCreateCourseModal(false);
        createCourseButtonRef.current?.focus();
        largeCreateCourseButtonRef.current?.focus();
    }

    return (
        <>
            <TrainerHeader>
                <TextCol>
                    <Headline1 $inverted>{strings.dashboardPage.headerTitle}</Headline1>
                </TextCol>
                {isDesktopWidth && (
                    <ImageCol>
                        <Dashboard />
                    </ImageCol>
                )}
            </TrainerHeader>
            <MainSectionWithGaps>
                <CoursesHeader>
                    <Headline2>{strings.dashboardPage.coursesTitle}</Headline2>
                    {!courses || courses.length <= 0 ? null : (
                        <CreateCourseButtonContainer>
                            <PrimaryButton
                                buttonRef={createCourseButtonRef}
                                title={strings.dashboardPage.headerActionTitle}
                                aria={strings.dashboardPage.createCourseButtonAria}
                                onClick={() => setOpenCreateCourseModal(true)}
                                icon={<PlusIcon size={IconSize.LARGE} />}
                            />
                        </CreateCourseButtonContainer>
                    )}
                </CoursesHeader>
                <CourseListsSection>
                    {!courses || courses.length <= 0 ? (
                        <LargeCreateButton
                            buttonRef={largeCreateCourseButtonRef}
                            title={strings.dashboardPage.createCourseButton}
                            aria={strings.dashboardPage.createCourseButtonAria}
                            onClick={() => setOpenCreateCourseModal(true)}
                            icon={<PlusIcon size={IconSize.XXXLARGE} />}
                        />
                    ) : null}
                    {publishedCourses?.length ? (
                        <CourseList>
                            <Headline3>{strings.dashboardPage.publishedCourses}</Headline3>
                            <CourseCardsContainer>
                                {publishedCourses.map((course: Course) => {
                                    return <CourseCard course={course} key={course.id} />;
                                })}
                            </CourseCardsContainer>
                        </CourseList>
                    ) : (
                        <></>
                    )}
                    {unpublishedCourses?.length ? (
                        <CourseList>
                            <Headline3>{strings.dashboardPage.unpublishedCourses}</Headline3>
                            <CourseCardsContainer>
                                {unpublishedCourses.map((course: Course) => {
                                    return <CourseCard course={course} key={course.id} />;
                                })}
                            </CourseCardsContainer>
                        </CourseList>
                    ) : (
                        <></>
                    )}
                </CourseListsSection>
                {openCreateCourseModal && (
                    <Modal
                        onClickCloseButton={closeCourseModal}
                        headlineText={strings.createCourseModal.createCourseHeadline}
                        modalBody={<CreateCourseSwitch closeModal={closeCourseModal} />}
                    />
                )}
            </MainSectionWithGaps>
        </>
    );
}

const CourseListsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXl};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingLg};
    }
`;

const CourseList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
`;

const CoursesHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const CreateCourseButtonContainer = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        padding-top: ${sizes.spacingMd};
        width: 100%;
    }
`;

export const CourseCardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${sizes.spacingMd};
    grid-row-gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const TextCol = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${theme.trainerDashboardBannerBackground};
`;

const ImageCol = styled.div`
    background-color: ${theme.trainerDashboardBannerImageBackground};
    display: none;
    height: 100%;
    @media only screen and (min-width: ${breakpoints.sm}) {
        display: block;
    }
`;
