import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline5 } from 'components/Elements';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from 'strings/strings.json';
import Skeleton from 'react-loading-skeleton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import { APIError, ApiResponse } from '../../../lib/_api-helpers';

type MarkAsCompleteCardProps = {
    completed: boolean;
    toggleTaskCompletion: () => Promise<ApiResponse<boolean>>;
    toggleCompletionLoading: boolean;
    toggleCompletionErrors: APIError[];
};

export default function MarkAsCompleteCard({
    toggleTaskCompletion,
    completed,
    toggleCompletionLoading,
    toggleCompletionErrors,
}: MarkAsCompleteCardProps): JSX.Element {
    if (toggleCompletionLoading) {
        return (
            <GreenBanner>
                <Content $completed={false}>
                    <Skeleton height={44} />
                </Content>
            </GreenBanner>
        );
    }

    if (toggleCompletionErrors.length > 0 || toggleCompletionErrors.length > 0) {
        return (
            <GreenBanner>
                <Content $completed={false}>
                    <ErrorNotifierContainer>
                        <ErrorNotifier
                            title={strings.taskPage.markAsComplete.errorTitle}
                            description={strings.taskPage.markAsComplete.errorDescription}
                            maxWidth={'100%'}
                        />
                    </ErrorNotifierContainer>
                </Content>
            </GreenBanner>
        );
    }

    return (
        <GreenBanner>
            <Content $completed={completed}>
                <Headline5 $inverted={completed}>
                    {completed ? strings.taskPage.markAsComplete.complete : strings.taskPage.markAsComplete.incomplete}
                </Headline5>

                {completed ? (
                    <SecondaryButton
                        title={strings.taskPage.markAsComplete.incompleteButtonTitle}
                        aria={strings.taskPage.markAsComplete.incompleteButtonAria}
                        onClick={toggleTaskCompletion}
                    />
                ) : (
                    <PrimaryButton
                        title={strings.taskPage.markAsComplete.completeButtonTitle}
                        aria={strings.taskPage.markAsComplete.completeButtonAria}
                        onClick={toggleTaskCompletion}
                    />
                )}
            </Content>
        </GreenBanner>
    );
}

const ErrorNotifierContainer = styled.div`
    width: 100%;
`;

export const Content = styled.div<{ $completed: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${sizes.spacingRg};
    width: 900px;
    background-color: ${({ $completed }) =>
        $completed ? theme.learnerActivityCard.completedNotificationBackground : theme.cardBackground};
    margin: ${sizes.spacingLg} 0;
    padding: ${sizes.spacingLg};
    border-radius: ${sizes.borderRadiusLg};

    @media (max-width: ${breakpoints.md}) {
        padding: ${sizes.spacingLg} ${sizes.spacingMd};
        width: 90%;
    }

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

export const GreenBanner = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${theme.learnerActivityCard.completedBackground};
`;
