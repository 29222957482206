import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline4 } from 'components/Elements';
import useNeveForm from 'components/forms/NeveForm';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import { NotificationType } from 'components/notifiers/Notification';
import { WarningNotifierSlim } from 'components/notifiers/WarningNotifierSlim';
import ProgressBar from 'components/progressBar/ProgressBar';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import useGetRequest from 'hooks/useGetRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { ActivityMeeting } from 'models/ActivityMeeting';
import { AttendeeTableRow, AttendeesTable } from 'pages/schedule/components/AttendeesTable';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme, sizes, breakpoints } from 'theme';
import strings from '../../../../../strings/strings.json';
import Notification from 'components/notifiers/Notification';

type UpdateAttendanceRequest = {
    scheduledCourseId: string;
    activityId: string;
    learnerAttendance: LearnerAttendance[];
};

type LearnerAttendance = {
    userId: string;
    attended: boolean;
};

type UpdateAttendanceInput = {
    learnerAttendances: boolean[];
};

export default function Attendance(): JSX.Element {
    const { liveTrainingId, courseId } = useParams();

    const {
        data: liveTrainingMeeting,
        loading,
        called,
        errors,
        refetchData,
    } = useGetRequest<ActivityMeeting>(
        `courses/schedule/${courseId}/live-training/${liveTrainingId}`,
        !liveTrainingId,
    );

    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [allAttendedCheckbox, setAllAttendedCheckbox] = useState<boolean>(false);

    const { modifyData: updateAttendance } = useModifyRequest(
        `courses/schedule/${courseId}/live-training/${liveTrainingId}/attendance`,
        'PATCH',
    );

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty },
        setValue,
        getValues,
        watch,
    } = useNeveForm<UpdateAttendanceInput>({
        learnerAttendances: [],
    });

    React.useEffect(() => {
        if (called && liveTrainingMeeting) {
            const formValues: UpdateAttendanceInput = {
                learnerAttendances: liveTrainingMeeting.attendees.map((a) => a.attended ?? false),
            };

            reset(formValues);
            setAllAttendedCheckbox(liveTrainingMeeting.attendees.every((a) => a.attended));
        }
    }, [called, liveTrainingMeeting, reset]);

    const watchForm = watch();

    React.useEffect(() => {
        const someNotAttended = watchForm.learnerAttendances.some((attended) => !attended);

        if (someNotAttended) {
            setAllAttendedCheckbox(false);
        }
    }, [watchForm]);

    React.useEffect(() => {
        if (isDirty) {
            setShowSuccess(false);
            setShowError(false);
        }
    }, [isDirty]);

    if (!liveTrainingMeeting || loading) return <></>;

    if (errors && errors.length > 0) {
        return (
            <MainSectionWithGaps>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.liveTrainingDetailPage.titleError}
                    description={strings.errorMessages.genericDetail}
                    big
                />
            </MainSectionWithGaps>
        );
    }

    if (!liveTrainingMeeting) {
        return <></>;
    }

    async function submitUpdateAttendance(formData: UpdateAttendanceInput) {
        setShowError(false);
        setShowSuccess(false);

        const allAttendance: LearnerAttendance[] = [];

        if (!liveTrainingMeeting || !courseId || !liveTrainingId) return;

        for (let i = 0; i < liveTrainingMeeting.attendees.length; i++) {
            allAttendance.push({
                userId: liveTrainingMeeting.attendees[i].userId,
                attended: formData.learnerAttendances[i],
            });
        }

        const request: UpdateAttendanceRequest = {
            scheduledCourseId: courseId,
            activityId: liveTrainingId,
            learnerAttendance: allAttendance,
        };

        const response = await updateAttendance(request);

        if (response.errors) {
            setShowError(true);
            setShowSuccess(false);
        }

        await refetchData();
        setShowSuccess(true);
    }

    const attendeeTableRows: AttendeeTableRow[] = [];

    let index = 0;

    for (const attendee of liveTrainingMeeting.attendees) {
        const attendeeTableRow: AttendeeTableRow = {
            attendeeName: `${attendee.firstName} ${attendee.lastName}`,
            attendeeImagePath: attendee.imagePath,
            attendeeEmail: attendee.email,
            checkboxProps: { ...register(`learnerAttendances.${index}`) },
            checkboxValue: attendee.attended ?? false,
            checkboxName: '',
        };

        attendeeTableRows.push(attendeeTableRow);
        index++;
    }

    function attendedHeaderCheckboxOnCheck(event: any) {
        const learnerAttendances = getValues('learnerAttendances');

        const attended = event?.target?.checked as boolean;

        for (let i = 0; i < learnerAttendances.length; i++) {
            learnerAttendances[i] = attended;
        }

        setValue(`learnerAttendances`, learnerAttendances, { shouldDirty: true });
        setAllAttendedCheckbox(!allAttendedCheckbox);
    }

    return (
        <>
            <MainSectionWithGaps>
                <HeadlineAndProgressBar>
                    <Headline4>{strings.liveTrainingDetailPage.attendanceTable}</Headline4>
                    <AttendanceProgressBarContainer>
                        <ProgressBar
                            label={strings.liveTrainingDetailPage.progressBarLabel}
                            max={liveTrainingMeeting.attendees.length}
                            current={liveTrainingMeeting.attendees.filter((a) => a.attended === true).length}
                        />
                    </AttendanceProgressBarContainer>
                </HeadlineAndProgressBar>
                <form onSubmit={handleSubmit(submitUpdateAttendance)}>
                    <FormContent>
                        <TableContainer>
                            <AttendeesTable
                                attendeeRows={attendeeTableRows}
                                attendedHeaderCheckboxOnCheck={attendedHeaderCheckboxOnCheck}
                                attendedHeaderCheckboxInitialValue={allAttendedCheckbox}
                            />
                        </TableContainer>
                        <RelativeContainer>
                            <FixedContainer id="fixedAttendanceRow">
                                <SaveAttendanceContainer>
                                    {isDirty ? (
                                        <WarningNotifierSlim
                                            description={strings.liveTrainingDetailPage.saveAttendance.unsavedChanges}
                                        />
                                    ) : showSuccess ? (
                                        <Notification notificationType={NotificationType.SUCCESS} />
                                    ) : (
                                        showError && (
                                            <ErrorNotifierSlim
                                                description={strings.liveTrainingDetailPage.saveAttendance.error}
                                            />
                                        )
                                    )}
                                    <PrimaryButton
                                        title={strings.liveTrainingDetailPage.saveAttendance.button.title}
                                        aria={strings.liveTrainingDetailPage.saveAttendance.button.aria}
                                        type="submit"
                                    />
                                </SaveAttendanceContainer>
                            </FixedContainer>
                        </RelativeContainer>
                    </FormContent>
                </form>
            </MainSectionWithGaps>
        </>
    );
}

const TableContainer = styled.div`
    margin-bottom: ${sizes.spacingLg};

    @media (max-width: ${breakpoints.sm}) {
        margin-bottom: 120px;
    }
`;

const RelativeContainer = styled.div`
    position: relative;
`;

const FixedContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${theme.cardBackground};
    border-top: 2px solid ${theme.cardBorder};
    padding: 0 ${sizes.spacingMd};
    height: 100px;

    display: flex;

    @media (max-width: ${breakpoints.sm}) {
        height: unset;
        padding: ${sizes.spacingMd};
        margin-bottom: 40px;
    }
`;

const SaveAttendanceContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: end;
    align-items: center;
    margin: 0 auto ${sizes.spacingMd};
    max-width: 1270px;
    width: 100%;

    @media (max-width: ${breakpoints.xl}) {
        margin: 0 0 ${sizes.spacingMd};
    }

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const AttendanceProgressBarContainer = styled.div`
    min-width: 300px;

    @media (max-width: ${breakpoints.sm}) {
        min-width: 100%;
    }
`;

const HeadlineAndProgressBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingRg};
        align-items: start;
    }
`;
