import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { MagnificationProvider } from './contextProviders/MagnificationContextProvider';
import AuthLayout from './layouts/AuthLayout';
import {
    LearnerLayout,
    TrainerLayout,
    MainLayout,
    AdminLayout,
    PreviewLayout,
    EditPublishedCourseLayout,
    OrganisationLayout,
    LearnerContentLayout,
    LearnerCourseLayout,
    PublicLayout,
    TrainerEditCourseLayout,
    InitialLoginLayout,
} from './layouts/MainLayout';
import { AuthProvider } from './contextProviders/AuthContext';
import Redirect from './pages/Redirect';
import ScheduledCoursesPage from './pages/trainer/scheduledCourses/ScheduledCoursesPage';
import CourseReviewPage from './pages/trainer/CourseReviewPage';
import CourseEditorPage from './pages/edit-course/CourseEditorPage';
import CourseTitleAndDescriptionPage from './pages/trainer/course/CourseTitleAndDescriptionPage';
import CourseLearningObjectivesPage from './pages/trainer/course/CourseLearningObjectivesPage';
import CoursePreviewPage from './pages/preview/CoursePreviewPage';
import LearnerCoursePage from './pages/learner/LearnerCoursePage';
import EnrollPage from './pages/learner/EnrollPage';
import LoginPage from './pages/auth/LoginPage';
import LoginSuccessPage from './pages/auth/LoginSuccessPage';
import LogoutPage from './pages/LogoutPage';
import AdminDashboardPage from 'pages/admin/AdminDashboardPage';
import ChangeUserRolePage from 'pages/admin/ChangeUserRolePage';
import TrainerDashboardPage from 'pages/trainer/TrainerDashboardPage';
import CreateOrganisationPage from 'pages/admin/CreateOrganisationPage';
import UpdateOrganisationPage from 'pages/admin/UpdateOrganisationPage';
import LearnerCoursesPage from 'pages/learner/LearnerCoursesPage';
import { SkeletonTheme } from 'react-loading-skeleton';
import PublishCourseWarningPage from 'pages/trainer/course/PublishCourseWarningPage';
import EditPublishedCoursePage from 'pages/trainer/course/EditPublishedCoursePage';
import EditCourseWarningPage from 'pages/trainer/course/EditCourseWarningPage';
import EditLearningObjectivesPage from 'pages/trainer/editPublishedCourse/EditLearningObjectivesPage';
import EditTitleAndDescriptionPage from 'pages/trainer/editPublishedCourse/EditTitleAndDescriptionPage';
import EditModulePage from 'pages/trainer/editPublishedCourse/EditModulePage';
import NotificationsPage from 'pages/notifications/NotificationsPage';
import { TrainerDetailsPage } from 'pages/OA/trainers/TrainerDetailsPage';
import { TrainerInvitePage } from 'pages/OA/trainers/TrainerInvitePage';
import InvitationPage from 'pages/invitation/InvitationPage';
import OACoursePage from 'pages/OA/courses/OACoursePage';
import FileUploadPage from './pages/OA/files/FileUploadPage';
import ScheduleCoursePage from './pages/schedule/ScheduleCoursePage';
import OrganisationOverviewPage from 'pages/OA/OrganisationOverviewPage';
import LiveTrainingDetailPage from './pages/schedule/courses/live-training/LiveTrainingDetailPage';
import ScheduledCourseDetailPage from './pages/schedule/courses/ScheduledCourseDetailPage';
import LogoUploadPage from './pages/OA/logo/LogoUploadPage';
import ModulePage from './pages/learner/module/ModulePage';
import ModulePreviewPage from 'pages/preview/ModulePreviewPage';
import ActivityPage from 'pages/learner/activity/ActivityPage';
import ActivityPreviewPage from 'pages/preview/ActivityPreviewPage';
import LogoutIfUnauthenticated from './layouts/components/LogoutIfUnauthenticated';
import ProfilePage from './pages/profile/ProfilePage';
import PublicOrganisationPage from 'pages/public/PublicOrganisationPage';
import PublicCoursePage from 'pages/public/PublicCoursePage';
import AddUserPage from './pages/admin/AddUserPage';
import NotifyUsersPage from 'pages/admin/NotifyUsersPage';
import OADashboard from 'pages/OA/OADashboard';
import InitialLoginPage from 'pages/initial-login/InitialLoginPage';

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/public" element={<PublicLayout />}>
                <Route path="organisation/:name" element={<PublicOrganisationPage />} />
                <Route path="course/:id" element={<PublicCoursePage />} />
            </Route>

            <Route path="/auth" element={<AuthLayout />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="logged-in" element={<LoginSuccessPage />} />
            </Route>

            <Route element={<LogoutIfUnauthenticated />}>
                <Route path="/initial-login" element={<InitialLoginLayout />}>
                    <Route index element={<InitialLoginPage />} />
                </Route>

                <Route path="/admin" element={<AdminLayout />}>
                    <Route path="dashboard" element={<AdminDashboardPage />} />
                    <Route path="notify-users" element={<NotifyUsersPage />} />
                    <Route path="add-user" element={<AddUserPage />} />
                    <Route path="change-user-role/:id" element={<ChangeUserRolePage />} />
                    <Route path="create-organisation" element={<CreateOrganisationPage />} />
                    <Route path="organisation/:id" element={<UpdateOrganisationPage />} />
                </Route>
                <Route path="/invitations" element={<MainLayout />}>
                    <Route path=":id" element={<InvitationPage />} />
                </Route>

                <Route path="/edit-course/:courseId" element={<TrainerEditCourseLayout />}>
                    <Route index element={<CourseEditorPage />} />
                </Route>

                <Route path="/trainer" element={<TrainerLayout />}>
                    <Route path="dashboard" element={<TrainerDashboardPage />} />
                    <Route path="scheduled-courses" element={<ScheduledCoursesPage />} />

                    <Route path="course/:courseId" element={<Outlet />}>
                        <Route path="title-and-description" element={<CourseTitleAndDescriptionPage />} />
                        <Route path="learning-objectives" element={<CourseLearningObjectivesPage />} />
                        <Route path="publish-course-warning" element={<PublishCourseWarningPage />} />
                        <Route path="edit-course-warning" element={<EditCourseWarningPage />} />
                        <Route path="view" element={<CourseReviewPage />} />
                    </Route>
                </Route>

                <Route path="/preview/:courseId" element={<PreviewLayout />}>
                    <Route index element={<CoursePreviewPage />} />
                    <Route path="module/:moduleId" element={<ModulePreviewPage />} />
                    <Route path="activity/:activityId" element={<ActivityPreviewPage />} />
                </Route>
                <Route path="/edit-published-course/:courseId" element={<EditPublishedCourseLayout />}>
                    <Route index element={<EditPublishedCoursePage />} />
                    <Route path="learning-objectives" element={<EditLearningObjectivesPage />} />
                    <Route path="title-and-description" element={<EditTitleAndDescriptionPage />} />
                    <Route path="module/:moduleId" element={<EditModulePage />} />
                </Route>
                <Route path="/schedule" element={<MainLayout />}>
                    <Route path=":courseId" element={<ScheduleCoursePage />} />
                    <Route path="courses/:courseId" element={<ScheduledCourseDetailPage />} />
                    <Route
                        path="courses/:courseId/live-training/:liveTrainingId"
                        element={<LiveTrainingDetailPage />}
                    />
                </Route>

                <Route path="/organisation" element={<OrganisationLayout />}>
                    <Route path="dashboard" element={<Outlet />}>
                        <Route index element={<OADashboard />} />
                        <Route path=":courseId" element={<Outlet />}>
                            <Route path="view" element={<OACoursePage />} />
                        </Route>
                    </Route>
                    <Route path="overview" element={<OrganisationOverviewPage />} />
                    <Route path="trainers/invite" element={<TrainerInvitePage />} />
                    <Route path="trainers/:id" element={<TrainerDetailsPage />} />
                    <Route path="files/upload" element={<FileUploadPage />} />
                    <Route path="logo" element={<LogoUploadPage />} />
                </Route>

                <Route path="/learner" element={<Outlet />}>
                    <Route path="courses" element={<LearnerLayout />}>
                        <Route index element={<LearnerCoursesPage />} />
                    </Route>
                    <Route path="notifications" element={<LearnerLayout />}>
                        <Route index element={<NotificationsPage />} />
                    </Route>
                    <Route path="enroll/:courseScheduleId" element={<LearnerLayout />}>
                        <Route index element={<EnrollPage />} />
                    </Route>
                    <Route path=":courseScheduleId" element={<LearnerCourseLayout />}>
                        <Route index element={<LearnerCoursePage />} />
                    </Route>
                    <Route path=":courseScheduleId/module/:moduleId" element={<LearnerContentLayout />}>
                        <Route index element={<ModulePage />} />
                    </Route>
                    <Route path=":courseScheduleId/activity/:activityId" element={<LearnerContentLayout />}>
                        <Route index element={<ActivityPage />} />
                    </Route>
                </Route>

                <Route path="/profile" element={<MainLayout />}>
                    <Route index element={<ProfilePage />} />
                </Route>

                <Route path={'/notifications'} element={<MainLayout />}>
                    <Route index element={<NotificationsPage />} />
                </Route>
            </Route>
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="*" element={<Redirect />} />
        </>,
    ),
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <MagnificationProvider>
        <SkeletonTheme duration={3}>
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
        </SkeletonTheme>
    </MagnificationProvider>,
);
