import { BodyRegular, BodySmall } from 'components/Elements';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
// @ts-ignore
import strings from '../../../strings/strings.json';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import FileUpload from 'components/fileUpload/FileUpload';
import { FileMetaData, FileType, STRATEGIES } from 'components/fileUpload/strategies/strategies';
import { FileDocument } from 'models/FileDocument';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import { IconSize } from 'assets/icons/icon-sizes';

type SelectFileFormProps = {
    organisationName: string;
    filesFromProvider: FileDocument[];
    loadingFiles: boolean;
    setFileFromLocal: (newFile: File | null | undefined) => void;
    setFileFromProvider: (file: FileDocument) => void;
    setFileFromLocalMetaData: (newFileMetaData: FileMetaData | undefined) => void;
    closeModal: () => void;
    chosenFileType: FileType;
};

export default function SelectFileForm({
    organisationName,
    filesFromProvider,
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    closeModal,
    chosenFileType,
}: SelectFileFormProps): JSX.Element {
    const [tempFileFromLocal, setTempFileFromLocal] = React.useState<File | undefined>();
    const [localFileValid, setLocalFileValid] = React.useState<boolean>(false);
    const [localFileMetaData, setLocalFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [localFileMetaDataValid, setLocalFileMetaDataValid] = React.useState<boolean>(false);
    const [tempFileFromProvider, setTempFileFromProvider] = React.useState<FileDocument>();
    const [showError, setShowError] = React.useState<boolean>(false);

    const { ref } = useKeyboardTrap(closeModal);

    React.useEffect(() => {
        setShowError(false);
    }, [tempFileFromLocal, tempFileFromProvider]);

    function onSelectProviderFile(file: FileDocument) {
        setTempFileFromProvider(file);
        setTempFileFromLocal(undefined);
    }

    function onUploadFile(file: File | undefined) {
        setTempFileFromProvider(undefined);
        setTempFileFromLocal(file);
    }

    function onClickAddFile() {
        if (!tempFileFromLocal && !tempFileFromProvider) {
            setShowError(true);
        } else {
            if (tempFileFromLocal) {
                if (!localFileValid) return;
                if (!localFileMetaDataValid) return;

                setFileFromLocal(tempFileFromLocal);
                setFileFromLocalMetaData(localFileMetaData);
            } else if (tempFileFromProvider) {
                setFileFromProvider(tempFileFromProvider);
            }

            closeModal();
        }
    }

    const errorDescription =
        filesFromProvider.length > 0
            ? strings.selectFileForm.errorDescriptionIfList
            : strings.selectFileForm.errorDescription;

    return (
        <Form ref={ref}>
            {filesFromProvider.length > 0 && (
                <ProviderFileContent>
                    {organisationName && (
                        <BodyRegular $bold={true}>
                            {strings.selectFileForm.uploadedBy} {organisationName}
                        </BodyRegular>
                    )}

                    <FileList>
                        {filesFromProvider.map((v) => (
                            <FileCard key={v.id}>
                                <FileRadioInput
                                    id={v.id}
                                    type={'radio'}
                                    checked={v.id === tempFileFromProvider?.id}
                                    onChange={() => onSelectProviderFile(v)}
                                    onClick={() => onSelectProviderFile(v)}
                                />
                                <FileLabel htmlFor={v.id}>
                                    <FileIconAndDescription>
                                        <div>{STRATEGIES.find((x) => x.fileType === chosenFileType)?.icon}</div>
                                        <FileDescription>
                                            <BodySmall $bold={true}>{v.filename}</BodySmall>
                                            <BodySmall>{new Date(v.dateUpdated).toLocaleDateString()}</BodySmall>
                                        </FileDescription>
                                    </FileIconAndDescription>
                                </FileLabel>
                            </FileCard>
                        ))}
                    </FileList>
                </ProviderFileContent>
            )}

            <FileUpload
                localFile={tempFileFromLocal}
                setLocalFile={onUploadFile}
                fileMetaData={localFileMetaData}
                setFileMetaData={(newFileMetaData) => {
                    setLocalFileMetaData(newFileMetaData);
                }}
                fileValid={localFileValid}
                setFileValid={(fileValid) => {
                    setLocalFileValid(fileValid);
                }}
                fileMetaDataValid={localFileMetaDataValid}
                setFileMetaDataValid={(fileValid) => {
                    setLocalFileMetaDataValid(fileValid);
                }}
                chosenFileType={chosenFileType}
            />
            {showError && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.selectFileForm.errorTitle}
                    description={errorDescription}
                    maxWidth={'100%'}
                />
            )}
            <AddFileButtonRow>
                <PrimaryButton
                    title={strings.selectFileForm.uploadFileButtonTitle}
                    aria={strings.selectFileForm.uploadFileButtonAria}
                    onClick={onClickAddFile}
                    icon={<UploadIcon colour={theme.cardBackground} size={IconSize.SMALL} />}
                />
            </AddFileButtonRow>
        </Form>
    );
}

const AddFileButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    margin-bottom: ${sizes.spacingMd};
    width: 700px;
    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const FileList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    max-height: 250px;
    overflow-y: auto;
`;

const FileCard = styled.div`
    position: relative;
`;

const FileLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${sizes.spacingSm} ${sizes.spacingMd};

    overflow: hidden;

    border: 1px solid ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusMd};
    width: 100%;
`;

const FileRadioInput = styled.input`
    position: absolute;
    right: ${sizes.spacingMd};
    margin: auto 0;
    top: 0;
    bottom: 0;

    width: 32px;
    height: 32px;

    &:focus ~ ${FileLabel} {
        background-color: ${theme.inputRadioButton.focusBackgroundColour};
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:checked {
        accent-color: ${theme.inputRadioButton.activeRadioColour};
    }
    &:checked ~ ${FileLabel} {
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:hover ~ ${FileLabel} {
        background-color: ${theme.inputRadioButton.hoverBackgroundColour};
    }
`;

export const FileDescription = styled.div`
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    max-width: 500px;
    @media (max-width: ${breakpoints.sm}) {
        max-width: 200px;
    }
`;

export const FileIconAndDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const ProviderFileContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;
