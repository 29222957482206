export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const DOCUMENTS_URL = process.env.REACT_APP_DOCUMENTS_BASE_URL;

export const ACCEPTED_VIDEO_MIME_TYPES = [
    'video/mp4',
    'video/mpeg',
    'video/webm',
    'video/ogg',
    'video/x-msvideo',
    'video/x-matroska',
    'video/x-ms-wmv',
    'video/quicktime',
];

//source: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#common_image_file_types
export const ACCEPTED_IMAGE_MIME_TYPES = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp',
];

export const ACCEPTED_LOGO_MIME_TYPES = ['image/png'];
export const ACCEPTED_AUDIO_MIME_TYPES = ['audio/mpeg'];
export const ACCEPTED_SCORM_MIME_TYPES = ['application/x-zip-compressed'];
export const ACCEPTED_POWERPOINT_MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const numberOfBytesInGigabyte = 1073741824;

export const TEN_MB_ROUNDED = 10000000;
export const MAX_VIDEO_SIZE = numberOfBytesInGigabyte;
export const MAX_IMAGE_SIZE = numberOfBytesInGigabyte * 0.1; //100MB
export const MAX_AUDIO_SIZE = numberOfBytesInGigabyte * 0.1; //100MB
export const MAX_SCORM_SIZE = numberOfBytesInGigabyte * 0.1; //100MB;
export const MAX_POWERPOINT_SIZE = numberOfBytesInGigabyte * 0.1; //100MB;

export function getHeaders(token?: string): HeadersInit {
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
    };
    if (token) {
        headers.Authorization = 'Bearer ' + token;
    }
    return headers;
}

export function isLaterThanNow(secondsSinceUnixEpoch: number): boolean {
    // we multiply secondsSinceUnixEpoch by 1000 to convert to milliseconds
    // this allows comparison with .getTime(), which returns numbers
    // in the unit 'milliseconds since unix epoch'
    return new Date().getTime() >= secondsSinceUnixEpoch * 1000;
}

export function genericServiceError<T>(): ApiResponse<T> {
    return {
        errors: [
            {
                field: 'global',
                code: 'serviceError',
            },
        ],
    };
}

export type ApiResponse<T> = {
    value?: T;
    errors?: APIError[];
};

export type APIError = {
    field: string;
    code: string;
};
