import ExpandableContentComponent from 'components/ExpandableContentComponent';
import { LearnerCourseOverview } from 'models/LearnerCourseOverview';
import LearnerCourseCard from './LearnerCourseCard';
import { CourseList, Border, CourseCardsContainer } from '../sharedStyles/LearnerStyles';

export default function ExpandableCourseList({
    headline,
    courses,
}: {
    headline: string;
    courses: LearnerCourseOverview[];
}) {
    return (
        <>
            <ExpandableContentComponent
                headline={headline}
                closedHeightInPx={0}
                expandedByDefault={false}
                content={
                    <CourseList>
                        <Border />
                        <CourseCardsContainer>
                            {courses
                                .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
                                .map((courseOverview: LearnerCourseOverview) => {
                                    return (
                                        <LearnerCourseCard key={courseOverview.id} courseOverview={courseOverview} />
                                    );
                                })}
                        </CourseCardsContainer>
                    </CourseList>
                }
            />
        </>
    );
}
