import { BodyRegular, BodySmall } from '../Elements';
import styled from 'styled-components';
import { sizes, theme } from '../../theme';

export type RadioFieldOption = {
    value: number | string;
    title: string;
    description?: string;
    aria: string;
    inputProps: object;
    disabled?: boolean;
};

type RadioFieldsProps = {
    options: RadioFieldOption[];
    maxWidth?: string;
    onBlur?: Function;
};

export default function RadioFields({ options, maxWidth, onBlur }: RadioFieldsProps): JSX.Element {
    return (
        <AllOptionsContainer onBlur={!!onBlur ? () => onBlur() : undefined}>
            {options &&
                options.map((option, index) => {
                    const fieldId = `activity-option-${option.value}`;

                    return (
                        <OptionContainer key={index} $maxWidth={maxWidth}>
                            <RadioInput
                                {...option.inputProps}
                                type="radio"
                                value={option.value}
                                id={fieldId}
                                $subHeading={!!option.description}
                                disabled={option.disabled ?? false}
                            />
                            <TextBlock htmlFor={fieldId} aria-label={option.aria}>
                                <BodyRegular $bold>{option.title}</BodyRegular>
                                {option.description && <Description>{option.description}</Description>}
                            </TextBlock>
                        </OptionContainer>
                    );
                })}
        </AllOptionsContainer>
    );
}

const AllOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const OptionContainer = styled.div<{ $maxWidth?: string }>`
    position: relative;
    max-width: ${({ $maxWidth }) => {
        return $maxWidth ? $maxWidth : '640px';
    }};
    align-items: center;
`;

const TextBlock = styled.label`
    display: block;
    width: 100%;
    border: solid 1px ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusLg};
    padding: ${sizes.rem.rg} ${sizes.rem.sm} ${sizes.rem.rg} 3.5rem;
`;

const Description = styled(BodySmall)`
    color: ${theme.textSecondaryColour};
    margin-top: ${sizes.spacingSm};
`;

const RadioInput = styled.input<{ $subHeading?: boolean }>`
    position: absolute;
    left: 0;
    // When there is a subheading, we want it inline with the top line only
    top: ${({ $subHeading }) => {
        return $subHeading ? sizes.rem.rg : '50%';
    }};
    transform: ${({ $subHeading }) => {
        return $subHeading ? null : 'translateY(-50%)';
    }};
    margin-left: ${sizes.spacingMd};
    width: 1.75rem;
    height: 1.75rem;
    &:focus ~ ${TextBlock} {
        background-color: ${theme.inputRadioButton.focusBackgroundColour};
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:checked {
        accent-color: ${theme.inputRadioButton.activeRadioColour};
    }
    &:checked ~ ${TextBlock} {
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:hover ~ ${TextBlock} {
        background-color: ${theme.inputRadioButton.hoverBackgroundColour};
    }
`;
