/*
This component will allow a user to select a file from their local device,
validate that file and then check for any accessibility issues.
It will not upload the file to an API or elsewhere, this must be done separately.
*/
import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';
import { BodyRegular, Headline5 } from '../Elements';
import strings from '../../strings/strings.json';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { FileMetaData, FileType, FileUploadStrategy, STRATEGIES } from './strategies/strategies';
import {
    ACCEPTED_AUDIO_MIME_TYPES,
    ACCEPTED_IMAGE_MIME_TYPES,
    ACCEPTED_SCORM_MIME_TYPES,
    ACCEPTED_VIDEO_MIME_TYPES,
} from '../../lib/_api-helpers';
import ErrorIcon from '../../assets/icons/indicators/ErrorIcon';
import { ErrorMessage } from '../forms/FormComponents';

type FileUploadProps = {
    localFile: File | undefined;
    setLocalFile: (newFile: File | undefined) => void;
    fileMetaData: FileMetaData | undefined;
    setFileMetaData: (newFileMetaData: FileMetaData | undefined) => void;
    fileValid: boolean;
    setFileValid: (fileValid: boolean) => void;
    fileMetaDataValid: boolean;
    setFileMetaDataValid: (fileValid: boolean) => void;
    chosenFileType: FileType | undefined;
};

export default function FileUpload({
    localFile,
    setLocalFile,
    fileMetaData,
    setFileMetaData,
    fileValid,
    setFileValid,
    setFileMetaDataValid,
    chosenFileType,
}: FileUploadProps): JSX.Element {
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const [validationMessage, setValidationMessage] = React.useState<string | null>(null);
    const [detectedStrategy, setDetectedStrategy] = React.useState<FileUploadStrategy | undefined>();

    const chosenStrategy = STRATEGIES.find((x) => x.fileType === chosenFileType);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setLocalFile(e.target.files[0]);
            const newFile = e.target.files[0];
            if (!newFile) return;
            setLocalFile(e.target.files[0]);
        }
    };

    const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            if (!fileInputRef.current) return;
            fileInputRef.current.click();
            event.preventDefault();
        }
    };

    function detectStrategy(fileType: string): FileUploadStrategy | undefined {
        let calculatedStrategy: FileUploadStrategy | undefined;

        if (ACCEPTED_VIDEO_MIME_TYPES.includes(fileType))
            calculatedStrategy = STRATEGIES.find((x) => x.fileType === FileType.VIDEO);
        else if (ACCEPTED_IMAGE_MIME_TYPES.includes(fileType))
            calculatedStrategy = STRATEGIES.find((x) => x.fileType === FileType.IMAGE);
        else if (ACCEPTED_AUDIO_MIME_TYPES.includes(fileType))
            calculatedStrategy = STRATEGIES.find((x) => x.fileType === FileType.AUDIO);
        else if (ACCEPTED_SCORM_MIME_TYPES.includes(fileType))
            calculatedStrategy = STRATEGIES.find((x) => x.fileType === FileType.SCORM);
        return calculatedStrategy;
    }

    React.useEffect(() => {
        setValidationMessage(null);
        setFileValid(false);
        setDetectedStrategy(undefined);

        if (!localFile) {
            return;
        }

        if (!chosenStrategy) return;

        if (!chosenFileType) {
            const detectedStrategy = detectStrategy(localFile.type);

            if (!detectedStrategy) {
                setValidationMessage(strings.fileUpload.invalidFileTypeText);
                return;
            }

            if (localFile.size > detectedStrategy.maxFileSize) {
                setValidationMessage(detectedStrategy.invalidSizeText);
                return;
            }

            setDetectedStrategy(detectedStrategy);
            setFileValid(true);
        } else {
            if (!chosenStrategy.mimeTypes.includes(localFile.type)) {
                setValidationMessage(chosenStrategy.invalidTypeText);
                return;
            }

            if (localFile.size > chosenStrategy.maxFileSize) {
                setValidationMessage(chosenStrategy.invalidSizeText);
                return;
            }

            setFileValid(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFile, chosenFileType, setFileValid]);

    return (
        <FileUploadContainer>
            <FileNameRow>
                <FileName>{localFile?.name ?? strings.fileUpload.browseFiles}</FileName>
                <ButtonContainer>
                    <FileUploadButton
                        htmlFor="upload-input"
                        tabIndex={0}
                        onKeyDown={handleButtonKeyDown}
                        aria-label={strings.fileUpload.selectFileButtonAria}
                    >
                        {strings.fileUpload.selectFileButtonTitle}
                    </FileUploadButton>
                </ButtonContainer>
                <HiddenFileInput id="upload-input" type="file" ref={fileInputRef} onChange={handleFileChange} />
            </FileNameRow>
            {!localFile && <BodyRegular>{chosenStrategy?.validationText}</BodyRegular>}
            {validationMessage && (
                <ValidationContainer>
                    <div>
                        <ErrorIcon />
                    </div>
                    <FormattedErrorMessage>{validationMessage}</FormattedErrorMessage>
                </ValidationContainer>
            )}
            {localFile &&
                detectedStrategy &&
                detectedStrategy.strategy(localFile, fileValid, fileMetaData, setFileMetaData, setFileMetaDataValid)}
            {localFile &&
                !detectedStrategy &&
                chosenStrategy?.strategy(localFile, fileValid, fileMetaData, setFileMetaData, setFileMetaDataValid)}
        </FileUploadContainer>
    );
}

const FileUploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.cardSecondaryBackgroundColour};
    padding: ${sizes.spacingMd};
    border-radius: ${sizes.borderRadiusLg};
    gap: ${sizes.spacingSm};
    min-height: 115px;
`;

const FileNameRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${sizes.spacingLg};
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: unset;
        gap: ${sizes.spacingMd};
    }
`;

const FileUploadButton = styled.label`
    font-family: ${theme.fontFamilyMain};
    font-size: ${FONTSIZE.BodyRegular};
    font-weight: bold;
    white-space: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${sizes.spacingSm};
    cursor: pointer;
    height: 48px;
    padding: 0 ${sizes.spacingLg} 3px ${sizes.spacingLg};
    border-radius: 24px;
    letter-spacing: 0;
    text-align: center;
    border: 2px solid ${theme.secondaryButtonBorderColour} !important;
    color: ${theme.secondaryButtonTextColour};
    background-color: ${theme.secondaryButtonBackgroundColour};
    box-sizing: border-box;
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border: 2px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
`;

const ValidationContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;

    @media (max-width: ${breakpoints.sm}) {
        height: unset;
    }
`;

const FileName = styled(Headline5)`
    word-wrap: break-word;
`;

const FormattedErrorMessage = styled(ErrorMessage)`
    white-space: pre-wrap;
`;

const HiddenFileInput = styled.input`
    display: none;
`;
