import ScheduledCourseDetails from '../../../components/courseSchedule/ScheduledCourseDetails';
import strings from '../../../strings/strings.json';
import React from 'react';
import { AuthContext } from 'contextProviders/AuthContext';
import { RoleType } from 'models/User';

export default function ScheduledCourseDetailPage(): JSX.Element {
    const { userData } = React.useContext(AuthContext);

    return (
        <ScheduledCourseDetails
            backButtonDetails={{
                title: strings.OACoursePage.backToCoursesButtonTitle,
                aria: strings.OACoursePage.backToCoursesButtonTitleAria,
                href:
                    userData.activeRole === RoleType.ORGANISATION_ADMIN
                        ? '/organisation/courses'
                        : '/trainer/scheduled-courses',
                navigateState:
                    userData.activeRole === RoleType.ORGANISATION_ADMIN
                        ? { state: { activeTabId: 'scheduledCourses' } }
                        : null,
            }}
        />
    );
}
