import strings from '../../../strings/strings.json';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import * as React from 'react';
import { AuthContext } from '../../../contextProviders/AuthContext';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import { BodyLarge, Headline2 } from '../../../components/Elements';
import { getFriendlyRoleName, ROLE_DETAILS, RoleType } from '../../../models/User';
import RadioFields from '../../../components/forms/RadioFields';
import useNeveForm from '../../../components/forms/NeveForm';
import { ErrorMessage } from '../../../components/forms/FormComponents';
import SecondaryButton from '../../../components/buttons/SecondaryButton';

type RoleInput = {
    role: number | null;
};
export default function ChangeRoleButton(): JSX.Element {
    const { userData, setNewActiveRole } = React.useContext(AuthContext);

    const [showRoleSelectOverlay, setShowRoleSelectOverlay] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);

    const rolesWithLearner: RoleType[] = [...userData.roles];

    const { register, handleSubmit, reset } = useNeveForm<RoleInput>({
        role: null,
    });

    function openModal() {
        setShowRoleSelectOverlay(true);
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        reset();
        setError(null);
        setShowRoleSelectOverlay(false);
        document.body.style.overflow = 'visible';
    }

    const submitRoleChange = (formData: RoleInput) => {
        if (formData.role === null) {
            setError(strings.changeRoleOverlay.noRoleSelectedError);
            document.body.style.overflow = 'visible';
            return;
        }

        const newRole = rolesWithLearner[formData.role];

        setNewActiveRole(newRole);
        setError(null);
        reset();
        setShowRoleSelectOverlay(false);
        document.body.style.overflow = 'visible';
    };

    return (
        <>
            {userData.roles.length > 0 && (
                <SecondaryButton
                    title={strings.profilePage.changeRoleButtonTitle}
                    aria={strings.profilePage.changeRoleButtonAria}
                    onClick={() => openModal()}
                />
            )}
            {showRoleSelectOverlay && (
                <Modal onSubmit={handleSubmit(submitRoleChange)}>
                    <ModalHeader>{strings.changeRoleOverlay.headerText}</ModalHeader>
                    <BodyLarge>
                        {strings.changeRoleOverlay.subtitleText}{' '}
                        {userData.activeRole ? getFriendlyRoleName(userData.activeRole, true) : null}
                    </BodyLarge>
                    <RadioFields
                        options={rolesWithLearner.map((role, index) => {
                            const roleDetails =
                                ROLE_DETAILS.find((roleDetail) => roleDetail.name === role) ?? ROLE_DETAILS[0];

                            return {
                                value: index,
                                title: roleDetails.friendlyName,
                                description: roleDetails.description,
                                aria: role,
                                inputProps: register('role'),
                                name: 'role',
                            };
                        })}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <ButtonContainer>
                        <SecondaryButton
                            title={strings.changeRoleOverlay.backButtonTitle}
                            aria={strings.changeRoleOverlay.backButtonAria}
                            onClick={() => closeModal()}
                        />
                        <PrimaryButton
                            title={strings.changeRoleOverlay.submitButtonTitle}
                            aria={strings.changeRoleOverlay.submitButtonAria}
                            type="submit"
                        />
                    </ButtonContainer>
                </Modal>
            )}
        </>
    );
}

const Modal = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${sizes.spacingLg};
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.cardBackground};
    z-index: 10;
    padding: ${sizes.spacingRg};
    overflow-y: auto;
    @media (max-width: ${breakpoints.sm}) {
        top: 100px;
    }
`;

const ModalHeader = styled(Headline2)`
    padding-top: ${sizes.spacingLg};
    text-align: center;
    @media (max-width: ${breakpoints.sm}) {
        padding-top: ${sizes.spacingSm};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    margin-bottom: 100px;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
        margin-bottom: 150px;
    }
`;
