import {
    BodyLarge,
    BodyRegular,
    ExternalSecondaryLinkButton,
    Flex,
    FlexAlignCenter,
    FlexCol,
    Headline2,
    Headline4,
    LinkWrapper,
} from 'components/Elements';
import useGetRequest from 'hooks/useGetRequest';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme, sizes, breakpoints, FONTSIZE } from 'theme';
import PrimaryButton, { PrimaryButtonColour } from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import CheckIcon from 'assets/icons/form-icons/CheckIcon';
import { LearnerLearningObjective } from 'models/LearnerCourse';
import { PublicCourseOverview, PublicModuleOverviews } from 'models/PublicCourseOverview';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import strings from '../../strings/strings.json';
import { IconSize } from 'assets/icons/icon-sizes';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import ScheduleIcon from 'assets/icons/navigation/ScheduleIcon';
import { ErrorMessage } from 'components/forms/FormComponents';
import React from 'react';
import { CopyLinkModal, CopyLinkModalProps, CopyLinkModalState } from 'components/modals/CopyLinkModal';
import ErrorIcon from 'assets/icons/indicators/ErrorIcon';
import NextIcon from 'assets/icons/controls/NextIcon';
import { AuthContext } from 'contextProviders/AuthContext';
import Alert from 'components/alert/Alert';
import ProgressBar from '../../components/progressBar/ProgressBar';
import WarningIcon from '../../assets/icons/indicators/WarningIcon';
import { LearnerCardBase } from 'pages/learner/sharedStyles/LearnerStyles';
import { ImageCircle } from 'components/ImageCircle';
import useWindowWidth from 'hooks/useWindowWidth';

export default function PublicCoursePage(): JSX.Element {
    const { isMobileWidth } = useWindowWidth();
    const { id } = useParams();
    const { login, isAuthenticated } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const {
        data: course,
        loading,
        errors,
        called,
    } = useGetRequest<PublicCourseOverview>(`public/course/${id}`, undefined, true);

    const [enrollAlertOpen, setEnrollAlertOpen] = React.useState<boolean>(false);

    function closeCancelAlert() {
        setEnrollAlertOpen(false);
    }

    const [copyLinkState, setCopyLinkState] = React.useState<CopyLinkModalState>({
        modalProps: {} as CopyLinkModalProps,
        modalOpen: false,
    });

    function openCopyModal(url: string) {
        setCopyLinkState({
            modalOpen: true,
            modalProps: {
                title: `${strings.copyLinkModal.shareLink.title}`,
                inputValue: url,
                primaryButtonTitle: `${strings.copyLinkModal.shareLink.primaryButtonTitle}`,
                primaryButtonAria: `${strings.copyLinkModal.shareLink.primaryButtonAria}`,
                closeButtonAria: `${strings.copyLinkModal.shareLink.closeButtonAria}`,
                onClickCloseButton: () => {},
            },
        });
    }

    function closeCopyModal() {
        setCopyLinkState({
            modalOpen: false,
            modalProps: {} as CopyLinkModalProps,
        });
    }

    if (!loading && !called) return <></>;

    if (loading) return <></>;

    if (!course || (errors && errors.length > 0)) {
        return (
            <ErrorContainer>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.publicCoursePage.errors.courseNotFoundError.title}
                    description={strings.publicCoursePage.errors.courseNotFoundError.description}
                    maxWidth="1240"
                />
            </ErrorContainer>
        );
    }

    const startDate = new Date(course.startDate);
    const formattedStartDate = startDate.toLocaleDateString('en-UK', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });

    let endDate;
    let formattedEndDate;
    let courseEnded = false;

    if (course.endDate) {
        endDate = new Date(course.endDate);
        formattedEndDate = endDate.toLocaleDateString('en-UK', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
        courseEnded = endDate < new Date();
    }

    const learningObjectives: LearnerLearningObjective[] = course.learningObjectives;
    const modules: PublicModuleOverviews[] = course.modules;

    const profileImageFilepath = course.trainer.profileImage ? `${DOCUMENTS_URL}/${course.trainer.profileImage}` : '';

    const courseFull = course.currentLearners >= course.maximumLearners;

    const renderJoinCourseButton = () => {
        return (
            <PrimaryButton
                title={strings.publicCoursePage.joinCourseButton.title}
                aria={strings.publicCoursePage.joinCourseButton.aria}
                disabled={course.cancelled || courseFull || courseEnded}
                onClick={isAuthenticated ? () => setEnrollAlertOpen(true) : () => login(`/learner/enroll/${id}`)}
            />
        );
    };

    return (
        <PublicCoursePageContainer>
            <HeaderBackground>
                <AppWidthContainer>
                    <HeaderContainer>
                        <TitleAndLogoContainer>
                            <TitleTextGrid>{course.title}</TitleTextGrid>
                            {course.organisation.logoS3Filepath && (
                                <LogoGrid>
                                    <LinkWrapper href={`/public/organisation/${course.organisation.publicName}`}>
                                        <LogoContainer>
                                            <Image
                                                src={`${DOCUMENTS_URL}/${course.organisation.logoS3Filepath}`}
                                                alt={strings.organisationOverviewPage.logoAltText}
                                            />
                                        </LogoContainer>
                                    </LinkWrapper>
                                </LogoGrid>
                            )}
                            <HeaderButtonContainer>
                                {renderJoinCourseButton()}
                                <SecondaryButton
                                    title={strings.publicCoursePage.shareCourseButton.title}
                                    aria={strings.publicCoursePage.shareCourseButton.aria}
                                    onClick={() => {
                                        const copyLinkUrl = `${window.location.origin}/public/course/${course.scheduledCourseId}`;
                                        openCopyModal(copyLinkUrl);
                                    }}
                                    disabled={courseFull}
                                />
                                {copyLinkState.modalOpen && (
                                    <CopyLinkModal {...copyLinkState.modalProps} onClickCloseButton={closeCopyModal} />
                                )}
                                {enrollAlertOpen && (
                                    <Alert
                                        title={strings.publicCoursePage.joinCourseConfirmation.title}
                                        alertText={`${strings.publicCoursePage.joinCourseConfirmation.alertText} ${course.title}`}
                                        buttonText={strings.publicCoursePage.joinCourseConfirmation.buttonText}
                                        ariaButtonText={`${strings.publicCoursePage.joinCourseConfirmation.buttonAria} ${course.title}`}
                                        buttonColour={PrimaryButtonColour.DEFAULT}
                                        onSubmit={() => navigate(`/learner/enroll/${id}`)}
                                        closeAlert={closeCancelAlert}
                                    />
                                )}
                            </HeaderButtonContainer>
                        </TitleAndLogoContainer>
                        {course.cancelled && (
                            <ErrorMessageContainer>
                                <ErrorIcon />
                                <ErrorMessage $bold>
                                    {strings.publicCoursePage.errors.courseCancelledError.description}
                                </ErrorMessage>
                            </ErrorMessageContainer>
                        )}
                        {courseFull && (
                            <ErrorMessageContainer>
                                <WarningIcon />
                                <ErrorMessage $bold>
                                    {strings.publicCoursePage.errors.courseFullError.description}
                                </ErrorMessage>
                            </ErrorMessageContainer>
                        )}
                    </HeaderContainer>
                </AppWidthContainer>
            </HeaderBackground>

            <MainContainer>
                {!course.cancelled && (
                    <>
                        <DateContentBlock>
                            <DateContainerCard>
                                <DateAndIconContainer>
                                    <ScheduleIcon size={isMobileWidth ? IconSize.LARGE : IconSize.XLARGE} />
                                    <FlexCol>
                                        <BodyLarge>{strings.publicCoursePage.startDate}</BodyLarge>
                                        <LargeDateText $bold>{formattedStartDate}</LargeDateText>
                                    </FlexCol>
                                </DateAndIconContainer>
                                {endDate && (
                                    <DateAndIconContainer>
                                        <ScheduleIcon size={isMobileWidth ? IconSize.LARGE : IconSize.XLARGE} />
                                        <FlexCol>
                                            <BodyLarge>{strings.publicCoursePage.endDate}</BodyLarge>
                                            <LargeDateText $bold>{formattedEndDate}</LargeDateText>
                                        </FlexCol>
                                    </DateAndIconContainer>
                                )}
                            </DateContainerCard>
                            <ProgressBarContainer>
                                <ProgressBar
                                    label={strings.publicCoursePage.progressBarLabel}
                                    current={course.currentLearners}
                                    max={course.maximumLearners}
                                    reverseFractionAmount
                                />
                            </ProgressBarContainer>
                        </DateContentBlock>
                        <Border />
                    </>
                )}

                <ContentBlock>
                    <Headline4>{strings.publicCoursePage.trainerHeadline}</Headline4>
                    <TrainerAndOrgContainer>
                        <TrainerContainer>
                            <TrainerImageAndName>
                                <div>
                                    <ImageCircle $imageurl={profileImageFilepath} />
                                </div>
                                <div>
                                    <TrainerNameBlueText
                                        $bold
                                    >{`${course.trainer.firstName} ${course.trainer.lastName}`}</TrainerNameBlueText>
                                </div>
                            </TrainerImageAndName>
                            <BodyRegular>{course.trainer.bio}</BodyRegular>
                        </TrainerContainer>
                        <TrainerNameBlueText $bold>{course.organisation.name}</TrainerNameBlueText>
                        {course.organisation.description && (
                            <BodyRegular>{course.organisation.description}</BodyRegular>
                        )}
                        <SecondaryButton
                            title={strings.publicCoursePage.organisationSection.viewOrganisationButton.title}
                            aria={strings.publicCoursePage.organisationSection.viewOrganisationButton.aria}
                            onClick={() => {
                                navigate(`/public/organisation/${course.organisation.publicName}`);
                            }}
                        />
                    </TrainerAndOrgContainer>
                </ContentBlock>
                <Border />

                {course.description && (
                    <>
                        <ContentBlock>
                            <Headline4>{strings.publicCoursePage.courseDescriptionHeadline}</Headline4>
                            <ContentBodyText>{course.description}</ContentBodyText>
                        </ContentBlock>
                        <Border />
                    </>
                )}

                <ContentBlock>
                    <Headline4>{strings.publicCoursePage.learningObjectivesHeadline}</Headline4>
                    <LearningObjectiveContainer>
                        {learningObjectives
                            .sort((a, b) => a.order - b.order)
                            .map((x) => (
                                <LearningObjective key={x.id}>
                                    <LearningObjectiveIconContainer>
                                        <CheckIcon />
                                    </LearningObjectiveIconContainer>
                                    <BodyRegular>{x.description}</BodyRegular>
                                </LearningObjective>
                            ))}
                    </LearningObjectiveContainer>
                </ContentBlock>
                <Border />

                <ContentBlock>
                    <Headline4>{strings.publicCoursePage.modulesHeadline}</Headline4>
                    <ModuleContainer>
                        {modules
                            .sort((a, b) => a.order - b.order)
                            .map((module) => (
                                <ModuleCard key={module.order}>
                                    <ModuleNumberText $bold>Module {module.order}:</ModuleNumberText>{' '}
                                    <BodyLarge>{module.title}</BodyLarge>
                                </ModuleCard>
                            ))}
                    </ModuleContainer>
                </ContentBlock>
                <Border />

                <PageBottomButtonContainer>
                    {renderJoinCourseButton()}
                    <StyledLinkButton
                        aria-label={strings.publicCoursePage.neveInfoSection.neveLearningLinkAria}
                        href="https://www.nevelearning.co.uk/"
                        target="_blank"
                    >
                        {strings.publicCoursePage.neveInfoSection.neveLearningLink}
                        <NextIconContainer>
                            <NextIcon size={IconSize.LARGE} colour={`${theme.footerBackground}`} />
                        </NextIconContainer>
                    </StyledLinkButton>
                </PageBottomButtonContainer>
            </MainContainer>
        </PublicCoursePageContainer>
    );
}

const ProgressBarContainer = styled(LearnerCardBase)`
    width: min(100%, 500px);
    padding: ${sizes.spacingMd};
`;

const PublicCoursePageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background: ${theme.learnerCourseBackgroundColour};
`;

const HeaderBackground = styled.header`
    width: 100%;
    padding: ${sizes.spacingLg} 0;
    background: white;
    border-bottom: 1px solid ${theme.textColour};
`;

const AppWidthContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: auto;
`;

const HeaderContainer = styled(FlexCol)`
    width: 100%;
    height: 100%;
    min-height: 200px;
    justify-content: center;
    gap: ${sizes.spacingLg};
`;

const TitleAndLogoContainer = styled.div`
    display: grid;
    gap: ${sizes.spacingMd};
    grid-template-areas:
        'title logo'
        'buttons buttons';
    @media (max-width: ${breakpoints.lg}) {
        grid-template-areas:
            'title title'
            'buttons logo';
        align-items: center;
    }
    @media (max-width: ${breakpoints.sm}) {
        grid-template-areas:
            'title '
            'logo'
            'buttons ';
        align-items: unset;
    }
`;

const TitleTextGrid = styled(Headline2)`
    grid-area: title;
`;

const LogoGrid = styled.div`
    padding-top: ${sizes.spacingMd};
    height: fit-content;
    grid-area: logo;
    display: grid;
    justify-content: flex-end;
    @media (max-width: ${breakpoints.lg}) {
        padding-top: unset;
    }
    @media (max-width: ${breakpoints.sm}) {
        justify-content: flex-start;
    }
`;

const LogoContainer = styled(LearnerCardBase)`
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    max-width: max-content;
    max-height: max-content;
    padding: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.md}) {
        align-self: flex-start;
    }
`;

const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;

const HeaderButtonContainer = styled.div`
    grid-area: buttons;
    display: flex;
    gap: ${sizes.spacingRg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const Border = styled.div`
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
`;

const ContentBodyText = styled(BodyLarge)`
    padding: ${sizes.spacingMd};
    width: 100%;
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-right: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-bottom: 3px solid var(--neutral-05, ${theme.cardBorder});
    border-left: 1px solid var(--neutral-05, ${theme.cardBorder});
    white-space: pre-wrap;
`;

const MainContainer = styled.main`
    margin: 0 auto;
    padding: 0 ${sizes.edgePadding};
    width: 100%;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    display: flex;
    flex-direction: column;
    background: inherit;
`;

const ErrorContainer = styled(MainContainer)`
    margin: ${sizes.spacingLg} 0;
`;

const DateContentBlock = styled(FlexAlignCenter)`
    padding: ${sizes.spacingLg} 0;
    min-height: 150px;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: unset;
        gap: ${sizes.spacingLg};
    }
`;

const DateContainerCard = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
    width: min(100%, 500px);
    display: flex;
    justify-content: space-between;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
`;

const DateAndIconContainer = styled(FlexAlignCenter)`
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingSm};
    }
`;

const LargeDateText = styled(BodyLarge)`
    font-size: ${FONTSIZE.Headline4};
    overflow-wrap: unset;
    white-space: nowrap;
    word-break: keep-all;
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${FONTSIZE.Headline5};
    }
`;

const ContentBlock = styled(FlexCol)`
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingLg} 0;
`;

const TrainerContainer = styled(FlexCol)`
    padding-bottom: ${sizes.spacingMd};
    width: 100%;
    gap: ${sizes.spacingSm};
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
`;

const TrainerImageAndName = styled(FlexAlignCenter)`
    gap: ${sizes.spacingMd};
`;

const TrainerNameBlueText = styled(BodyLarge)`
    font-size: ${FONTSIZE.Headline6};
    color: ${theme.footerBackground};
`;

const TrainerAndOrgContainer = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${sizes.spacingMd};
    word-break: break-word;
`;

const LearningObjectiveContainer = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;

const LearningObjective = styled(FlexAlignCenter)`
    gap: ${sizes.spacingMd};
`;

const LearningObjectiveIconContainer = styled.div`
    padding: ${sizes.spacingMd};
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: ${theme.notificationSuccessBackground};
`;

const ModuleContainer = styled(FlexCol)`
    width: 100%;
    gap: ${sizes.spacingMd};
`;

const ModuleCard = styled(Flex)`
    width: 100%;
    gap: ${sizes.spacingSm};
    white-space: pre-wrap;
    background: ${theme.cardBackground};
    padding: ${sizes.spacingMd};
    border: 2px solid ${theme.primaryButtonBackgroundColour};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
`;

const ModuleNumberText = styled(BodyLarge)`
    min-width: 100px;
`;

const PageBottomButtonContainer = styled.div`
    padding: ${sizes.spacingLg} 0 ${sizes.spacingXl} 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingLg};
        align-items: flex-start;
    }
`;

const NextIconContainer = styled(FlexAlignCenter)`
    min-width: 40px;
`;

const StyledLinkButton = styled(ExternalSecondaryLinkButton)`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
    color: ${theme.footerBackground};
    font-size: ${FONTSIZE.Headline5};
    @media (max-width: ${breakpoints.sm}) {
        font-size: ${FONTSIZE.BodyLarge};
        gap: unset;
    }
`;
