import strings from '../../../../strings/strings.json';
import useNeveForm from 'components/forms/NeveForm';
import { TextAreaField } from 'components/forms/TextAreaField';
import { Headline4, Headline5 } from 'components/Elements';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { TaskActivity } from 'models/inputModels/TaskActivity';
import {
    createErrorMessage,
    fieldErrorCodes,
    createGlobalErrorFromAPIError,
    requiredFieldErrorMessage,
} from 'shared/error-messages';
import React from 'react';
import { FileDocument } from 'models/FileDocument';
import { FileMetaData } from 'components/fileUpload/strategies/strategies';
import useUploadRequest from 'hooks/useUploadRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { ErrorMessage } from 'components/forms/FormComponents';
import ActivityWizardButtonRow from '../ActivityWizardButtonRow';
import { ImageCard } from '../FileCard';
import { ImageSelectionComponent } from '../FileSelectionComponent';
import styled from 'styled-components';
import { sizes } from 'theme';
import ResourcesList from '../ResourcesList';
import { LearningResourcesFormModal } from '../LearningResourcesFormModal';
import { ResourcesInput } from '../ResourcesForm';
import { Resource } from 'models/Resources';
import { CreateTaskWithTextAndImageRequest } from 'models/inputModels/CreateTaskRequest';
import { Activity } from 'models/Activity';

type TaskContentInput = {
    content: string;
};

export default function TaskContentFormForTextAndImage(props: StepperFormProps<TaskActivity>): JSX.Element {
    const { complete, newObject, setNewObject } = props;

    const context = React.useContext(EditCourseContext);

    const [errorsState, setErrors] = React.useState<string[]>([]);
    const [imageFromProvider, setImageFromProvider] = React.useState<FileDocument>();
    const [imageFileMetaData, setImageFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [linkModalOpen, setLinkModalOpen] = React.useState(false);
    const [resources, setResources] = React.useState<ResourcesInput[]>([]);
    const [disableCompleteButton, setDisableCompleteButton] = React.useState<boolean>(false);

    const selectImageButtonRef = React.useRef<HTMLButtonElement>(null);
    const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useNeveForm<TaskContentInput>({
        content: newObject.content ?? '',
    });

    React.useEffect(() => {
        const resourcesInputs: ResourcesInput[] = newObject.resources.map((r) => {
            const resource: ResourcesInput = { id: r.id ?? '', title: r.title, url: r.url };
            return resource;
        });

        setResources(resourcesInputs);
    }, []); // eslint-disable-line

    const content = watch('content');

    React.useEffect(() => {
        setNewObject({
            ...newObject,
            content: content,
        });
    }, [content]); //eslint-disable-line

    React.useEffect(() => {
        if (resources.length <= 0) {
            return;
        }

        setNewObject({
            ...newObject,
            resources: resources.map((r) => {
                const resource: Resource = { id: r.id ?? '', title: r.title, url: r.url };
                return resource;
            }),
        });
    }, [resources]); //eslint-disable-line

    const { upload: uploadImage, uploading: uploadingImage } = useUploadRequest();

    const { modifyData: createTask, loading: creatingTask } = useModifyRequest(`activities/task/textimage`, 'POST');

    function updateErrors(apiErrors: APIError[]): void {
        let newErrors: string[] = [];

        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;

            if (field === 'global') {
                newErrors.push(createGlobalErrorFromAPIError(code));
            } else {
                newErrors.push(createErrorMessage(field, code));
            }
        });

        setErrors(newErrors);
    }

    async function submit() {
        let existingImageId: string | null = null;
        let imageFilePath: string | null = null;
        let imageFileName: string | null = null;

        setDisableCompleteButton(true);

        if (imageFromProvider) {
            existingImageId = imageFromProvider.id;
        } else if (imageFromLocal) {
            const uploadResponse = await uploadImage(imageFromLocal);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                return;
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                return;
            }

            imageFilePath = uploadResponse.value.s3Key;
            imageFileName = uploadResponse.value.filename;
        }

        const request: CreateTaskWithTextAndImageRequest = {
            title: newObject.title,
            description: newObject.description,
            content: newObject.content,
            durationInMins: newObject.durationInMins,
            moduleId: newObject.moduleId,
            resources: resources,
            existingImageId: existingImageId,
            imageFilename: imageFileName,
            imageFilepath: imageFilePath,
            imageAltText: imageFileMetaData?.imageAltText ?? null,
        };

        const response = await createTask(request);
        const { errors, value: activity } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            setDisableCompleteButton(false);
            return;
        }

        const activityId = (activity as Activity).id;
        const buildMethod = (activity as Activity).buildMethod;
        context.setDataToShowGenerateContentModal({ activityId, buildMethod });
        await context.refetchCourse();
        complete && complete();
    }

    const setImageFromLocal = (newFile: File | null | undefined) => {
        setNewObject({
            ...newObject,
            imageFile: newFile ?? null,
        });
        setImageFromProvider(undefined);
    };

    const setImageFromProviderFunc = (image: FileDocument) => {
        setNewObject({
            ...newObject,
            imageFile: null,
        });
        setImageFromProvider(image);
    };

    const removeImage = () => {
        setNewObject({
            ...newObject,
            imageFile: null,
        });
        setImageFromProvider(undefined);
        setImageFileMetaData(undefined);
        selectImageButtonRef.current?.focus();
    };

    const imageFromLocal = newObject.imageFile;

    let imageName: string;
    if (imageFromLocal) imageName = imageFromLocal.name;
    else if (imageFromProvider) imageName = imageFromProvider.filename;
    else imageName = strings.fileUpload.browseFiles;

    const imageSelected = imageFromLocal || imageFromProvider;

    let uploadDate: Date = imageFromProvider ? imageFromProvider.dateUpdated : new Date();

    function addResource(newResource: ResourcesInput) {
        setResources([...resources, newResource]);
        addLinkButtonRef.current?.focus();
    }

    const deleteResource = (id: string | null, title: string, url: string, _: boolean) => {
        setResources(resources.filter((resource) => !(resource.title === title && resource.url === url)));
        addLinkButtonRef.current?.focus();
    };

    const closeLinksModal = () => {
        setLinkModalOpen(false);
        addLinkButtonRef.current?.focus();
    };

    return (
        <PaddedForm onSubmit={handleSubmit(submit)}>
            <Headline4>{strings.taskContentForm.buildMethod.textAndImage}</Headline4>
            <InputsSection>
                <TextAreaField
                    maxLength={2000}
                    charactersLeft={2000 - content.length}
                    fieldName={strings.taskContentForm.contentLabel}
                    aria={strings.taskContentForm.contentAria}
                    errorMessage={errors.content?.message}
                    inputProps={register('content', {
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(strings.taskContentForm.contentLabel),
                        },
                        maxLength: {
                            value: 2000,
                            message: createErrorMessage(
                                strings.taskContentForm.contentLabel,
                                fieldErrorCodes.maxLength,
                            ),
                        },
                    })}
                    required
                    autoFocus
                />
                <ImageSection>
                    <Headline5>{strings.learningMaterialsForm.addImageTitle}</Headline5>
                    {imageSelected && (
                        <ImageCard
                            fileName={imageName}
                            uploadDate={uploadDate}
                            removeFile={removeImage}
                            disabled={uploadingImage || creatingTask}
                        />
                    )}
                    {!imageSelected && (
                        <ImageSelectionComponent
                            setFileFromLocal={setImageFromLocal}
                            setFileFromProvider={setImageFromProviderFunc}
                            setFileFromLocalMetaData={setImageFileMetaData}
                            disableButton={uploadingImage || creatingTask}
                            selectFileButtonRef={selectImageButtonRef}
                        />
                    )}
                </ImageSection>
            </InputsSection>
            <LinksSection>
                <Headline5>{strings.resourcesList.title}</Headline5>
                <ResourcesList
                    resources={resources}
                    deleteResource={deleteResource}
                    toggleModalOpen={() => setLinkModalOpen(!linkModalOpen)}
                    disabled={uploadingImage || creatingTask}
                    addLinkButtonRef={addLinkButtonRef}
                />
            </LinksSection>
            <ActivityWizardButtonRow
                submitButtonTitle={strings.taskContentForm.submitButton}
                submitButtonAria={strings.taskContentForm.submitButtonAria}
                submitting={disableCompleteButton}
                showPreviousButton={false}
            />
            {errorsState.length > 0 &&
                errorsState.map((error, index) => (
                    <ErrorMessage id={`error-message-${index}`} key={index}>
                        {error}
                    </ErrorMessage>
                ))}
            {linkModalOpen && <LearningResourcesFormModal closeModal={closeLinksModal} addResource={addResource} />}
        </PaddedForm>
    );
}

const LinksSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const InputsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const ImageSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const PaddedForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;
