import * as React from 'react';
import styled from 'styled-components';
import ActivityCard from './ActivityCard';
import strings from '../../../strings/strings.json';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { BodyLarge, BodyRegular } from '../../../components/Elements';
import { Module } from '../../../models/Module';
import { breakpoints, FONTSIZE, sizes, theme } from '../../../theme';
import { Activity, ActivityType } from 'models/Activity';
import useModifyRequest from '../../../hooks/useModifyRequest';
import { APIError } from '../../../lib/_api-helpers';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { reorder } from '../../../lib/reorderList';
import EditIcon from '../../../assets/icons/controls/EditIcon';
import { AccordionPanelHeaderTitle } from 'components/accordion/AccordionPanelTitle';
import { AccordionPanelToggle } from 'components/accordion/AccordionPanelToggle';
import { animated, useSpring } from '@react-spring/web';
import AddIcon from '../../../assets/icons/controls/AddIcon';
import AccessibilityNotifier from '../../../components/notifiers/AccessibilityNotifier';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import { useHeight } from '../../../hooks/useHeight';
import WarningIcon from 'assets/icons/indicators/WarningIcon';
import { ErrorMessage } from 'components/forms/FormComponents';
import GenerateModuleQuestionnaireForm from './questionnaire/GenerateModuleQuestionnaireForm';
import Modal from 'components/modals/Modal';
import QuestionnaireIcon from 'assets/icons/pathway/QuestionnaireIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import useWindowWidth from 'hooks/useWindowWidth';

type ModuleAccordionProps = {
    module: Module;
    editButtonClick?: (moduleId: string) => void;
    activeModuleId?: string;
    dragHandleProps?: any;
    activitiesEditable?: boolean;
    activitiesSortable?: boolean;
    moduleEditable?: boolean;
    addActivityClick?: (moduleId: string) => void;
    editActivityClick?: (moduleId: string, activityId: string) => void;
    showIncompleteStepsWarning?: boolean;
};

export default function ModuleAccordion({
    module,
    editButtonClick,
    activeModuleId,
    dragHandleProps,
    activitiesEditable = false,
    activitiesSortable = false,
    moduleEditable = false,
    addActivityClick,
    editActivityClick,
    showIncompleteStepsWarning,
}: ModuleAccordionProps): JSX.Element {
    const [errors, setErrors] = React.useState<APIError[]>([]);
    const [reorderSaved, setReorderSaved] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [localActivities, setLocalActivities] = React.useState<Activity[]>(module.activities);
    const [showGenerateQuestionnaireModal, setShowGenerateQuestionnaireModal] = React.useState<boolean>(false);
    const { isMobileWidth } = useWindowWidth();

    const [heightRef, height] = useHeight();

    const { height: animatedHeight } = useSpring({
        from: { height: 0 },
        to: {
            height: isOpen ? height : 0,
        },
    });

    const { modifyData: reorderActivities, loading: reorderLoading } = useModifyRequest(
        `modules/${module.id}/reorder-activities`,
        'PUT',
    );

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    React.useEffect(() => {
        if (activeModuleId && activeModuleId === module.id) {
            setIsOpen(true);
            scrollToBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeModuleId]);

    React.useEffect(() => {
        setLocalActivities(
            module.activities.sort((activityOne, activityTwo) => {
                return activityOne.order > activityTwo.order ? 1 : -1;
            }),
        );
    }, [module]);

    const showVariationTip = React.useMemo(() => {
        if (!module.activities) return null;
        if (module.activities.length < 3) return null;
        if (module.activities.length === 6) return null;

        const numberOfTasks = module.activities.filter((x) => x.type === ActivityType.TASK).length;
        const numberOfLiveTrainingMeetings = module.activities.filter(
            (x) => x.type === ActivityType.LIVE_TRAINING,
        ).length;
        const numberOfQuestionnaires = module.activities.filter((x) => x.type === ActivityType.QUESTIONNAIRE).length;

        if (numberOfTasks >= 3 && numberOfQuestionnaires === 0) return 'tooManyTasks';
        if (numberOfLiveTrainingMeetings > 3) return 'tooManyLiveTrainingActivities';
        if (numberOfQuestionnaires > 3) return 'tooManyQuestionnaires';
    }, [module.activities]);

    async function onDragEnd(result: DropResult) {
        if (!result.destination) return;

        setErrors([]);
        setReorderSaved(false);

        const oldActivities = [...localActivities];

        const newActivities = reorder(localActivities, result.source.index, result.destination.index);

        setLocalActivities(newActivities);

        const reorderResponse = await reorderActivities(newActivities.map((x) => x.id));

        const { errors } = reorderResponse;
        if (errors) {
            setLocalActivities(oldActivities);
            setErrors(errors);
            return;
        }

        setReorderSaved(true);
    }

    const onDragStart = () => {
        if (reorderSaved) {
            setReorderSaved(false);
        }
    };

    return (
        <AccordionPanelContainer id={module.id}>
            <TrainerCourseGrid {...dragHandleProps}>
                <AccordionPanelHeaderTitle title={module.title} />
                <DescriptionGrid>
                    <BodyRegular>{module.description}</BodyRegular>
                </DescriptionGrid>
                {moduleEditable && editButtonClick && (
                    <Edit>
                        <SecondaryButton
                            title={strings.courseContentPage.editModuleButton}
                            aria={strings.courseContentPage.editModuleButtonAria}
                            onClick={() => editButtonClick(module.id)}
                            icon={<EditIcon />}
                            fullwidth
                        />
                    </Edit>
                )}
                <AccordionPanelToggle onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
            </TrainerCourseGrid>
            {showIncompleteStepsWarning && module.activities.length === 0 && (
                <Warning>
                    <WarningIcon />
                    <ErrorMessage>{strings.courseContentPage.addActivityToThisModule}</ErrorMessage>
                </Warning>
            )}
            <animated.div style={{ overflow: 'hidden', height: animatedHeight }}>
                <div ref={heightRef}>
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId={`activities-list-${module.id}`}
                            type="activity-droppable"
                            isDropDisabled={!activitiesSortable}
                        >
                            {(provided, snapshot) => (
                                <DropArea
                                    $isDraggingOver={snapshot.isDraggingOver}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <ActivitiesTitle $bold $isDraggingOver={snapshot.isDraggingOver}>
                                        {strings.reviewCoursePage.activitiesHeader}
                                    </ActivitiesTitle>
                                    {localActivities.map((activity, index) => {
                                        return (
                                            <Draggable
                                                key={activity.id}
                                                draggableId={activity.id}
                                                index={index}
                                                isDragDisabled={reorderLoading || !activitiesSortable}
                                            >
                                                {(provided, snapshot) => (
                                                    <DroppableItem
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ActivityCard
                                                            isDragging={snapshot.isDragging}
                                                            key={activity.id}
                                                            activity={activity}
                                                            showEditControls={activitiesEditable}
                                                            editActivityClick={
                                                                editActivityClick
                                                                    ? () => editActivityClick(module.id, activity.id)
                                                                    : () => {}
                                                            }
                                                        />
                                                    </DroppableItem>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                    {reorderLoading ? (
                                        <Notification notificationType={NotificationType.WARNING} title="Saving..." />
                                    ) : reorderSaved ? (
                                        <Notification
                                            notificationType={NotificationType.SUCCESS}
                                            title="Saved Successfully!"
                                        />
                                    ) : null}

                                    {errors && errors.length > 0 && !reorderLoading && (
                                        <Notification
                                            notificationType={NotificationType.DANGER}
                                            title={strings.courseContentPage.reorderActivitiesError}
                                            description={strings.courseContentPage.reorderActivitiesErrorDetail}
                                        />
                                    )}

                                    {showVariationTip && showVariationTip === 'tooManyTasks' ? (
                                        <AccessibilityNotifier
                                            accessibilityGuideKey={showVariationTip}
                                            fixButtonName={
                                                isMobileWidth
                                                    ? strings.accessibilityGuide.generateQuestionnaireButton
                                                          .titleMobile
                                                    : strings.accessibilityGuide.generateQuestionnaireButton.title
                                            }
                                            fixButtonAria={strings.accessibilityGuide.generateQuestionnaireButton.aria}
                                            fixButtonIcon={
                                                <QuestionnaireIcon
                                                    colour={theme.secondaryButtonTextColour}
                                                    size={IconSize.SMALL}
                                                />
                                            }
                                            fixButtonFunction={() => {
                                                setShowGenerateQuestionnaireModal(true);
                                            }}
                                        />
                                    ) : (
                                        <AccessibilityNotifier accessibilityGuideKey={showVariationTip!} />
                                    )}

                                    {showGenerateQuestionnaireModal && (
                                        <Modal
                                            onClickCloseButton={() => setShowGenerateQuestionnaireModal(false)}
                                            modalBody={
                                                <GenerateModuleQuestionnaireForm
                                                    moduleId={module.id}
                                                    moduleTitle={module.title}
                                                    tasks={module.activities}
                                                    returnToModulesList={() =>
                                                        setShowGenerateQuestionnaireModal(false)
                                                    }
                                                    hideHeader
                                                />
                                            }
                                            headlineText={strings.questionnaireGenerateForm.instruction}
                                        />
                                    )}

                                    {module.activities?.length < 6 && activitiesEditable && addActivityClick && (
                                        <AddActivityButtonContainer>
                                            <SecondaryButton
                                                fullwidth
                                                title={strings.courseContentPage.addActivityButton}
                                                aria={strings.courseContentPage.addActivityButtonAria}
                                                onClick={() => addActivityClick(module.id)}
                                                icon={<AddIcon />}
                                            />
                                        </AddActivityButtonContainer>
                                    )}
                                </DropArea>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </animated.div>
        </AccordionPanelContainer>
    );
}

const ActivitiesTitle = styled(BodyLarge)<{ $isDraggingOver: boolean }>`
    color: ${({ $isDraggingOver }) => {
        return $isDraggingOver ? theme.primaryButtonTextColour : theme.textColour;
    }};
`;

const DropArea = styled.div<{ $isDraggingOver: boolean }>`
    background-color: ${({ $isDraggingOver }) => {
        return $isDraggingOver ? theme.primaryButtonHoverBackgroundColour : theme.cardSecondaryBackgroundColour;
    }};
    border-radius: ${sizes.spacingSm};
    margin-top: ${sizes.spacingMd};
    padding: ${sizes.spacingMd};
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const DroppableItem = styled.div`
    margin-bottom: ${sizes.spacingSm};
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusLg};
    &:focus {
        border: 2px solid ${theme.linkFocusBorderColour};
        outline: ${theme.linkFocusBorderColour};
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border-radius: 12px;
    }
`;

const Edit = styled.div`
    grid-area: edit;
    display: flex;
    align-items: flex-start;
    font-size: ${FONTSIZE.BodyRegular};
`;

const TrainerCourseGrid = styled.div`
    display: grid;
    grid-template-areas:
        'title edit toggle'
        'description description description';
    grid-template-columns: 1fr;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        grid-template-areas:
            'title  toggle'
            'description description'
            'edit edit';
    }
`;

const DescriptionGrid = styled.div`
    grid-area: description;
`;

const AccordionPanelContainer = styled.div<{ complete?: boolean }>`
    width: 100%;
    padding: ${sizes.spacingMd};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);

    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusLg};
    border: 1px solid ${({ complete }) => (complete ? theme.notificationSuccessBorder : theme.cardBorder)};
`;

const AddActivityButtonContainer = styled.div`
    width: 100%;
    padding-bottom: ${sizes.spacingMd};
`;

const Warning = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
    margin-top: ${sizes.spacingMd};
`;
