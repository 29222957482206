import styled from 'styled-components';
import { FONTSIZE, sizes, theme } from '../../../theme';
import { BodyLarge, BodyRegular } from '../../../components/Elements';
import { ImageCircle } from 'components/ImageCircle';
import { LearnerCardBase } from '../sharedStyles/LearnerStyles';

type YourTrainerCardProps = {
    profileImageFilepath: string;
    trainerName: string;
    trainerBio: string | null;
    trainerEmail?: string;
};

export default function YourTrainerCard({
    profileImageFilepath,
    trainerName,
    trainerBio,
    trainerEmail,
}: YourTrainerCardProps): JSX.Element {
    return (
        <TrainerAndOrgContainer>
            <TrainerImageAndName>
                <div>
                    <ImageCircle $imageurl={profileImageFilepath} />
                </div>
                <div>
                    <HeadlineTextBlue $bold>{trainerName}</HeadlineTextBlue>
                    {trainerEmail && <BodyLarge>{trainerEmail}</BodyLarge>}
                </div>
            </TrainerImageAndName>
            {trainerBio && <BodyRegular>{trainerBio}</BodyRegular>}
        </TrainerAndOrgContainer>
    );
}

const TrainerImageAndName = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
`;

const HeadlineTextBlue = styled(BodyLarge)`
    font-size: ${FONTSIZE.Headline6};
    color: ${theme.primaryBrandColour};
`;

const TrainerAndOrgContainer = styled(LearnerCardBase)`
    width: 100%;
    display: flex;
    padding: ${sizes.spacingMd};
    word-break: break-word;
    flex-direction: column;
    align-items: start;
    gap: ${sizes.spacingMd};
`;
