import React, { useContext } from 'react';
import strings from '../../../strings/strings.json';
import Table, { RightAlignedButtonCell, TableRow } from 'components/table/Table';
import { EmptyTableContent } from 'components/table/EmptyTableContent';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import { breakpoints, sizes, theme } from 'theme';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { Course } from 'models/Course';
import NavigateWithArrow from 'components/actions/NavigateWithArrow';
import { IconSize } from 'assets/icons/icon-sizes';
import { FlexAlignCenter, Headline3 } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import MainSection from 'components/sections/MainSection';
import styled from 'styled-components';
import PlusIcon from 'assets/icons/controls/PlusIcon';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import Modal from 'components/modals/Modal';
import CreateCourseSwitch from 'components/modals/createCourseModalComponents/CreateCourseSwitch';

export default function OACoursesCoursesTable(): JSX.Element {
    const { userData } = useContext(AuthContext);

    const [showCreateCourseModal, setShowCreateCourseModal] = React.useState<boolean>(false);

    const createCourseButtonRef = React.useRef<HTMLButtonElement>(null);

    const {
        data: courses,
        loading,
        errors,
    } = useGetRequest<Course[]>(
        `organisations/${userData?.organisation?.id}/published-courses`,
        !userData?.organisation?.id,
    );

    if (!courses || loading) return <></>;

    if (errors && errors.length) {
        return (
            <CourseTableContentSection>
                <Headline3>{strings.OACoursesTable.coursesHeading}</Headline3>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.OACoursesTable.getCoursesError.title}
                    description={strings.OACoursesTable.getCoursesError.description}
                />
            </CourseTableContentSection>
        );
    }

    function closeModal() {
        setShowCreateCourseModal(false);
        createCourseButtonRef.current?.focus();
    }

    const courseRows: TableRow[] = (courses ?? [])
        .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
        .flatMap((course) => {
            const shortDate = new Date(course.dateCreated).toLocaleDateString();
            const linkAria = `${strings.OACoursesTable.viewCourseDetailsButton.aria} ${course.title}`;
            const linkHref = `/organisation/dashboard/${course.id}/view`;
            const editCourseLinkHref = `/edit-course/${course.id}`;

            return {
                id: course.id,
                cells: [
                    {
                        name: strings.OACoursesTable.tableHeaders.courseTitle,
                        value: course.title,
                    },
                    {
                        name: strings.OACoursesTable.tableHeaders.createdBy,
                        value: `${course.user?.firstName} ${course.user?.lastName}`,
                    },
                    {
                        name: strings.OACoursesTable.tableHeaders.dateCreated,
                        value: shortDate,
                    },
                    {
                        name: strings.OACoursesTable.tableHeaders.published,
                        value: course.published
                            ? strings.OACoursesTable.publishedYes
                            : strings.OACoursesTable.publishedNo,
                    },
                    {
                        name: '',
                        value: (
                            <ButtonsSection>
                                <RightAlignedButtonCell>
                                    <NavigateWithArrow
                                        title={strings.OACoursesTable.viewCourseDetailsButton.title}
                                        href={course.published ? linkHref : editCourseLinkHref}
                                        aria={linkAria}
                                        iconSize={IconSize.LARGE}
                                    />
                                </RightAlignedButtonCell>
                            </ButtonsSection>
                        ),
                    },
                ],
            };
        });

    return (
        <CourseTableContentSection>
            <TitleAndButtonContainer>
                <Headline3>{strings.OACoursesTable.coursesHeading}</Headline3>
                {courses.length > 0 && (
                    <PrimaryButton
                        title={strings.OACoursesTable.createCourseButton.title}
                        aria={strings.OACoursesTable.createCourseButton.aria}
                        onClick={() => setShowCreateCourseModal(true)}
                        icon={<PlusIcon size={IconSize.LARGE} />}
                    />
                )}
            </TitleAndButtonContainer>
            <div>
                <Table
                    headers={[
                        {
                            name: strings.OACoursesTable.tableHeaders.courseTitle,
                            order: 1,
                            rowPercentage: 40,
                        },
                        {
                            name: strings.OACoursesTable.tableHeaders.createdBy,
                            order: 2,
                            rowPercentage: 20,
                        },
                        {
                            name: strings.OACoursesTable.tableHeaders.dateCreated,
                            order: 3,
                            rowPercentage: 20,
                        },
                        { name: strings.OACoursesTable.tableHeaders.published, order: 4, rowPercentage: 10 },
                        {
                            name: '',
                            order: 5,
                            rowPercentage: 10,
                        },
                    ]}
                    rows={courseRows}
                    emptyTableContent={
                        <EmptyTableContent
                            icon={<CoursesIcon size={IconSize.XXLARGE} colour={theme.textColour} />}
                            title={strings.OACoursesTable.emptyTableContent.title}
                            description={strings.OACoursesTable.emptyTableContent.description}
                            buttonTitle={strings.OACoursesTable.createCourseButton.title}
                            buttonAria={strings.OACoursesTable.createCourseButton.aria}
                            buttonOnClick={() => setShowCreateCourseModal(true)}
                            buttonIcon={<PlusIcon size={IconSize.LARGE} />}
                        />
                    }
                    mobileViewBelow={breakpoints.xl}
                />
            </div>
            {showCreateCourseModal && (
                <Modal
                    onClickCloseButton={closeModal}
                    headlineText={strings.createCourseModal.createCourseHeadline}
                    modalBody={<CreateCourseSwitch closeModal={closeModal} />}
                />
            )}
        </CourseTableContentSection>
    );
}

const CourseTableContentSection = styled(MainSection)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    padding: ${sizes.spacingSm} 0;
    min-height: 120px;

    @media (max-width: ${breakpoints.sm}) {
        padding: unset;
    }
`;

const TitleAndButtonContainer = styled(FlexAlignCenter)`
    justify-content: space-between;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        align-items: flex-start;
    }
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.xl}) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
`;
