import * as React from 'react';
import { AuthContext } from '../../contextProviders/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ButtonRowRight, Headline3, Headline4 } from '../../components/Elements';
import styled from 'styled-components';
import RadioFields, { RadioFieldOption } from 'components/forms/RadioFields';
import useNeveForm from 'components/forms/NeveForm';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import useModifyRequest from 'hooks/useModifyRequest';
import MainSection from 'components/sections/MainSection';
import strings from '../../strings/strings.json';
import { sizes } from 'theme';
import UserCreateOrganisationForm from './components/UserCreateOrganisationForm';

enum ChooseRole {
    LEARNER = 0,
    ORGANISATION = 1,
}

type ChooseRoleOption = {
    type: ChooseRole;
    name: string;
    description: string;
    aria: string;
};

type ChooseRoleInput = {
    type: 0 | 1 | null;
};

export type Component = '';

export default function InitialLoginPage(): JSX.Element {
    const { refreshUserData } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const [error, setError] = React.useState<boolean>(false);
    const [showCreateOrganisationForm, setShowCreateOrganisationForm] = React.useState<boolean>(false);

    const { modifyData: addLearnerRole } = useModifyRequest(`users/me/assign-learner-role`, 'PATCH');

    const { register, handleSubmit } = useNeveForm<ChooseRoleInput>({
        type: null,
    });

    const options: ChooseRoleOption[] = [
        {
            type: ChooseRole.LEARNER,
            name: strings.chooseRolePage.radioOptions.learner.name,
            description: strings.chooseRolePage.radioOptions.learner.description,
            aria: strings.chooseRolePage.radioOptions.learner.aria,
        },
        {
            type: ChooseRole.ORGANISATION,
            name: strings.chooseRolePage.radioOptions.organisation.name,
            description: strings.chooseRolePage.radioOptions.organisation.description,
            aria: strings.chooseRolePage.radioOptions.organisation.aria,
        },
    ];

    const radioFieldOptions: RadioFieldOption[] = options.map((option) => {
        return {
            value: option.type,
            title: option.name,
            description: option.description,
            aria: option.aria,
            inputProps: register('type'),
            name: 'type',
        };
    });

    async function submit(formData: ChooseRoleInput) {
        setError(false);

        if (formData.type === null) {
            setError(true);
            return;
        }

        const selection = options.find((x) => +x.type === +formData.type!);

        switch (selection!.type) {
            case ChooseRole.LEARNER:
                await addLearnerRole({});
                await refreshUserData();
                navigate('/learner/courses');
                break;
            case ChooseRole.ORGANISATION:
                setShowCreateOrganisationForm(true);
                break;
        }
    }

    return (
        <MainSection>
            {showCreateOrganisationForm ? (
                <UserCreateOrganisationForm showHeadlineText />
            ) : (
                <ChooseRoleForm onSubmit={handleSubmit(submit)}>
                    <Headline3>{strings.chooseRolePage.welcomeHeader}</Headline3>
                    <Headline4>{strings.chooseRolePage.chooseRoleHeader}</Headline4>
                    <RadioContainer>
                        <RadioFields options={radioFieldOptions} />
                    </RadioContainer>
                    <ButtonRowRight>
                        {error && <ErrorNotifierSlim description={strings.chooseRolePage.chooseRoleErrorMessage} />}
                        <PrimaryButton
                            type="submit"
                            title={strings.chooseRolePage.button.title}
                            aria={strings.chooseRolePage.button.aria}
                        />
                    </ButtonRowRight>
                </ChooseRoleForm>
            )}
        </MainSection>
    );
}

const ChooseRoleForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: ${sizes.spacingLg} 0;
`;

const RadioContainer = styled.div`
    width: 100%;
`;
