import Accordion from 'components/accordion/Accordion';
import { AccordionPanelProps } from 'components/accordion/AccordionPanel';
import { AccordionPanelItemProps } from 'components/accordion/AccordionPanelItem';
import { BodyRegular, WrappingText } from 'components/Elements';
import MainSection from 'components/sections/MainSection';
import useWindowWidth from 'hooks/useWindowWidth';
import { ActivityType, getActivityTypeIcon } from 'models/Activity';
import { LearnerActivity, LearnerLearningObjective, LearnerModule, LearnerTrainerInfo } from 'models/LearnerCourse';
import LearnerCoursePageFooter from 'pages/learner/components/LearnerCoursePageFooter';
import strings from 'strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import DurationLabel from 'components/DurationLabel';
import NextIcon from 'assets/icons/controls/NextIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import NextActivityComponent from './NextActivityComponent';
import NextLiveTrainingMeetingCard from '../../pages/learner/components/NextLiveTrainingMeetingCard';
import LearnerCourseHeader from './LearnerCourseHeader';

type LearnerCourseComponentProps = {
    courseTitle: string;
    courseDescription: string;
    learningObjectives: LearnerLearningObjective[];
    modules: LearnerModule[];
    activeModuleIndex?: number;
    startDate: string;
    endDate: string;
    urlPrefix: string;
    trainerInfo: LearnerTrainerInfo;
};

export default function LearnerCourseComponent({
    courseTitle,
    courseDescription,
    learningObjectives,
    modules,
    activeModuleIndex,
    startDate,
    endDate,
    urlPrefix,
    trainerInfo,
}: LearnerCourseComponentProps): JSX.Element {
    const { isMobileWidth } = useWindowWidth();

    const flattenedActivities: LearnerActivity[] = modules.flatMap((module) => module.activities);

    const nextLiveTraining = flattenedActivities
        .filter((x) => x.type === ActivityType.LIVE_TRAINING)
        .filter((x) => new Date(x.startDate ?? 0).getTime() - 60 * 60 * 1000 < new Date().getTime()) // is one hour before the live training starts less than now?
        .filter(
            (x) => new Date(x.startDate ?? 0).getTime() + (x.durationInMins ?? 0) * 60 * 1000 > new Date().getTime(),
        )[0]; // is now earlier than the end time? get the first that satisfies these filters.

    const accordionPanels: AccordionPanelProps[] = modules.map((module, index) => {
        const panelItems = module.activities.map((activity) => {
            const panelItem: AccordionPanelItemProps = {
                content: (
                    <>
                        <IconAndTitleContainer>
                            <IconContainer>{getActivityTypeIcon(activity, IconSize.MEDIUM)}</IconContainer>
                            <WrappingText>{activity.title}</WrappingText>
                        </IconAndTitleContainer>
                        <DurationAndArrowContainer>
                            {!isMobileWidth && !!activity.durationInMins && (
                                <DurationLabel durationInMins={activity.durationInMins} hideTitle />
                            )}
                            <NextIcon size={IconSize.LARGE} />
                        </DurationAndArrowContainer>
                    </>
                ),
                url: `${urlPrefix}/activity/${activity.id}`,
                aria: strings.learnerCoursePage.activityButtonAria,
            };

            return panelItem;
        });

        const moduleOverviewPanelItem: AccordionPanelItemProps = {
            content: (
                <>
                    <BodyRegular $bold>{strings.learnerCoursePage.moduleOverviewButton.title}</BodyRegular>
                    <NextIcon size={IconSize.LARGE} />
                </>
            ),
            url: `module/${module.id}`,
            aria: `${strings.learnerCoursePage.moduleOverviewButton.aria}: ${module.title}`,
        };

        panelItems.unshift(moduleOverviewPanelItem);

        const moduleTitle = `Module ${module.order}: ${module.title}`;
        const moduleCompleted = module.activities.every((activity) => activity.completed);
        const numberOfCompletedActivities = module.activities.filter((activity) => activity.completed).length;
        const completedActivityText = `${numberOfCompletedActivities}/${module.activities.length}`;

        const panel: AccordionPanelProps = {
            headerTitle: moduleTitle,
            panelCompleted: moduleCompleted,
            completedItemsText: completedActivityText,
            panelItems: panelItems,
            openPanel: index === activeModuleIndex,
        };

        return panel;
    });

    return (
        <LearnerCoursePage>
            <LearnerCourseHeader
                courseTitle={courseTitle}
                courseDescription={courseDescription}
                learningObjectives={learningObjectives}
                startDate={startDate}
                endDate={endDate}
                trainerInfo={trainerInfo}
            />
            <Background>
                <LearnerCourseComponentMainSection>
                    {nextLiveTraining ? (
                        <NextLiveTrainingMeetingCard nextActivity={nextLiveTraining} />
                    ) : (
                        <NextActivityComponent modules={modules} />
                    )}
                    <Accordion panels={accordionPanels} width={'780px'} />
                </LearnerCourseComponentMainSection>
            </Background>
            <LearnerCoursePageFooter trainerInfo={trainerInfo} />
        </LearnerCoursePage>
    );
}

const LearnerCoursePage = styled.div`
    background: ${theme.learnerCourseBackgroundColour};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 80px);
    @media (max-width: ${breakpoints.sm}) {
        height: calc(100% - 113px);
    }
`;

const Background = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: inherit;
`;

const LearnerCourseComponentMainSection = styled(MainSection)`
    background: ${theme.learnerCourseBackgroundColour};
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingLg} ${sizes.edgePadding};
`;

const IconContainer = styled.div`
    display: flex;
`;

const IconAndTitleContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    gap: ${sizes.spacingMd};
`;

const DurationAndArrowContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingRg};
`;
