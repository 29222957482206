import {
    ACCEPTED_AUDIO_MIME_TYPES,
    ACCEPTED_IMAGE_MIME_TYPES,
    ACCEPTED_LOGO_MIME_TYPES,
    ACCEPTED_POWERPOINT_MIME_TYPES,
    ACCEPTED_SCORM_MIME_TYPES,
    ACCEPTED_VIDEO_MIME_TYPES,
    MAX_AUDIO_SIZE,
    MAX_IMAGE_SIZE,
    MAX_POWERPOINT_SIZE,
    MAX_SCORM_SIZE,
    MAX_VIDEO_SIZE,
} from '../../../lib/_api-helpers';
import strings from '../../../strings/strings.json';
import VideoStrategy from './VideoStrategy';
import ImageStrategy from './ImageStrategy';
import ImageIcon from '../../../assets/icons/pathway/ImageIcon';
import VideoIcon from '../../../assets/icons/pathway/VideoIcon';
import LogoStrategy from './LogoStrategy';
import AudioStrategy from './AudioStrategy';
import AudioIcon from 'assets/icons/pathway/AudioIcon';
import DefaultStrategy from './DefaultStrategy';

export enum FileType {
    VIDEO = 1,
    IMAGE = 2,
    LOGO = 3,
    AUDIO = 4,
    SCORM = 5,
    POWERPOINT = 6,
}

export type FileMetaData = {
    videoTranscript?: string;
    imageAltText?: string;
};

export type StrategyProps = {
    localFile: File;
    localFileValid: boolean;
    fileMetaData: FileMetaData | undefined;
    setFileMetaData: Function;
    setFileMetaDataValid: Function;
};

export type FileUploadStrategy = {
    fileType: FileType | undefined;
    mimeTypes: string[];
    maxFileSize: number;
    validationText: string;
    invalidTypeText: string;
    invalidSizeText: string;
    strategy: (
        localFile: File,
        localFileValid: boolean,
        fileMetaData: FileMetaData | undefined,
        setFileMetaData: Function,
        setFileValid: Function,
    ) => JSX.Element;
    icon: JSX.Element;
};
export const STRATEGIES: FileUploadStrategy[] = [
    {
        fileType: FileType.VIDEO,
        mimeTypes: ACCEPTED_VIDEO_MIME_TYPES,
        maxFileSize: MAX_VIDEO_SIZE,
        invalidTypeText: strings.fileUpload.invalidVideoType,
        invalidSizeText: strings.fileUpload.videoTooBig,
        validationText: strings.fileUpload.videoValidationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => {
            return (
                <VideoStrategy
                    localFile={localFile}
                    localFileValid={localFileValid}
                    fileMetaData={fileMetaData}
                    setFileMetaData={setFileMetaData}
                    setFileMetaDataValid={setFileMetaDataValid}
                />
            );
        },
        icon: <VideoIcon />,
    },
    {
        fileType: FileType.IMAGE,
        mimeTypes: ACCEPTED_IMAGE_MIME_TYPES,
        maxFileSize: MAX_IMAGE_SIZE,
        invalidTypeText: strings.fileUpload.invalidImageType,
        invalidSizeText: strings.fileUpload.imageTooBig,
        validationText: strings.fileUpload.imageValidationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => {
            return (
                <ImageStrategy
                    localFile={localFile}
                    localFileValid={localFileValid}
                    fileMetaData={fileMetaData}
                    setFileMetaData={setFileMetaData}
                    setFileMetaDataValid={setFileMetaDataValid}
                />
            );
        },
        icon: <ImageIcon />,
    },
    {
        fileType: FileType.LOGO,
        mimeTypes: ACCEPTED_LOGO_MIME_TYPES,
        maxFileSize: MAX_IMAGE_SIZE,
        invalidTypeText: strings.fileUpload.invalidLogoType,
        invalidSizeText: strings.fileUpload.logoTooBigText,
        validationText: strings.fileUpload.logoValidationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => {
            return (
                <LogoStrategy
                    localFile={localFile}
                    localFileValid={localFileValid}
                    fileMetaData={fileMetaData}
                    setFileMetaData={setFileMetaData}
                    setFileMetaDataValid={setFileMetaDataValid}
                />
            );
        },
        icon: <ImageIcon />,
    },
    {
        fileType: FileType.AUDIO,
        mimeTypes: ACCEPTED_AUDIO_MIME_TYPES,
        maxFileSize: MAX_AUDIO_SIZE,
        invalidTypeText: strings.fileUpload.audio.invalidAudioType,
        invalidSizeText: strings.fileUpload.audio.audioTooBig,
        validationText: strings.fileUpload.audio.audioValidationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => {
            return (
                <AudioStrategy
                    localFile={localFile}
                    localFileValid={localFileValid}
                    fileMetaData={fileMetaData}
                    setFileMetaData={setFileMetaData}
                    setFileMetaDataValid={setFileMetaDataValid}
                />
            );
        },
        icon: <AudioIcon />,
    },
    {
        fileType: FileType.SCORM,
        mimeTypes: ACCEPTED_SCORM_MIME_TYPES,
        maxFileSize: MAX_SCORM_SIZE,
        invalidTypeText: strings.fileUpload.scorm.invalidType,
        invalidSizeText: strings.fileUpload.scorm.tooBig,
        validationText: strings.fileUpload.scorm.validationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => {
            return (
                <DefaultStrategy
                    localFile={localFile}
                    localFileValid={localFileValid}
                    fileMetaData={fileMetaData}
                    setFileMetaData={setFileMetaData}
                    setFileMetaDataValid={setFileMetaDataValid}
                />
            );
        },
        icon: <ImageIcon />,
    },
    {
        fileType: FileType.POWERPOINT,
        mimeTypes: ACCEPTED_POWERPOINT_MIME_TYPES,
        maxFileSize: MAX_POWERPOINT_SIZE,
        invalidTypeText: strings.fileUpload.powerPoint.invalidType,
        invalidSizeText: strings.fileUpload.powerPoint.tooBig,
        validationText: strings.fileUpload.powerPoint.validationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => {
            return (
                <DefaultStrategy
                    localFile={localFile}
                    localFileValid={localFileValid}
                    fileMetaData={fileMetaData}
                    setFileMetaData={setFileMetaData}
                    setFileMetaDataValid={setFileMetaDataValid}
                />
            );
        },
        icon: <ImageIcon />,
    },
    {
        fileType: undefined,
        mimeTypes: ACCEPTED_IMAGE_MIME_TYPES.concat(ACCEPTED_VIDEO_MIME_TYPES),
        maxFileSize: MAX_VIDEO_SIZE,
        invalidTypeText: strings.fileUpload.invalidFileTypeText,
        invalidSizeText: strings.fileUpload.fileTooBigText,
        validationText: strings.fileUpload.fileValidationText,
        strategy: (localFile: File, localFileValid, fileMetaData, setFileMetaData, setFileMetaDataValid) => <></>,
        icon: <ImageIcon />,
    },
];
