import { ContentColumn, LearnerCourseContentError, Page } from '../../../components/shared';
import strings from '../../../../../strings/strings.json';
import { BodyLarge, Headline2, Headline5 } from '../../../../../components/Elements';
import { APIError, ApiResponse, DOCUMENTS_URL } from '../../../../../lib/_api-helpers';
import { ResourceCardGrid } from '../../../components/ActivityCardGrid';
import MarkAsCompleteCard from '../../../components/MarkAsCompleteCard';
import styled from 'styled-components';
import { sizes, theme } from '../../../../../theme';
import { CourseNavigationActivity } from '../../../components/CourseNavigation';
import { LearnerTask } from '../../../../../models/LearnerTask';
import ExpandableContentComponent from 'components/ExpandableContentComponent';
import ActivityTypeLabel from '../../../../edit-course/components/ActivityTypeLabel';
import { ActivityType, BuildMethod } from 'models/Activity';
import AudioPlayer from 'pages/learner/components/AudioPlayer';
import VideoPlayer from 'pages/learner/components/VideoPlayer';
import { LearnerCardBase } from 'pages/learner/sharedStyles/LearnerStyles';

type TaskContentLayoutProps = {
    task: LearnerTask | null;
    loading: boolean;
    errors: APIError[];
    toggleTaskCompletion: () => Promise<ApiResponse<boolean>>;
    toggleCompletionLoading: boolean;
    toggleCompletionErrors: APIError[];
};

export default function TaskContentLayout({
    task,
    loading,
    errors,
    toggleTaskCompletion,
    toggleCompletionLoading,
    toggleCompletionErrors,
}: TaskContentLayoutProps): JSX.Element {
    if (loading || !task) return <></>;

    if (errors.length > 0) {
        return (
            <LearnerCourseContentError
                title={strings.modulePage.error.title}
                description={strings.modulePage.error.description}
            />
        );
    }

    let taskContent: JSX.Element = <></>;

    switch (task.buildMethod) {
        case BuildMethod.TEXT_AND_IMAGE:
            taskContent = (
                <>
                    {task.audioDocument && (
                        <CardWithGap>
                            <Headline5>{strings.taskPage.audioContentTitle}</Headline5>
                            <AudioPlayer filepath={task.audioDocument!.s3Filepath} />{' '}
                        </CardWithGap>
                    )}
                    {task.audioDocument ? (
                        <Card>
                            <ExpandableContentComponent
                                headline={strings.taskPage.generatedTextContentTitle}
                                closedHeightInPx={150}
                                content={<DescriptionText>{task.content}</DescriptionText>}
                            />
                        </Card>
                    ) : (
                        <CardWithGap>
                            <Headline5>{strings.taskPage.textContentTitle}</Headline5>
                            <DescriptionText>{task.content}</DescriptionText>
                        </CardWithGap>
                    )}
                    {task.imageDocument && (
                        <ImageContainer>
                            <Image
                                src={`${DOCUMENTS_URL}/${task.imageDocument.s3Filepath}`}
                                alt={task.imageDocument.imageAltText ?? strings.taskPage.imageAltTextPlaceholder}
                            />
                        </ImageContainer>
                    )}
                </>
            );
            break;
        case BuildMethod.AUDIO:
            taskContent = (
                <>
                    <CardWithGap>
                        <Headline5>{strings.taskPage.audioContentTitle}</Headline5>
                        <AudioPlayer filepath={task.audioDocument!.s3Filepath} />
                    </CardWithGap>
                    {task.content && (
                        <Card>
                            <ExpandableContentComponent
                                headline={strings.taskPage.generatedTextContentTitle}
                                closedHeightInPx={150}
                                content={<DescriptionText>{task.content}</DescriptionText>}
                            />
                        </Card>
                    )}
                </>
            );
            break;
        case BuildMethod.VIDEO:
            taskContent = (
                <>
                    <CardWithGap>
                        <Headline5>{strings.taskPage.videoContentTitle}</Headline5>
                        <VideoPlayer filepath={task.videoDocument!.s3Filepath} />
                    </CardWithGap>
                    {task.content && (
                        <Card>
                            <ExpandableContentComponent
                                headline={strings.taskPage.generatedTextContentTitle}
                                closedHeightInPx={150}
                                content={<DescriptionText>{task.content}</DescriptionText>}
                            />
                        </Card>
                    )}
                </>
            );
            break;
    }

    return (
        <Page id="page">
            <ContentColumn>
                <HeadlineAndLabelContainer>
                    <Headline2>{task && task.title}</Headline2>
                    <ActivityTypeLabel activityType={ActivityType.TASK} />
                </HeadlineAndLabelContainer>
                <DescriptionText $bold>{task.description}</DescriptionText>
                <Border />
                {taskContent}
                {task.resources.length > 0 && <ResourceCardGrid resources={task.resources} />}
            </ContentColumn>
            <TaskContentPageFooter>
                <MarkAsCompleteCard
                    completed={task.completed}
                    toggleTaskCompletion={toggleTaskCompletion}
                    toggleCompletionLoading={toggleCompletionLoading}
                    toggleCompletionErrors={toggleCompletionErrors}
                />
                <CourseNavigationActivity activityId={task.id} />
            </TaskContentPageFooter>
        </Page>
    );
}
const Card = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
`;

const CardWithGap = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const DescriptionText = styled(BodyLarge)`
    white-space: pre-wrap;
`;

export const HeadlineAndLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const ImageContainer = styled(LearnerCardBase)`
    padding: ${sizes.spacingMd};
    display: flex;
    max-width: 1000px;
    max-height: 1000px;
`;

const Image = styled.img`
    object-fit: contain;
    width: 100%;
    height: auto;
`;

const TaskContentPageFooter = styled.div`
    width: 100%;
`;

const Border = styled.div`
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
    margin: ${sizes.spacingMd} 0;
`;
