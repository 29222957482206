import NextIcon from 'assets/icons/controls/NextIcon';
import PreviousIcon from 'assets/icons/controls/PreviousIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { BodyRegular } from 'components/Elements';
import useWindowWidth from 'hooks/useWindowWidth';
import { LearnerCourse } from 'models/LearnerCourse';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sizes, theme } from 'theme';
import { useContext } from 'react';
import { LearnerCourseSummaryContext } from '../../../contextProviders/LearnerCourseSummaryProvider';
import strings from '../../../strings/strings.json';

type CourseNavigationModuleProps = {
    learnerCourse: LearnerCourse;
    moduleId: string;
    urlPrefix: string;
};

export function CourseNavigationModule({
    learnerCourse,
    moduleId,
    urlPrefix,
}: CourseNavigationModuleProps): JSX.Element {
    let previousButtonTitle = '';
    let previousButtonAria = '';
    let previousButtonUrl = '';
    let nextButtonTitle = '';
    let nextButtonAria = '';
    let nextButtonUrl = '';

    const modules = learnerCourse.modules.sort((moduleA, moduleB) => moduleA.order - moduleB.order);
    const module = modules.find((module) => module.id === moduleId);

    if (!module) {
        return <></>;
    }

    const moduleIndex = modules.findIndex((module) => module.id === moduleId);

    if (moduleIndex === 0) {
        previousButtonTitle = learnerCourse.title;
        previousButtonAria = `${strings.immersiveCourseViewButtons.backButton.aria} ${learnerCourse.title}`;
        previousButtonUrl = `${urlPrefix}/${learnerCourse.id}`;
    } else {
        const previousModule = modules[moduleIndex - 1];

        if (previousModule.activities.length > 0) {
            const activities = previousModule.activities.sort((a, b) => a.order - b.order);
            const lastActivityOfPreviousModule = activities[activities.length - 1];
            previousButtonTitle = lastActivityOfPreviousModule.title;
            previousButtonAria = `${strings.immersiveCourseViewButtons.backButton.aria} ${lastActivityOfPreviousModule.title}`;
            previousButtonUrl = `${urlPrefix}/${learnerCourse.id}/activity/${lastActivityOfPreviousModule.id}`;
        } else {
            previousButtonTitle = previousModule.title;
            previousButtonAria = `${strings.immersiveCourseViewButtons.backButton.aria} ${previousModule.title}`;
            previousButtonUrl = `${urlPrefix}/${learnerCourse.id}/module/${previousModule.id}`;
        }
    }

    if (module.activities.length > 0) {
        const nextActivity = module.activities[0];
        nextButtonTitle = nextActivity.title;
        nextButtonAria = `${strings.immersiveCourseViewButtons.nextButton.aria} ${nextActivity.title}`;
        nextButtonUrl = `${urlPrefix}/${learnerCourse.id}/activity/${nextActivity.id}`;
    } else {
        const isLastModule = moduleIndex === modules.length - 1;
        nextButtonTitle = isLastModule ? learnerCourse.title : modules[moduleIndex + 1].title;
        nextButtonAria = `${strings.immersiveCourseViewButtons.nextButton.aria} ${
            isLastModule ? learnerCourse.title : modules[moduleIndex + 1].title
        }`;
        nextButtonUrl = isLastModule
            ? `${urlPrefix}/${learnerCourse.id}`
            : `${urlPrefix}/${learnerCourse.id}/module/${moduleId}`;
    }

    return (
        <CourseNavigation
            previousButtonTitle={previousButtonTitle}
            previousButtonAria={previousButtonAria}
            previousButtonUrl={previousButtonUrl}
            nextButtonTitle={nextButtonTitle}
            nextButtonAria={nextButtonAria}
            nextButtonUrl={nextButtonUrl}
        />
    );
}

type CourseNavigationActivityProps = {
    activityId: string;
    questionnaireActive?: boolean;
};

export function CourseNavigationActivity({
    activityId,
    questionnaireActive,
}: CourseNavigationActivityProps): JSX.Element {
    const { courseSummary, navigationUrlPrefix } = useContext(LearnerCourseSummaryContext);

    let previousButtonTitle = '';
    let previousButtonUrl = '';
    let previousButtonAria = '';
    let nextButtonTitle = '';
    let nextButtonAria = '';
    let nextButtonUrl = '';
    let showSkipButton = false;

    if (!courseSummary) return <></>;

    const activity = courseSummary.modules
        .flatMap((module) => module.activities)
        .find((activity) => activity.id === activityId);

    if (!activity) {
        return <></>;
    }

    const module = courseSummary.modules.find((module) => module.id === activity.moduleId);

    if (!module) {
        return <></>;
    }

    const activities = module.activities.sort((activityA, activityB) => activityA.order - activityB.order);
    const activityIndex = activities.findIndex((activity) => activity.id === activityId);

    if (activityIndex === 0) {
        previousButtonTitle = module.title;
        previousButtonAria = `${strings.immersiveCourseViewButtons.backButton.aria} ${module.title}`;
        previousButtonUrl = `${navigationUrlPrefix}/${courseSummary.id}/module/${module.id}`;
    } else {
        const previousActivity = activities[activityIndex - 1];
        previousButtonTitle = previousActivity.title;
        previousButtonAria = `${strings.immersiveCourseViewButtons.backButton.aria} ${previousActivity.title}`;
        previousButtonUrl = `${navigationUrlPrefix}/${courseSummary.id}/activity/${previousActivity.id}`;
    }

    const isLastActivityInModule = activityIndex === activities.length - 1;

    if (isLastActivityInModule) {
        const moduleIndex = courseSummary.modules
            .sort((moduleA, moduleB) => moduleA.order - moduleB.order)
            .findIndex((m) => m.id === module.id);
        const isLastModuleInCourse = moduleIndex === courseSummary.modules.length - 1;

        if (isLastModuleInCourse) {
            nextButtonTitle = courseSummary.title;
            nextButtonAria = `${strings.immersiveCourseViewButtons.nextButton.aria} ${courseSummary.title}`;
            nextButtonUrl = `${navigationUrlPrefix}/${courseSummary.id}`;
        } else {
            const nextModule = courseSummary.modules[moduleIndex + 1];
            nextButtonTitle = nextModule.title;
            nextButtonAria = `${strings.immersiveCourseViewButtons.nextButton.aria} ${nextModule.title}`;
            nextButtonUrl = `${navigationUrlPrefix}/${courseSummary.id}/module/${nextModule.id}`;
        }
    } else {
        const nextActivity = activities[activityIndex + 1];
        nextButtonTitle = nextActivity.title;
        nextButtonAria = `${strings.immersiveCourseViewButtons.nextButton.aria} ${nextActivity.title}`;
        nextButtonUrl = `${navigationUrlPrefix}/${courseSummary.id}/activity/${nextActivity.id}`;
    }

    if (questionnaireActive) {
        nextButtonTitle = strings.questionnairePage.skipQuestionnaireButtonTitle;
        showSkipButton = true;
    }

    return (
        <CourseNavigation
            showSkipButton={showSkipButton}
            previousButtonTitle={previousButtonTitle}
            previousButtonAria={previousButtonAria}
            previousButtonUrl={previousButtonUrl}
            nextButtonTitle={nextButtonTitle}
            nextButtonAria={nextButtonAria}
            nextButtonUrl={nextButtonUrl}
        />
    );
}

type CourseNavigationProps = {
    showSkipButton?: boolean;
    previousButtonTitle: string;
    previousButtonAria: string;
    previousButtonUrl: string;
    nextButtonTitle: string;
    nextButtonAria: string;
    nextButtonUrl: string;
};

function CourseNavigation({
    showSkipButton,
    previousButtonTitle,
    previousButtonAria,
    previousButtonUrl,
    nextButtonTitle,
    nextButtonAria,
    nextButtonUrl,
}: CourseNavigationProps): JSX.Element {
    const { isMobileWidth } = useWindowWidth();
    const navigate = useNavigate();

    return (
        <CourseNavigationContainer>
            <Buttons>
                {!showSkipButton && (
                    <>
                        <PreviousButton
                            onClick={() => {
                                navigate(previousButtonUrl);
                            }}
                            aria-label={previousButtonAria}
                        >
                            <IconWrapper>
                                <PreviousIcon size={IconSize.XLARGE} colour={theme.secondaryButtonTextColour} />
                            </IconWrapper>
                            <ButtonText>
                                <BodyRegular $bold>{strings.immersiveCourseViewButtons.backButton.text}</BodyRegular>
                                {!isMobileWidth && <BodyRegular $bold>{previousButtonTitle}</BodyRegular>}
                            </ButtonText>
                        </PreviousButton>
                        <NextButton
                            onClick={() => {
                                navigate(nextButtonUrl);
                            }}
                            aria-label={nextButtonAria}
                        >
                            <ButtonText>
                                <BodyRegular $inverted $bold>
                                    {strings.immersiveCourseViewButtons.nextButton.text}
                                </BodyRegular>
                                {!isMobileWidth && (
                                    <BodyRegular $inverted $bold>
                                        {nextButtonTitle}
                                    </BodyRegular>
                                )}
                            </ButtonText>
                            <IconWrapper>
                                <NextIcon size={IconSize.XLARGE} colour={theme.primaryButtonTextColour} />
                            </IconWrapper>
                        </NextButton>
                    </>
                )}
                {showSkipButton && (
                    <SkipButton
                        onClick={() => {
                            navigate(nextButtonUrl);
                        }}
                        aria-label={strings.immersiveCourseViewButtons.skipQuestionnaireButton.aria}
                    >
                        <ButtonText>
                            <BodyRegular $bold>
                                {strings.immersiveCourseViewButtons.skipQuestionnaireButton.text}
                            </BodyRegular>
                        </ButtonText>
                    </SkipButton>
                )}
            </Buttons>
        </CourseNavigationContainer>
    );
}

const Button = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
    padding: ${sizes.spacingSm} ${sizes.spacingMd};
    width: 100%;
    border-radius: ${sizes.borderRadiusLg};
    cursor: pointer;
`;

const IconWrapper = styled.div`
    min-width: 50px;
`;

const PreviousButton = styled(Button)`
    height: 5rem;
    background-color: ${theme.secondaryButtonBackgroundColour};
    border: 1px solid ${theme.secondaryButtonBorderColour};
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
`;

const NextButton = styled(Button)`
    height: 5rem;
    justify-content: end;
    background-color: ${theme.primaryButtonBackgroundColour};
    border: 1px solid ${theme.primaryButtonBorderColour};
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
    }
    &:focus p {
        color: ${theme.primaryButtonFocusTextColour};
    }
    &:focus svg path {
        fill: ${theme.primaryButtonFocusTextColour};
    }
    &:hover {
        background-color: ${theme.primaryButtonHoverBackgroundColour};
    }
    &:hover p {
        color: ${theme.primaryButtonTextColour};
    }
    &:hover svg path {
        fill: ${theme.primaryButtonTextColour};
    }
`;

const SkipButton = styled(Button)`
    height: 5rem;
    justify-content: center;
    background-color: ${theme.secondaryButtonBackgroundColour};
    border: 1px solid ${theme.secondaryButtonBackgroundColour};
    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
`;

const ButtonText = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    margin: ${sizes.spacingLg} ${sizes.spacingMd};
    width: 900px;
`;

const CourseNavigationContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${theme.adminSubpageHeaderColour};
`;
