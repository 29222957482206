import { ButtonRowRight, Headline5 } from 'components/Elements';
import strings from 'strings/strings.json';
import React from 'react';
import RadioFields, { RadioFieldOption } from 'components/forms/RadioFields';
import useNeveForm from 'components/forms/NeveForm';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import styled from 'styled-components';
import { sizes } from 'theme';
import { Component } from './CreateCourseSwitch';

enum CreateCourseMethod {
    MANUAL = 0,
    SCORM = 1,
    POWERPOINT = 2,
}

type CreateCourseOption = {
    type: CreateCourseMethod;
    name: string;
    description: string;
    aria: string;
};

type CreateCourseMethodInput = {
    type: 0 | 1 | null;
};

type SelectCreateCourseMethodProps = {
    setComponent: React.Dispatch<React.SetStateAction<Component>>;
};

export default function SelectCreateCourseMethodForm({ setComponent }: SelectCreateCourseMethodProps): JSX.Element {
    const [error, setError] = React.useState<boolean>(false);

    const { register, handleSubmit } = useNeveForm<CreateCourseMethodInput>({
        type: null,
    });

    const options: CreateCourseOption[] = [
        {
            type: CreateCourseMethod.MANUAL,
            name: strings.createCourseModal.createCourseMethod.manual.name,
            description: strings.createCourseModal.createCourseMethod.manual.aria,
            aria: strings.createCourseModal.createCourseMethod.manual.aria,
        },
        {
            type: CreateCourseMethod.SCORM,
            name: strings.createCourseModal.createCourseMethod.scorm.name,
            description: strings.createCourseModal.createCourseMethod.scorm.aria,
            aria: strings.createCourseModal.createCourseMethod.scorm.aria,
        },
        {
            type: CreateCourseMethod.POWERPOINT,
            name: strings.createCourseModal.createCourseMethod.powerPoint.name,
            description: strings.createCourseModal.createCourseMethod.powerPoint.aria,
            aria: strings.createCourseModal.createCourseMethod.powerPoint.aria,
        },
    ];

    const radioFieldOptions: RadioFieldOption[] = options.map((option) => {
        return {
            value: option.type,
            title: option.name,
            description: option.aria,
            aria: option.aria,
            inputProps: register('type'),
            name: 'type',
        };
    });

    const submit = (formData: CreateCourseMethodInput) => {
        setError(false);

        if (formData.type === null) {
            setError(true);
            return;
        }

        const selection = options.find((x) => +x.type === +formData.type!);
        switch (selection!.type) {
            case CreateCourseMethod.MANUAL:
                setComponent('createCourseManual');
                break;
            case CreateCourseMethod.SCORM:
                setComponent('createCourseSCORM');
                break;
            case CreateCourseMethod.POWERPOINT:
                setComponent('createCoursePowerPoint');
                break;
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit(submit)}>
            <Headline5>{strings.createCourseModal.createCourseMethod.title}</Headline5>
            <RadioFields options={radioFieldOptions} maxWidth="none" />
            <ButtonRowRight>
                {error && <ErrorNotifierSlim description={strings.createCourseModal.createCourseMethod.error} />}
                <PrimaryButton
                    type="submit"
                    title={strings.createCourseModal.createCourseMethod.continueButton.title}
                    aria={strings.createCourseModal.createCourseMethod.continueButton.aria}
                />
            </ButtonRowRight>
        </FormContainer>
    );
}

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;
