import styled from 'styled-components';
import { sizes, theme } from 'theme';

export const CourseList = styled.div`
    margin-bottom: ${sizes.spacingXl};
`;

export const CourseCardsContainer = styled.div`
    --grid-min-col-size: 500px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(var(--grid-min-col-size), 100%), 1fr));
    gap: 1.5rem;
`;

export const Border = styled.div`
    border-bottom: 1px solid ${theme.secondaryButtonDisabledBackgroundColour};
`;

export const LearnerCardBase = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    border-top: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-right: 1px solid var(--neutral-05, ${theme.cardBorder});
    border-bottom: 3px solid var(--neutral-05, ${theme.cardBorder});
    border-left: 1px solid var(--neutral-05, ${theme.cardBorder});
`;
