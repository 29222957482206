import React from 'react';
import strings from '../../../strings/strings.json';
import { AuthContext } from 'contextProviders/AuthContext';
import useGetRequest from 'hooks/useGetRequest';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { FileDocument } from 'models/FileDocument';
import SelectFileForm from './SelectFileForm';
import { FileMetaData, FileType } from 'components/fileUpload/strategies/strategies';
import Modal from 'components/modals/Modal';

type FileSelectionComponentProps = {
    setFileFromLocal: (newFile: File | null | undefined) => void;
    setFileFromProvider: (video: FileDocument) => void;
    setFileFromLocalMetaData: (newMetaData: FileMetaData | undefined) => void;
    disableButton: boolean;
    selectFileButtonRef: React.RefObject<HTMLButtonElement>;
};

export function VideoSelectionComponent({
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    selectFileButtonRef,
}: FileSelectionComponentProps) {
    const authContext = React.useContext(AuthContext);
    const userOrganisation = authContext.userData.organisation;
    const { data: videos, loading: loadingVideos } = useGetRequest<FileDocument[]>(
        `organisations/${userOrganisation?.id}/documents?document_type=VIDEO`,
    );

    return (
        <FileSelectionComponent
            buttonTitle={strings.learningMaterialsForm.selectVideoButton}
            buttonAria={strings.learningMaterialsForm.selectVideoButtonAria}
            setFileFromLocal={setFileFromLocal}
            setFileFromProvider={setFileFromProvider}
            setFileFromLocalMetaData={setFileFromLocalMetaData}
            disableButton={disableButton}
            organisationName={userOrganisation?.name ?? ''}
            filesFromProvider={videos}
            loadingFiles={loadingVideos}
            chosenFileType={FileType.VIDEO}
            selectFileButtonRef={selectFileButtonRef}
        />
    );
}

export function ImageSelectionComponent({
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    selectFileButtonRef,
}: FileSelectionComponentProps) {
    const authContext = React.useContext(AuthContext);
    const userOrganisation = authContext.userData.organisation;
    const { data: images, loading: loadingImages } = useGetRequest<FileDocument[]>(
        `organisations/${userOrganisation?.id}/documents?document_type=IMAGE`,
    );

    return (
        <FileSelectionComponent
            buttonTitle={strings.learningMaterialsForm.selectImageButton}
            buttonAria={strings.learningMaterialsForm.selectImageButtonAria}
            setFileFromLocal={setFileFromLocal}
            setFileFromProvider={setFileFromProvider}
            disableButton={disableButton}
            organisationName={userOrganisation?.name ?? ''}
            filesFromProvider={images}
            loadingFiles={loadingImages}
            setFileFromLocalMetaData={setFileFromLocalMetaData}
            chosenFileType={FileType.IMAGE}
            selectFileButtonRef={selectFileButtonRef}
        />
    );
}

export function AudioSelectionComponent({
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    selectFileButtonRef,
}: FileSelectionComponentProps) {
    const authContext = React.useContext(AuthContext);
    const userOrganisation = authContext.userData.organisation;
    const { data: audio, loading: loadingAudio } = useGetRequest<FileDocument[]>(
        `organisations/${userOrganisation?.id}/documents?document_type=AUDIO`,
    );

    return (
        <FileSelectionComponent
            buttonTitle={strings.learningMaterialsForm.audio.selectAudioButton}
            buttonAria={strings.learningMaterialsForm.audio.selectAudioButtonAria}
            setFileFromLocal={setFileFromLocal}
            setFileFromProvider={setFileFromProvider}
            disableButton={disableButton}
            organisationName={userOrganisation?.name ?? ''}
            filesFromProvider={audio}
            loadingFiles={loadingAudio}
            setFileFromLocalMetaData={setFileFromLocalMetaData}
            chosenFileType={FileType.AUDIO}
            selectFileButtonRef={selectFileButtonRef}
        />
    );
}

type InnerFileSelectionComponentProps = {
    buttonTitle: string;
    buttonAria: string;
    setFileFromLocal: (newFile: File | null | undefined) => void;
    setFileFromProvider: (video: FileDocument) => void;
    setFileFromLocalMetaData: (newMetaData: FileMetaData | undefined) => void;
    disableButton: boolean;
    organisationName: string;
    filesFromProvider: FileDocument[] | null;
    loadingFiles: boolean;
    chosenFileType: FileType;
    selectFileButtonRef: React.RefObject<HTMLButtonElement>;
};

function FileSelectionComponent({
    buttonTitle,
    buttonAria,
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    disableButton,
    organisationName,
    filesFromProvider,
    loadingFiles,
    chosenFileType,
    selectFileButtonRef,
}: InnerFileSelectionComponentProps) {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    return (
        <>
            <SecondaryButton
                title={buttonTitle}
                aria={buttonAria}
                onClick={() => setModalOpen(true)}
                disabled={disableButton}
                buttonRef={selectFileButtonRef}
                fullwidth
            />
            {modalOpen && (
                <Modal
                    headlineText={strings.selectFileForm.title}
                    onClickCloseButton={() => setModalOpen(false)}
                    modalBody={
                        <SelectFileForm
                            organisationName={organisationName}
                            filesFromProvider={filesFromProvider ?? []}
                            loadingFiles={loadingFiles}
                            setFileFromLocal={setFileFromLocal}
                            setFileFromProvider={setFileFromProvider}
                            setFileFromLocalMetaData={setFileFromLocalMetaData}
                            closeModal={() => {
                                setModalOpen(false);
                                selectFileButtonRef.current?.focus();
                            }}
                            chosenFileType={chosenFileType}
                        />
                    }
                />
            )}
        </>
    );
}
