import { Module } from './Module';
import { LearningObjective } from './LearningObjective';
import { Organisation } from './Organisation';
import { UserModel } from './User';

export type Course = {
    id: string;
    title: string;
    description: string;
    modules: Module[];
    dateCreated: string;
    dateUpdated: string;
    category: string;
    learningObjectives: LearningObjective[];
    published: boolean;
    userId: string;
    organisation: Organisation;
    user?: UserModel;
};

export function CourseDetailsAreComplete(course: Course): boolean {
    return !!course.title;
}

export function CourseHasAtLeastOneLearningObjective(course: Course): boolean {
    return course.learningObjectives.length >= 1;
}

export function EveryModuleHasActivity(course: Course): boolean {
    return course.modules.length >= 1 && course.modules.every((m) => m.activities.length >= 1);
}

export function CourseGeneratedContentIsApproved(course: Course): boolean {
    return course.modules
        .flatMap((m) => m.activities?.flatMap((a) => a?.generateContentStatuses || []))
        .filter((s) => s?.dateGenerated)
        .every((s) => s.dateApproved);
}

export function CourseIsReadyToPublish(course: Course): boolean {
    const courseTitleComplete = !!course.title;
    const learningObjectivesComplete = course.learningObjectives.length >= 1;
    const modulesAndActivitiesComplete =
        course.modules.length >= 1 && course.modules.every((m) => m.activities.length >= 1);
    const generatedContentComplete = CourseGeneratedContentIsApproved(course);

    const courseReadyToPublish =
        courseTitleComplete && learningObjectivesComplete && modulesAndActivitiesComplete && generatedContentComplete;

    return courseReadyToPublish;
}
