import 'styled-components';

const geyserBlue = '#D0DADF';
const daintreeBlue = '#002A3C';
const sweetCorn = '#fcf090';
const onahau = '#CBF0FF';
const cerulean = '#007499';
const prussianBlue = '#004866';
const richElectricBlue = '#0199d8';
const mystic = '#E4ECEF';
const fiord = '#385461';
const white = '#ffffff';
const offWhite = '#F6F5F3';
const timGray = '#00242c';
const timRed = '#ac1d00';
const backgroundGreen = '#E2FBDC';
const deepGreen = '#21850C';
const blueSapphire = '#185D63';
const linen = '#F9EEE7';
const greyishBlue = '#9DB3BC';
const platinum = '#E5E5E5';
const lightBlue = '#E9F1F5';
const lightGrey = '#C2C2C2';
const purple = '#9381A5';

// https://www.figma.com/file/EEnbtBmjVv8tesa8utz2DP/Neve-Design-System?node-id=1501%3A1300
// this theme should mimic the designs in this document as closely as possible
export const theme = {
    fontFamilyHeader: `"Work Sans", serif`,
    fontFamilyMain: `"Open Sans", serif`,

    // brand colours
    primaryBrandColour: cerulean,
    secondaryBrandColour: sweetCorn,

    // text
    textColour: timGray,
    textColourInverted: white,
    textSecondaryColour: fiord,

    // positive and negative messaging
    errorColour: timRed,
    successColour: deepGreen,

    // primary buttons
    primaryButtonTextColour: white,
    primaryButtonFocusTextColour: cerulean,
    primaryButtonBackgroundColour: cerulean,
    primaryButtonBorderColour: richElectricBlue,
    primaryButtonHoverBackgroundColour: prussianBlue,
    primaryButtonFocusBackgroundColour: onahau,
    primaryButtonDisabledBackgroundColour: geyserBlue,

    // secondary buttons
    secondaryButtonTextColour: cerulean,
    secondaryButtonAlternateTextColour: timGray,
    secondaryButtonBackgroundColour: white,
    secondaryButtonBackgroundSelectedColour: greyishBlue,
    secondaryButtonBorderColour: richElectricBlue,
    secondaryButtonAlternateBorderColour: timGray,
    secondaryButtonHoverBackgroundColour: mystic,
    secondaryButtonFocusBackgroundColour: onahau,
    secondaryButtonDisabledBackgroundColour: geyserBlue,
    secondaryButtonIconColor: daintreeBlue,
    secondaryButtonIconColorDanger: timRed,

    // course editor
    courseEditButtonBackgroundColour: lightBlue,
    courseEditHintBackgroundColour: lightBlue,

    // links
    linkColour: cerulean,
    linkFocusBorderColour: richElectricBlue,
    linkFocusBackgroundColour: onahau,
    linkHoverBackgroundColour: mystic,
    linkVisitedColour: prussianBlue,

    // neutrals
    neutralZero: daintreeBlue,
    neutralOne: fiord,
    neutralFour: platinum,

    // inputs
    inputBorderColour: fiord,
    inputActiveColour: cerulean,
    inputBackgroundError: white,
    inputRadioButton: {
        borderColour: fiord,
        activeRadioColour: cerulean,
        focusBackgroundColour: onahau,
        hoverBackgroundColour: platinum,
        checkedBorderColour: richElectricBlue,
    },

    // backgrounds
    contentBackgroundColourTrainer: offWhite,
    contentBackgroundColourLearner: offWhite,
    platformBackgroundColour: white,
    editCourseBackgroundColour: prussianBlue,
    footerBackground: prussianBlue,
    organisationTrainerBackgroundColour: white,
    learnerCourseBackgroundColour: lightBlue,

    // GENERIC COMPONENTS

    // notifications
    notificationSuccessBackground: backgroundGreen,
    notificationSuccessBorder: deepGreen,
    notificationWarningBackground: sweetCorn,
    notificationWarningBorder: daintreeBlue,
    notificationDangerBackground: linen,
    notificationDangerBorder: timRed,
    notificationInfoBackground: platinum,
    notificationInfoBorder: fiord,
    notificationAccessibilityBackground: lightBlue,
    notificationAccessibilityBorder: fiord,

    // tabs
    inactiveTab: daintreeBlue,
    activeTab: cerulean,

    // banners
    trainerDashboardBannerBackground: blueSapphire,
    trainerDashboardBannerImageBackground: onahau,
    adminDashboardBannerBackground: greyishBlue,
    subpageHeaderBackground: linen,
    adminSubpageHeaderColour: mystic,
    courseBannerBackground: linen,
    orgAdminHeaderColour: onahau,

    // cards
    cardBackground: white,
    cardSecondaryBackgroundColour: offWhite,
    cardThirdBackgroundColour: onahau,
    cardBorder: geyserBlue,
    cardSecondaryBorder: fiord,
    iconBackgroundColour: geyserBlue,

    // navigation bar
    navigationBar: {
        background: white,
        buttonFocusBackground: onahau,
        buttonFocusBorder: cerulean,
        buttonFocusText: cerulean,
        buttonText: daintreeBlue,
        buttonHoverBackground: mystic,
        border: fiord,
        divider: geyserBlue,
    },
    cardNavigation: {
        borderFocused: richElectricBlue,
        backgroundFocused: onahau,
        backgroundHover: platinum,
        heading: cerulean,
        headingHover: prussianBlue,
    },
    answersProgressBar: {
        backgroundTotal: white,
        backgroundAnswered: deepGreen,
        borderTotal: geyserBlue,
    },
    learnerActivityCard: {
        completedBackground: backgroundGreen,
        completedNotificationBackground: deepGreen,
        iconCircleBackground: cerulean,
    },
    circularProgressBar: {
        darkVersionIndicatorColour: deepGreen,
        darkVersionIndicatorTrackColour: white,
        darkVersionBackgroundColour: daintreeBlue,
        darkVersionTextColour: white,
        lightVersionBackground: geyserBlue,
        lightVersionFilledColour: deepGreen,
    },

    // stepper
    stepperBackgroundColour: daintreeBlue,
    stepperTextColour: white,
    stepperIncompleteLineColour: fiord,
    stepperCompleteLineColour: white,

    // accessibility panel
    accessibilityPanel: {
        buttonBackgroundColour: cerulean,
        textColour: white,
        buttonBorder: geyserBlue,
        buttonFocusBorder: richElectricBlue,
        butonFocusBackgroundColour: cerulean,
        backgroundColour: daintreeBlue,
        openPanelButtonBackground: sweetCorn,
    },
    spinnerColour: prussianBlue,
    roleTagBackgroundColour: linen,
    tableHeaderBackgroundColour: platinum,
    progressBar: {
        background: mystic,
        foreground: cerulean,
    },
    stepCheck: {
        circleColourIncomplete: lightGrey,
        circleColour: prussianBlue,
        checkColour: white,
    },
    aiLogoColour: purple,
    betaLabelBackgroundColor: purple,
    // colours
    mystic,
    fiord,
};

export const animations = {
    speed: '0.1s',
};

export const sizes = {
    appMaxWidth: 1440,
    appNavWidth: 1250,
    appMaxWidthWithoutPadding: 1270,
    edgePadding: '15px',
    borderRadiusMd: '4px',
    borderRadiusLg: '8px',
    borderRadiusXl: '12px',
    headerHeight: '80px',
    headerMobileHeight: '113px',
    headerMobileHeightSlim: '85px',
    headerPadding: '32px',
    spacingXs: '4px',
    spacingSm: '8px',
    spacingMd: '16px',
    spacingRg: '24px',
    spacingLg: '32px',
    spacingXl: '64px',
    rem: {
        md: '1.75rem',
        rg: '1.25rem',
        sm: '0.5rem',
        xs: '0.25rem',
    },
};

export const breakpoints = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
};

export enum FONTSIZE {
    Headline1 = '4rem', // 64px
    Headline2 = '3rem', // 48px
    Headline3 = '2.5rem', // 40px
    Headline4 = '2rem', // 32px
    Headline5 = '1.5rem', // 24px
    Headline6 = '1.25rem', // 24px
    BodyLarge = '1.25rem', // 20px
    BodyRegular = '1.125rem', // 18px
    BodySmall = '1rem', // 16px
}

export enum MOBILEFONTSIZE {
    Headline1 = '3rem', // 48px
    Headline2 = '2.5rem', // 40px
    Headline3 = '2rem', // 32px
    Headline4 = '1.5rem', // 24px
    Headline5 = '1.25rem', // 20px
    Headline6 = '1.25rem', // 20px
}
