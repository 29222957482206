import { Course, CourseHasAtLeastOneLearningObjective } from '../../../../models/Course';
import useModifyRequest from '../../../../hooks/useModifyRequest';
import * as React from 'react';
import { APIError } from '../../../../lib/_api-helpers';
import { logError } from '../../../../lib/debug-helpers';
import {
    createErrorMessage,
    createFieldErrorFromAPIError,
    createGlobalErrorFromAPIError,
    fieldErrorCodes,
} from '../../../../shared/error-messages';
import { ErrorMessage, FormCardWithGaps } from '../../../../components/forms/FormComponents';
import TextField from '../../../../components/forms/TextField';
import strings from '../../../../strings/strings.json';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { LearningObjective } from '../../../../models/LearningObjective';
import styled from 'styled-components';
import { sizes, theme } from '../../../../theme';
import useNeveForm from 'components/forms/NeveForm';
import Alert from 'components/alert/Alert';
import SecondaryButton, { SecondaryButtonColour } from '../../../../components/buttons/SecondaryButton';
import AddIcon from '../../../../assets/icons/controls/AddIcon';
import BinIcon from '../../../../assets/icons/controls/BinIcon';
import { IconSize } from '../../../../assets/icons/icon-sizes';
import useFormBlocker from 'components/forms/useFormBlocker';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import NavButton from 'components/buttons/NavButton';
import useWindowWidth from 'hooks/useWindowWidth';
import { StepId } from 'pages/edit-course/CourseEditorPage';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import {
    LeftNavButtonContainer,
    SaveButtonAndNotificationContainer,
    RightNavButtonContainer,
} from 'pages/edit-course/shared-styles/courseBuilderFormComponents';

function buildObjectivesFromInputs(learningObjectives: LearningObjectiveInput[]): LearningObjective[] {
    return (
        learningObjectives
            // when an objective is unregistered, the slot in the array is set to undefined
            .filter((x) => x !== undefined && x.description.trim() !== '')
            .map((learningObjective, index) => ({
                description: learningObjective.description,
                order: index,
            }))
    );
}

type LearningObjectiveInput = {
    description: string;
    order: number;
};

type CourseLearningObjectivesInput = {
    learningObjectives: LearningObjectiveInput[];
};

type CourseLearningObjectivesFormProps = {
    course: Course;
    refetchCourse?: () => Promise<void>;
    showIncompleteStepsWarning?: boolean;
};

export default function CourseLearningObjectivesForm({
    course,
    refetchCourse,
    showIncompleteStepsWarning,
}: CourseLearningObjectivesFormProps): JSX.Element {
    const navigate = useNavigate();
    const context = useContext(EditCourseContext);
    const { isMobileWidth } = useWindowWidth();
    const { modifyData: updateCourseLearningObjectives, loading } = useModifyRequest(
        `courses/${course.id}/learning-objectives`,
        'PUT',
    );

    const initialLearningObjectives =
        course.learningObjectives === undefined || course.learningObjectives.length === 0
            ? [0]
            : course.learningObjectives.map((learningObjective, index) => index);

    const initialObjectiveNumber =
        course.learningObjectives === undefined || course.learningObjectives.length === 0
            ? 1
            : course.learningObjectives.length + 1;

    const [learningObjectives, setLearningObjectives] = React.useState<number[]>(initialLearningObjectives);
    const [nextObjectiveNumber, setNextObjectiveNumber] = React.useState(initialObjectiveNumber);

    const {
        register,
        unregister,
        handleSubmit,
        setError,
        formState: { errors, isDirty },
        clearErrors,
        setFocus,
    } = useNeveForm<CourseLearningObjectivesInput>({
        learningObjectives: course.learningObjectives.map((x) => ({
            description: x.description,
            order: x.order,
        })),
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(isDirty);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    React.useEffect(() => {
        setFormDirty(isDirty);
    }, [isDirty]);

    React.useEffect(() => {
        setFocus(`learningObjectives.${nextObjectiveNumber - 1}.description`);
    }, [nextObjectiveNumber, setFocus]);

    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [saved, setSaved] = React.useState<boolean>(false);
    const [alertOpen, setAlertOpen] = React.useState(false);

    React.useEffect(() => {
        if (saved) {
            setFormDirty(false);
        }
    }, [saved]); // eslint-disable-line

    const toggleAlert = () => {
        setAlertOpen(!alertOpen);
    };

    const onSubmit = async (formData: CourseLearningObjectivesInput) => {
        setSaved(false);

        const response = await updateCourseLearningObjectives({
            learningObjectives: formData.learningObjectives
                ? buildObjectivesFromInputs(formData.learningObjectives)
                : [],
        });

        if (alertOpen) {
            setAlertOpen(false);
        }

        const { errors } = response;

        if (errors && errors.length > 0) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        setSaved(true);
        setGlobalErrorMessage('');
        !!refetchCourse && (await refetchCourse());
    };

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof CourseLearningObjectivesInput, createFieldErrorFromAPIError(field, code));
        });
    }

    function addnewObjective() {
        setLearningObjectives((prevState) => [...prevState, nextObjectiveNumber]);
        setNextObjectiveNumber((prevState) => prevState + 1);
        clearErrors('learningObjectives');
        setFormDirty(true);
    }

    function removeObjective(objectiveNumber: number) {
        const newLearningObjectives = learningObjectives.filter((x) => x !== objectiveNumber);
        unregister(`learningObjectives.${objectiveNumber}`);
        setLearningObjectives(newLearningObjectives);
        setFormDirty(true);
    }

    return (
        <>
            {!CourseHasAtLeastOneLearningObjective(course) && showIncompleteStepsWarning && (
                <NotificationContainer>
                    <Notification
                        title={strings.courseEditorPage.noLearningObjectivesWarning.title}
                        notificationType={NotificationType.WARNING}
                        maxWidth="100%"
                    />
                </NotificationContainer>
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
                {!isMobileWidth && !course.published && (
                    <LeftNavButtonContainer>
                        <NavButton
                            title={strings.courseLearningObjectivesPage.courseDetailsNavButton.title}
                            aria={strings.courseLearningObjectivesPage.courseDetailsNavButton.aria}
                            back
                            onClick={() =>
                                navigate(`/edit-course/${context.course!.id}`, {
                                    state: {
                                        stepId: 'courseDetails' as StepId,
                                    },
                                })
                            }
                        />
                    </LeftNavButtonContainer>
                )}
                {learningObjectives.map((i, actualIndex) => {
                    let objectiveErrorMessage = '';

                    if (errors.learningObjectives && errors.learningObjectives[i] !== undefined) {
                        objectiveErrorMessage = errors.learningObjectives[i]?.description?.message ?? '';
                    }

                    return (
                        <React.Fragment key={i}>
                            <ObjectiveCard>
                                <TextField
                                    fieldName={`learning-objective-${i}`}
                                    maxLength={500}
                                    labelText={`${strings.courseLearningObjectivesPage.learningObjectiveInputLabel} ${
                                        actualIndex + 1
                                    }`}
                                    inputAria={`${
                                        strings.courseLearningObjectivesPage.learningObjectiveInputLabelAria
                                    } ${actualIndex + 1}`}
                                    inputProps={register(`learningObjectives.${i}.description`, {
                                        maxLength: {
                                            value: 500,
                                            message: createErrorMessage(
                                                strings.courseLearningObjectivesPage.learningObjectiveInputLabel,
                                                fieldErrorCodes.maxLength,
                                            ),
                                        },
                                        onChange: () => saved && setSaved(false),
                                    })}
                                    errorMessage={objectiveErrorMessage}
                                />
                                <SecondaryButton
                                    title={strings.courseLearningObjectivesPage.removeButton}
                                    aria={`${strings.courseLearningObjectivesPage.removeButtonAria} ${
                                        actualIndex + 1
                                    }`}
                                    onClick={() => removeObjective(i)}
                                    icon={<BinIcon size={IconSize.MEDIUM} />}
                                    alternateColour={SecondaryButtonColour.ALTERNATE}
                                />
                            </ObjectiveCard>
                        </React.Fragment>
                    );
                })}
                {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
                {learningObjectives.length >= 20 ? null : (
                    <ButtonContainer>
                        <SecondaryButton
                            type="button"
                            fullwidth
                            title={strings.courseLearningObjectivesPage.addObjectiveButton}
                            aria={strings.courseLearningObjectivesPage.addObjectiveButtonAria}
                            icon={<AddIcon />}
                            onClick={() => addnewObjective()}
                        />
                    </ButtonContainer>
                )}
                <>
                    {course.published ? (
                        <SaveButtonAndNotificationContainer>
                            {saved && (
                                <Notification
                                    notificationType={NotificationType.SUCCESS}
                                    title={strings.general.saveSuccess}
                                />
                            )}
                            <PrimaryButton
                                title={strings.courseLearningObjectivesPage.submitButton}
                                aria={strings.courseLearningObjectivesPage.submitButtonAria}
                                disabled={loading}
                                onClick={toggleAlert}
                            />
                        </SaveButtonAndNotificationContainer>
                    ) : (
                        <SaveButtonAndNotificationContainer>
                            {saved && (
                                <Notification
                                    notificationType={NotificationType.SUCCESS}
                                    title={strings.general.saveSuccess}
                                />
                            )}
                            <PrimaryButton
                                type="submit"
                                title={strings.courseLearningObjectivesPage.submitButton}
                                aria={strings.courseLearningObjectivesPage.submitButtonAria}
                                disabled={loading}
                            />
                        </SaveButtonAndNotificationContainer>
                    )}
                    {!isMobileWidth && !course.published && (
                        <RightNavButtonContainer>
                            <NavButton
                                title={strings.courseLearningObjectivesPage.structureAndContentNavButton.title}
                                aria={strings.courseLearningObjectivesPage.structureAndContentNavButton.aria}
                                onClick={() =>
                                    navigate(`/edit-course/${context.course!.id}`, {
                                        state: {
                                            stepId: 'courseStructureAndContent' as StepId,
                                        },
                                    })
                                }
                            />
                        </RightNavButtonContainer>
                    )}
                </>
            </Form>
            {alertOpen && (
                <Alert
                    buttonText={strings.courseLearningObjectivesPage.alertButton}
                    ariaButtonText={strings.courseLearningObjectivesPage.alertButtonAria}
                    alertText={strings.courseLearningObjectivesPage.alertText}
                    closeAlert={toggleAlert}
                    onSubmit={handleSubmit(onSubmit)}
                />
            )}
        </>
    );
}

const Form = styled(FormCardWithGaps)`
    width: 100%;
`;

const NotificationContainer = styled.div`
    width: 100%;
`;

const ObjectiveCard = styled.div`
    background-color: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.borderRadiusLg};
    padding: ${sizes.spacingMd};
`;

const ButtonContainer = styled.div`
    width: 100%;
`;
