import { styled } from 'styled-components';
import { sizes, theme } from 'theme';

export const OrganisationHeaderBlue = styled.div`
    display: flex;
    justify-content: center;
    background: ${theme.orgAdminHeaderColour};
    width: 100%;
    min-height: 260px;
`;

export const LogoImage = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;
