import styled from 'styled-components';
import { breakpoints, sizes } from '../../../theme';
import { Card } from '../../../components/Elements';

export const FormWithGaps = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

export const MeetingCard = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

export const MeetingHeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
`;

export const MeetingDetailSection = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingMd} 0;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: flex-start;
        gap: ${sizes.spacingMd};
        padding: 0;
    }
`;

export const MeetingInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingSm};
`;
