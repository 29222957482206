import Stepper from 'components/stepper/Stepper';
import { QuestionnaireActivity, createEmptyQuestionnaireActivity } from 'models/inputModels/QuestionnaireActivity';
import EmptyForm from '../EmptyForm';
import QuestionnaireDetailsForm from './QuestionnaireDetailsForm';
import QuestionnaireQuestionsForm from './QuestionnaireQuestionsForm';
import strings from '../../../../strings/strings.json';
import RadioFields, { RadioFieldOption } from 'components/forms/RadioFields';
import useNeveForm from 'components/forms/NeveForm';
import React from 'react';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline4, ButtonRowRight } from 'components/Elements';
import { ErrorMessage } from 'components/forms/FormComponents';
import styled from 'styled-components';
import { sizes } from 'theme';
import { Module } from 'models/Module';
import { ActivityType } from 'models/Activity';
import QuestionnaireGenerateForm from './GenerateModuleQuestionnaireForm';
import { MainContainer, DarkFormHead } from './SharedQuestionnaireComponents';

enum QuestionnaireType {
    MANUAL = 0,
    GENERATE = 1,
}

type QuestionnaireTypeOption = {
    type: QuestionnaireType;
    name: string;
    description: string;
    aria: string;
    content: JSX.Element;
    disabled: boolean;
};

type QuestionnaireTypeInput = {
    type: 0 | 1 | null;
};

type QuestionnaireManualCreateStepperProps = {
    returnToModulesList: () => void;
    module: Module;
};

export default function QuestionnaireSelectTypeForm({
    returnToModulesList,
    module,
}: QuestionnaireManualCreateStepperProps): JSX.Element {
    const { register, handleSubmit } = useNeveForm<QuestionnaireTypeInput>({
        type: null,
    });

    const [error, setError] = React.useState<string | null>(null);
    const [content, setContent] = React.useState<JSX.Element | null>(null);

    const tasks = module.activities
        .filter((a) => a.type === ActivityType.TASK)
        .map((t) => {
            return {
                id: t.id,
                title: t.title,
            };
        });

    const options: QuestionnaireTypeOption[] = [
        {
            type: QuestionnaireType.MANUAL,
            name: strings.questionnaireSelectTypeForm.options.manualQuestionnaire.name,
            description: strings.questionnaireSelectTypeForm.options.manualQuestionnaire.description,
            aria: strings.questionnaireSelectTypeForm.options.manualQuestionnaire.description,
            content: <QuestionnaireManualCreateStepper returnToModulesList={returnToModulesList} module={module} />,
            disabled: false,
        },
        {
            type: QuestionnaireType.GENERATE,
            name: strings.questionnaireSelectTypeForm.options.generateModuleQuestionnaire.name,
            description: tasks.length
                ? strings.questionnaireSelectTypeForm.options.generateModuleQuestionnaire.description
                : strings.questionnaireSelectTypeForm.options.generateModuleQuestionnaire.descriptionDisabled,
            aria: tasks.length
                ? strings.questionnaireSelectTypeForm.options.generateModuleQuestionnaire.description
                : strings.questionnaireSelectTypeForm.options.generateModuleQuestionnaire.descriptionDisabled,
            content: (
                <QuestionnaireGenerateForm
                    moduleId={module.id}
                    moduleTitle={module.title}
                    tasks={tasks}
                    returnToModulesList={returnToModulesList}
                />
            ),
            disabled: !tasks.length,
        },
    ];

    const radioFieldOptions: RadioFieldOption[] = options.map((option) => {
        return {
            value: option.type,
            title: option.name,
            description: option.description,
            aria: option.aria,
            inputProps: register('type'),
            name: 'type',
            disabled: option.disabled,
        };
    });

    const submitQuestionnaireType = (formData: QuestionnaireTypeInput) => {
        if (formData.type === null) {
            setError(strings.activityTypeSelectionPage.typeValidationError);
            return;
        }

        const selectedContent = options.find(
            (x) => x.type === (formData.type === null ? 0 : +formData?.type),
        )?.content;

        if (selectedContent) {
            setContent(selectedContent);
        }
    };

    return content ? (
        content
    ) : (
        <MainContainer>
            <DarkFormHead />
            <FormContainer onSubmit={handleSubmit(submitQuestionnaireType)}>
                <Headline4>{strings.questionnaireSelectTypeForm.buildQuestonnaireHeadline}</Headline4>
                <RadioFields options={radioFieldOptions} />
                {error && <ErrorMessage id={`error-message`}>{error}</ErrorMessage>}
                <ButtonRowRight>
                    <PrimaryButton
                        type="submit"
                        title={strings.questionnaireSelectTypeForm.submitButton.title}
                        aria={strings.questionnaireSelectTypeForm.submitButton.aria}
                    />
                </ButtonRowRight>
            </FormContainer>
        </MainContainer>
    );
}

export function QuestionnaireManualCreateStepper({
    module,
    returnToModulesList,
}: QuestionnaireManualCreateStepperProps): JSX.Element {
    return (
        <Stepper<QuestionnaireActivity>
            defaultObject={createEmptyQuestionnaireActivity(module.id)}
            steps={[
                { name: 'Questionnaire', Form: EmptyForm },
                { name: 'Details', Form: QuestionnaireDetailsForm },
                { name: 'Questions', Form: QuestionnaireQuestionsForm },
            ]}
            defaultStep={1}
            complete={returnToModulesList}
        />
    );
}

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingMd};
`;
