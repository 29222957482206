import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
import Navigation from './components/Navigation';
import { NavigationSlim } from './components/NavigationSlim';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contextProviders/AuthContext';
import { RoleType } from '../models/User';
import strings from '../strings/strings.json';
import { CustomBackgroundColourContext } from '../contextProviders/CustomBackgroundColourContext';
import { PreviewContext, PreviewProvider } from 'contextProviders/PreviewContext';
import NavigationPublic from './components/NavigationPublic';
import { EditCourseProvider } from 'contextProviders/EditCourseContext';
import SupportOnlyNavigation from './components/SupportOnlyNavigation';

export function InitialLoginLayout(): JSX.Element {
    const { userData, isAuthenticated } = React.useContext(AuthContext);

    if (isAuthenticated && userData.roles.length) {
        return <Navigate to="/" />;
    }

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourLearner}
            navigationComponent={<SupportOnlyNavigation />}
        />
    );
}

export function PublicLayout(): JSX.Element {
    const { isAuthenticated, userData } = React.useContext(AuthContext);

    if (isAuthenticated && !userData.roles.length) {
        return <Navigate to="/initial-login" />;
    }

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourLearner}
            navigationComponent={isAuthenticated ? <Navigation /> : <NavigationPublic />}
        />
    );
}

export function AdminLayout(): JSX.Element {
    const { userData, setNewActiveRole } = React.useContext(AuthContext);

    if (!userData.roles.includes(RoleType.ADMIN)) {
        return <Navigate to="/" />;
    }

    if (userData.activeRole !== RoleType.ADMIN) setNewActiveRole(RoleType.ADMIN);

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourTrainer}
            navigationComponent={<Navigation />}
        />
    );
}

export function OrganisationLayout(): JSX.Element {
    const { userData, setNewActiveRole } = React.useContext(AuthContext);

    if (!userData.roles.includes(RoleType.ORGANISATION_ADMIN)) {
        return <Navigate to="/" />;
    }

    if (userData.activeRole !== RoleType.ORGANISATION_ADMIN) setNewActiveRole(RoleType.ORGANISATION_ADMIN);

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourLearner}
            navigationComponent={<Navigation />}
        />
    );
}

export function LearnerLayout(): JSX.Element {
    const { userData, setNewActiveRole } = React.useContext(AuthContext);

    if (!userData.roles.includes(RoleType.LEARNER)) {
        return <Navigate to="/" />;
    }

    if (userData.activeRole !== RoleType.LEARNER) setNewActiveRole(RoleType.LEARNER);

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourLearner}
            navigationComponent={<Navigation />}
        />
    );
}

export function LearnerCourseLayout(): JSX.Element {
    const { userData, setNewActiveRole } = React.useContext(AuthContext);

    if (!userData.roles.includes(RoleType.LEARNER)) {
        return <Navigate to="/" />;
    }

    if (userData.activeRole !== RoleType.LEARNER) setNewActiveRole(RoleType.LEARNER);

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourLearner}
            navigationComponent={<Navigation />}
        />
    );
}

export function TrainerLayout(): JSX.Element {
    const { userData } = React.useContext(AuthContext);

    if (
        !userData.roles.some((role) => {
            return role === RoleType.TRAINER || role === RoleType.ORGANISATION_ADMIN;
        })
    ) {
        return <Navigate to="/" />;
    }

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourTrainer}
            navigationComponent={<Navigation />}
        />
    );
}

export function MainLayout(): JSX.Element {
    const { userData } = React.useContext(AuthContext);

    if (!userData.roles.length) return <Navigate to="/" />;

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourLearner}
            navigationComponent={<Navigation />}
        />
    );
}

type State = {
    previewContextEntryPoint: string;
};

export function PreviewLayout(): JSX.Element {
    const { userData } = React.useContext(AuthContext);
    const previewContext = React.useContext(PreviewContext);
    const navigate = useNavigate();

    const { state } = useLocation();
    const [previewEntryPoint] = React.useState((state as State)?.previewContextEntryPoint);

    if (
        !userData.roles.some((role) => {
            return role === RoleType.TRAINER || role === RoleType.ORGANISATION_ADMIN;
        })
    ) {
        return <Navigate to="/" />;
    }

    function closePreview() {
        previewContext.scheduledCourse = undefined;
        navigate(previewEntryPoint ?? '/');
    }

    return (
        <PreviewProvider>
            <AppMainLayout
                contentBackgroundColor={theme.contentBackgroundColourLearner}
                navigationComponent={
                    <NavigationSlim
                        buttonTitle={strings.navigation.closePreviewTitle}
                        buttonAria={strings.navigation.closePreviewAria}
                        buttonOnClick={closePreview}
                    />
                }
            />
        </PreviewProvider>
    );
}

export function LearnerContentLayout(): JSX.Element {
    const { userData } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const params = useParams();
    const { courseScheduleId } = params;

    if (!userData.roles.length) return <Navigate to="/" />;

    function closeModule() {
        navigate(`/learner/${courseScheduleId}`);
    }

    return (
        <AppMainLayout
            contentBackgroundColor={theme.learnerCourseBackgroundColour}
            navigationComponent={
                <NavigationSlim
                    buttonTitle={strings.navigation.closeModuleTitle}
                    buttonAria={strings.navigation.closeModuleAria}
                    buttonOnClick={closeModule}
                />
            }
        />
    );
}

export function EditPublishedCourseLayout(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const { userData } = React.useContext(AuthContext);
    const navigate = useNavigate();

    if (
        !userData.roles.some((role) => {
            return role === RoleType.TRAINER || role === RoleType.ORGANISATION_ADMIN;
        })
    ) {
        return <Navigate to="/" />;
    }

    function closeEditing() {
        navigate(`/trainer/course/${courseId}/view`);
    }

    return (
        <AppMainLayout
            contentBackgroundColor={theme.contentBackgroundColourTrainer}
            navigationComponent={
                <NavigationSlim
                    buttonTitle={strings.navigation.closeEditingTitle}
                    buttonAria={strings.navigation.closeEditingAria}
                    buttonOnClick={closeEditing}
                />
            }
        />
    );
}

export function TrainerEditCourseLayout(): JSX.Element {
    const { userData } = React.useContext(AuthContext);

    if (
        !userData.roles.some((role) => {
            return role === RoleType.TRAINER || role === RoleType.ORGANISATION_ADMIN;
        })
    ) {
        return <Navigate to="/" />;
    }

    return (
        <EditCourseProvider>
            <AppMainLayout
                contentBackgroundColor={theme.contentBackgroundColourTrainer}
                navigationComponent={<Navigation />}
                contentAlignItems={'start'}
            />
        </EditCourseProvider>
    );
}

type AppMainLayoutProps = {
    navigationComponent: JSX.Element;
    contentBackgroundColor: string;
    contentAlignItems?: string;
};

function AppMainLayout({
    navigationComponent,
    contentBackgroundColor,
    contentAlignItems,
}: AppMainLayoutProps): JSX.Element {
    const [customBackgroundColour, setCustomBackgroundColour] = React.useState<string>(contentBackgroundColor);

    const setBackgroundColour = (colour: string | null) => {
        if (colour) {
            setCustomBackgroundColour(colour);
        } else {
            setCustomBackgroundColour(contentBackgroundColor);
        }
    };

    return (
        <CustomBackgroundColourContext.Provider
            value={{ backgroundColour: customBackgroundColour, setBackgroundColour: setBackgroundColour }}
        >
            <App $backgroundColor={customBackgroundColour}>
                <SkipToContentLink href="#content" aria-label={strings.skipToContentLink.aria}>
                    {strings.skipToContentLink.title}
                </SkipToContentLink>
                {navigationComponent}
                <MainContainer id="content" $alignItems={contentAlignItems ?? 'center'}>
                    <Outlet />
                </MainContainer>
            </App>
        </CustomBackgroundColourContext.Provider>
    );
}

const MainContainer = styled.main<{
    $alignItems: string;
}>`
    display: flex;
    flex-direction: column;
    align-items: ${({ $alignItems }) => $alignItems};
    height: 100%;
    width: 100%;
    z-index: 0;
`;

const App = styled.div<{ $backgroundColor: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(p) => p.$backgroundColor};
    min-height: 100vh;
`;

const SkipToContentLink = styled.a`
    position: absolute;
    top: -400px;
    &:focus {
        top: 0;
        left: 10px;
        z-index: 121; //overlay navigation
    }
`;
