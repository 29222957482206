import styled from 'styled-components';
import { sizes, theme } from 'theme';
import { Headline5 } from 'components/Elements';
import NeveLearningLogoMonochrome from 'assets/icons/footer/NeveLearningLogoMonochrome';
import strings from '../../../strings/strings.json';
import YourTrainerCard from './YourTrainerCard';
import { LearnerTrainerInfo } from 'models/LearnerCourse';
import { DOCUMENTS_URL } from 'lib/_api-helpers';

export default function LearnerCoursePageFooter({ trainerInfo }: { trainerInfo: LearnerTrainerInfo }) {
    const profileImageFilepath = trainerInfo.profileImagePath
        ? `${DOCUMENTS_URL}/${trainerInfo.profileImagePath}`
        : '';

    return (
        <FooterBackground>
            <FooterInfoSection>
                <Headline5 $inverted>
                    {strings.learnerCoursePage.footerTrainerText} {trainerInfo.organisationName}
                </Headline5>
                <YourTrainerCard
                    profileImageFilepath={profileImageFilepath}
                    trainerName={`${trainerInfo.firstName} ${trainerInfo.lastName}`}
                    trainerBio={trainerInfo.bio}
                    trainerEmail={trainerInfo.email}
                />
                <LogoContainer>
                    <NeveLearningLogoMonochrome />
                </LogoContainer>
            </FooterInfoSection>
        </FooterBackground>
    );
}

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const FooterInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${sizes.spacingRg};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    width: 100%;
`;

const FooterBackground = styled.div`
    display: flex;
    background-color: ${theme.footerBackground};
    width: 100%;
    padding: ${sizes.spacingLg} 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
