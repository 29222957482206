import { StepperFormProps } from '../../../components/stepper/stepperTypes';
import { NewSchedule } from '../ScheduleCoursePage';
import useNeveForm from '../../../components/forms/NeveForm';
import strings from '../../../strings/strings.json';
import { BodyRegular, Headline4 } from '../../../components/Elements';
import * as React from 'react';
import { FormWithGaps } from './commonElements';
import TextField from 'components/forms/TextField';
import {
    validateLargerThanValue,
    validateSmallerThanValue,
    validateThreeCharacterLimit,
} from 'lib/custom-form-validation';
import styled from 'styled-components';
import { sizes } from 'theme';
import ErrorIcon from 'assets/icons/indicators/ErrorIcon';
import { ErrorMessage } from 'components/forms/FormComponents';
import StepperButtonRow from 'components/stepper/StepperButtonRow';
import { requiredFieldErrorMessage } from 'shared/error-messages';

type SelectNumberOfLearnersFormData = {
    minimumLearners: number;
    maximumLearners: number;
};
export default function SelectNumberOfLearnersForm(props: StepperFormProps<NewSchedule>): JSX.Element {
    const { next, previous, newObject, setNewObject } = props;

    const {
        handleSubmit,
        register,
        watch,
        setFocus,
        formState: { errors },
    } = useNeveForm<SelectNumberOfLearnersFormData>({
        minimumLearners: newObject.minimumLearners,
        maximumLearners: newObject.maximumLearners,
    });

    const min = watch('minimumLearners');
    const max = watch('maximumLearners');

    React.useEffect(() => {
        if (errors.minimumLearners) {
            setFocus('minimumLearners');
            return;
        }
        if (errors.maximumLearners) {
            setFocus('maximumLearners');
            return;
        }
    }, [errors.minimumLearners]); // eslint-disable-line

    async function onSubmit(formData: SelectNumberOfLearnersFormData) {
        setNewObject({
            ...newObject,
            minimumLearners: formData.minimumLearners,
            maximumLearners: formData.maximumLearners,
        });
        next();
    }

    async function onPrevious() {
        if (errors.minimumLearners) {
            setFocus('minimumLearners');
            return;
        } else if (errors.maximumLearners) {
            setFocus('maximumLearners');
            return;
        }
        setNewObject({
            ...newObject,
            minimumLearners: min,
            maximumLearners: max,
        });
        previous();
    }

    return (
        <FormWithGaps onSubmit={handleSubmit(onSubmit)}>
            <Headline4>{strings.scheduleCourseStepper.selectNumberOfLearnersForm.title}</Headline4>
            <BodyRegular>{strings.scheduleCourseStepper.selectNumberOfLearnersForm.subtitle}</BodyRegular>
            <InputsContainer>
                <TextField
                    fieldName="minimumLearners"
                    maxLength={3}
                    labelText={strings.scheduleCourseStepper.selectNumberOfLearnersForm.minimumLearnersInputLabel}
                    inputAria={strings.scheduleCourseStepper.selectNumberOfLearnersForm.minimumLearnersInputAria}
                    inputProps={register('minimumLearners', {
                        validate: {
                            validateThreeCharacterLimit,
                            smallerThanValue: (value) =>
                                validateSmallerThanValue(
                                    value,
                                    watch('maximumLearners'),
                                    strings.scheduleCourseStepper.selectNumberOfLearnersForm.minimumLearnersInputLabel,
                                    strings.scheduleCourseStepper.selectNumberOfLearnersForm.maximumLearnersInputLabel,
                                ),
                        },
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(
                                strings.scheduleCourseStepper.selectNumberOfLearnersForm.minimumLearnersInputLabel,
                            ),
                        },
                    })}
                    errorMessage={errors?.minimumLearners?.message}
                    hideErrorMessage
                    inputType="number"
                    width="8rem"
                    required
                />
                <TextField
                    fieldName="maximumLearners"
                    maxLength={3}
                    labelText={strings.scheduleCourseStepper.selectNumberOfLearnersForm.maximumLearnersInputLabel}
                    inputAria={strings.scheduleCourseStepper.selectNumberOfLearnersForm.maximumLearnersInputAria}
                    inputProps={register('maximumLearners', {
                        validate: {
                            validateThreeCharacterLimit,
                            largerThanValue: (value) =>
                                validateLargerThanValue(
                                    value,
                                    watch('minimumLearners'),
                                    strings.scheduleCourseStepper.selectNumberOfLearnersForm.maximumLearnersInputLabel,
                                    strings.scheduleCourseStepper.selectNumberOfLearnersForm.minimumLearnersInputLabel,
                                ),
                        },
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(
                                strings.scheduleCourseStepper.selectNumberOfLearnersForm.maximumLearnersInputLabel,
                            ),
                        },
                    })}
                    errorMessage={errors.maximumLearners?.message}
                    hideErrorMessage
                    inputType="number"
                    width="8rem"
                    required
                />
            </InputsContainer>
            {(errors.minimumLearners || errors.maximumLearners) && (
                <ErrorsContainer>
                    {errors.minimumLearners && (
                        <ErrorRow>
                            <ErrorIcon />
                            <ErrorMessage>{errors.minimumLearners.message}</ErrorMessage>
                        </ErrorRow>
                    )}
                    {errors.maximumLearners && (
                        <ErrorRow>
                            <ErrorIcon />
                            <ErrorMessage>{errors.maximumLearners.message}</ErrorMessage>
                        </ErrorRow>
                    )}
                </ErrorsContainer>
            )}
            <StepperButtonRow
                submitButtonTitle={strings.scheduleCourseStepper.selectNumberOfLearnersForm.submitButton}
                submitButtonAria={strings.scheduleCourseStepper.selectNumberOfLearnersForm.submitButtonAria}
                previousButtonFunction={onPrevious}
            />
        </FormWithGaps>
    );
}

const ErrorRow = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const ErrorsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${sizes.spacingLg};
    flex-wrap: wrap;
`;
