import React, { useContext } from 'react';
import styled from 'styled-components';
import strings from '../../strings/strings.json';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import { breakpoints, sizes, theme } from 'theme';
import { Headline2, LinkWrapper } from 'components/Elements';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import { TabDefinition } from 'components/tabs/Tabs';
import CoursesIcon from 'assets/icons/navigation/CoursesIcon';
import ScheduleIcon from 'assets/icons/navigation/ScheduleIcon';
import OrganisationIcon from 'assets/icons/navigation/OrganisationIcon';
import useGetRequest from 'hooks/useGetRequest';
import { Organisation } from 'models/Organisation';
import { AuthContext } from 'contextProviders/AuthContext';
import OACoursesCoursesTable from './courses/OACoursesTable';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import { OATrainersTable } from './trainers/OATrainersTable';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { useLocation } from 'react-router-dom';
import OALiveCoursesTable from './courses/OALiveCoursesTable';
import { OrganisationHeaderBlue } from './shared/styles';

type State = {
    activeTabId: string;
};

export default function OADashboard(): JSX.Element {
    const { userData } = useContext(AuthContext);
    const organisationId = userData.organisation?.id;
    const { state } = useLocation();
    const { activeTabId } = (state as State) ?? {};

    const {
        data: organisation,
        loading,
        errors,
    } = useGetRequest<Organisation>(`organisations/${organisationId}`, !organisationId);

    if (errors && errors.length) {
        return (
            <>
                <OrganisationHeaderBlue>
                    <LimitedContainer>
                        <Headline2>{strings.OADashboard.errors.pageHeader}</Headline2>
                    </LimitedContainer>
                </OrganisationHeaderBlue>
                <MainSectionWithGaps>
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.OADashboard.errors.errorTitle}
                        description={strings.OADashboard.errors.errorDescription}
                    />
                </MainSectionWithGaps>
            </>
        );
    }

    if (!organisation || loading) return <></>;

    const tabDefinitions: TabDefinition[] = [
        {
            id: 'courses',
            name: strings.OADashboard.tabTitles.courses,
            icon: CoursesIcon,
            content: <OACoursesCoursesTable />,
        },
        {
            id: 'liveCourses',
            name: strings.OADashboard.tabTitles.liveCourses,
            icon: ScheduleIcon,
            content: <OALiveCoursesTable />,
        },
        {
            id: 'trainers',
            name: strings.OADashboard.tabTitles.trainers,
            icon: OrganisationIcon,
            content: <OATrainersTable />,
        },
    ];

    return (
        <>
            <OrganisationHeaderBlue>
                <LimitedContainer>
                    <Headline2>{organisation.name}</Headline2>
                    {organisation.logoS3Filepath && (
                        <LinkWrapper href={`/public/organisation/${organisation.publicName}`}>
                            <LogoContainer>
                                <Image
                                    src={`${DOCUMENTS_URL}/${organisation.logoS3Filepath}`}
                                    alt={strings.organisationOverviewPage.logoAltText}
                                />
                            </LogoContainer>
                        </LinkWrapper>
                    )}
                </LimitedContainer>
            </OrganisationHeaderBlue>
            <MainSectionWithGaps>
                <TabStructureComponent tabs={tabDefinitions} initialActiveTabId={activeTabId} />
            </MainSectionWithGaps>
        </>
    );
}

const LimitedContainer = styled.header`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: ${sizes.spacingLg} ${sizes.spacingMd};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
`;

const LogoContainer = styled.div`
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusXl};
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    padding: ${sizes.spacingSm};
`;

const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;
