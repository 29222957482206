import { ROLE_DETAILS, RoleType } from '../../../models/User';
import styled from 'styled-components';
import { sizes, theme } from '../../../theme';
import { BodyRegular } from '../../../components/Elements';

export default function RolesTags({ roles }: { roles: RoleType[] }): JSX.Element {
    return (
        <TagsContainer>
            {roles.map((role) => {
                const roleDetails = ROLE_DETAILS.find((x) => x.name === role);

                if (!roleDetails) return null;

                const { friendlyName, icon } = roleDetails;

                const unbreakableFriendlyName = friendlyName.replaceAll(' ', '\u00A0');

                return (
                    <Tag key={role}>
                        {icon}
                        <BodyRegular>{unbreakableFriendlyName}</BodyRegular>
                    </Tag>
                );
            })}
        </TagsContainer>
    );
}

const Tag = styled.div`
    background-color: ${theme.roleTagBackgroundColour};
    border-radius: 32px;
    padding: ${sizes.spacingSm} ${sizes.spacingMd};
    display: flex;
    gap: ${sizes.spacingXs};
    align-items: center;
    word-break: break-word;
`;

const TagsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${sizes.spacingSm};
    flex-wrap: wrap;
`;
