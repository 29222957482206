import styled from 'styled-components';
import { sizes, breakpoints } from 'theme';

export const SaveButtonAndNotificationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${sizes.spacingMd};
    min-height: 72px;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
    }
`;

export const RightNavButtonContainer = styled.div`
    display: grid;
    justify-items: flex-end;
`;

export const LeftNavButtonContainer = styled.div`
    display: grid;
    justify-items: flex-start;
`;
