import { Headline2, BodyRegular, LinkWrapper } from 'components/Elements';
import ExpandableContentComponent from 'components/ExpandableContentComponent';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import { LearnerLearningObjective, LearnerTrainerInfo } from 'models/LearnerCourse';
import { formatDate } from 'pages/learner/components/CourseCardDateSection';
import LearningObjectivesList from 'pages/learner/components/LearningObjectivesList';
import { LearnerCardBase } from 'pages/learner/sharedStyles/LearnerStyles';
import styled from 'styled-components';
import { theme, breakpoints, sizes } from 'theme';
import strings from '../../strings/strings.json';

type LearnerCourseHeaderProps = {
    courseTitle: string;
    courseDescription: string;
    learningObjectives: LearnerLearningObjective[];
    startDate: string;
    endDate: string;
    trainerInfo: LearnerTrainerInfo;
};

export default function LearnerCourseHeader({
    courseTitle: title,
    courseDescription: description,
    learningObjectives,
    startDate,
    endDate,
    trainerInfo,
}: LearnerCourseHeaderProps): JSX.Element {
    return (
        <HeaderSection>
            <LimitedContainer>
                <PageHeaderSection>
                    <TitleAndLogoContainer>
                        <TitleGrid>
                            <Headline2>{title}</Headline2>
                        </TitleGrid>
                        <DateGrid>
                            <DateText>
                                <BoldDateText $bold>{strings.learnerCoursePage.start}</BoldDateText>{' '}
                                {formatDate(startDate)}
                            </DateText>
                            <DateText>
                                <BoldDateText $bold>{strings.learnerCoursePage.end}</BoldDateText>{' '}
                                {formatDate(endDate)}
                            </DateText>
                        </DateGrid>

                        {trainerInfo.organisationLogo && (
                            <Wrapper>
                                <LinkWrapper href={`/public/organisation/${trainerInfo.organisationPublicName}`}>
                                    <LogoGrid>
                                        <Image
                                            src={`${DOCUMENTS_URL}/${trainerInfo.organisationLogo}`}
                                            alt={`${trainerInfo.organisationName} logo`}
                                        />
                                    </LogoGrid>
                                </LinkWrapper>
                            </Wrapper>
                        )}
                    </TitleAndLogoContainer>

                    {description && (
                        <CardContainer>
                            <ExpandableContentComponent
                                headline={strings.learnerCoursePage.courseDescriptionTitle}
                                content={description}
                                closedHeightInPx={150}
                            />
                        </CardContainer>
                    )}

                    <CardContainer>
                        <ExpandableContentComponent
                            headline={strings.learnerCoursePage.learningObjectivesSectionTitle}
                            content={<LearningObjectivesList learningObjectives={learningObjectives} />}
                            closedHeightInPx={150}
                        />
                    </CardContainer>
                </PageHeaderSection>
            </LimitedContainer>
        </HeaderSection>
    );
}

const HeaderSection = styled.div`
    background-color: ${theme.contentBackgroundColourTrainer};
    width: 100%;
    padding-bottom: ${sizes.spacingLg};
    border-bottom: 1px solid ${theme.cardBorder};
`;

const LimitedContainer = styled.header`
    display: flex;
    flex-direction: column;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    padding: 0 ${sizes.edgePadding};
    margin: auto;
`;

const PageHeaderSection = styled.div`
    margin-top: ${sizes.headerPadding};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${sizes.spacingLg};
    height: 100%;
    width: 100%;
`;

const TitleGrid = styled.div`
    grid-area: title;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: end;
`;

const DateGrid = styled(LearnerCardBase)`
    grid-area: dates;
    padding: ${sizes.spacingMd};
    width: fit-content;
    height: min-content;
    display: flex;
    gap: ${sizes.spacingLg};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingSm};
        align-items: start;
        justify-content: center;
    }
`;

const DateText = styled(BodyRegular)`
    display: flex;
    gap: ${sizes.spacingXs};
    white-space: nowrap;
`;

const BoldDateText = styled(BodyRegular)`
    white-space: nowrap;
`;

const TitleAndLogoContainer = styled.div`
    display: grid;
    gap: ${sizes.spacingSm};
    grid-template-areas:
        'title logo'
        'dates .';
    @media (max-width: ${breakpoints.lg}) {
        grid-template-areas:
            'title title'
            'dates logo';
        align-items: center;
    }
`;

const LogoGrid = styled(LearnerCardBase)`
    grid-area: logo;
    display: flex;
    align-items: center;
    height: min-content;
    width: min-content;
    margin-top: ${sizes.spacingMd};
    padding: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.lg}) {
        margin-top: unset;
    }
`;

const Image = styled.img`
    border-radius: ${sizes.borderRadiusMd};
    max-width: 244px;
    max-height: 88px;
    width: auto;
    height: auto;
    object-fit: contain;
`;

const CardContainer = styled(LearnerCardBase)`
    width: 100%;
    padding: ${sizes.spacingMd};
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
